import React from 'react';
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DeviceConfig, DeviceConfigInput } from '@edgeiq/edgeiq-api-js';

import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import CheckboxInput from '../../../components/CheckboxInput';

interface LatencyTestProps {
  deviceConfig: DeviceConfig | DeviceConfigInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
}

const LatencyTest: React.FC<LatencyTestProps> = ({
  deviceConfig,
  onInputChange,
}) => {
  const handleArrayChange =
    (prop: string) =>
    (_e: React.SyntheticEvent, value: string[] | null): void => {
      if (value) {
        onInputChange(prop, value);
      }
    };

  const handleRestoreDefaultOptions = (): void => {
    onInputChange('latency_test', 'restore_defaults');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CheckboxInput
          label="Enable Latency Tests"
          prop="latency_test.enabled"
          value="latency_test.enabled_checkbox"
          checked={deviceConfig.latency_test?.enabled}
          onCheckboxClick={onInputChange}
        />
      </Grid>
      {deviceConfig.latency_test?.enabled && (
        <>
          {/* <Grid item xs={12} lg={6}>
            <TextInput
              label="Frequency (s)"
              prop="latency_test.frequency_seconds"
              type="number"
              value={deviceConfig.latency_test.frequency_seconds}
              onInputChange={onInputChange}
            />
          </Grid> */}
          {deviceConfig.connections && (
            <Grid item xs={12} lg={6}>
              <SelectInput
                multiple
                label="Interfaces"
                prop="latency_test.interfaces"
                values={deviceConfig.latency_test.interfaces ?? []}
                onMultiSelectChange={onInputChange}
                options={deviceConfig.connections?.map((network) => (
                  <MenuItem
                    dense
                    key={`latency_test_interface_${network.name}`}
                    value={network.name}
                  >
                    {network.name}
                  </MenuItem>
                ))}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" className="custom-label">
              Hosts (optional)
            </Typography>
            <Autocomplete
              multiple
              id="latency_test.hosts"
              freeSolo
              value={((deviceConfig.latency_test.hosts as string[]) ?? []).map(
                (option) => option,
              )}
              options={[] as string[]}
              onChange={handleArrayChange('latency_test.hosts')}
              renderTags={(tagValue: readonly string[], getTagProps) =>
                tagValue.map((option: string, subIndex: number) => {
                  const { key, ...tagProps } = getTagProps({
                    index: subIndex,
                  });
                  return (
                    <Chip
                      variant="outlined"
                      label={option}
                      key={key}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Add host. Optional. If no host is added, 'edgeiq.io' will be used."
                  helperText="Press enter to add host"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="custom-label m-0">
              Options
              <Button
                variant="text"
                size="small"
                onClick={handleRestoreDefaultOptions}
              >
                Restore to defaults
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              label="Packet Size"
              prop="latency_test.options.packet_size"
              type="number"
              value={deviceConfig.latency_test.options.packet_size}
              onInputChange={onInputChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              label="Count"
              prop="latency_test.options.count"
              type="number"
              value={deviceConfig.latency_test.options.count}
              onInputChange={onInputChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              label="Timeout (s)"
              type="number"
              prop="latency_test.options.timeout_seconds"
              value={deviceConfig.latency_test.options.timeout_seconds}
              onInputChange={onInputChange}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LatencyTest;
