import { set, uniq } from 'lodash';
import { Filters, Filter, FilterOperatorType } from '@edgeiq/edgeiq-api-js';
import { MetadataFilter } from '../models/common';
import {
  ACTIVATION_STATUS_KEY,
  BULK_RESPONSE_STATUS_KEY,
  DELETE_BULK_TYPE_KEY,
  METADATA_ARRAY_OPERATORS,
  METADATA_KEY,
  METADATA_OPERATORS_SYMBOLS,
  SOFTWARE_VERSION_KEY,
  TRANSFER_STATUS_KEY,
} from '../app/constants';

const likeKeys = ['name', 'description', 'unique_id', 'device_name', 'email'];
const specialKeys = [
  SOFTWARE_VERSION_KEY,
  TRANSFER_STATUS_KEY,
  ACTIVATION_STATUS_KEY,
  DELETE_BULK_TYPE_KEY,
  BULK_RESPONSE_STATUS_KEY,
];
const arrayKeys = ['tags'];

const parseFilters = (
  filters: { [key: string]: string },
  caseSensitive = false,
): Filters => {
  if (Object.keys(filters).length === 0) {
    return {};
  }

  const result: Filter[] = [];
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const value = filters[key];
      if (value !== '') {
        if (specialKeys.includes(key)) {
          parseSpecialCases(result, key, value);
        } else if (key.indexOf(METADATA_KEY) !== -1) {
          const filter: Filter = {
            key: getMetadataFilterKey(key),
            operator: getMetadataFilterOperator(key) as FilterOperatorType,
            value,
          };
          result.push(filter);
        } else {
          const isMulti = value.indexOf('|') !== -1;
          const filterValue = isMulti ? value.split('|') : value;
          const filter: Filter = {
            key,
            operator: arrayKeys.includes(key)
              ? 'incany'
              : isMulti
              ? 'in'
              : likeKeys.includes(key)
              ? `${caseSensitive ? '' : 'i'}like`
              : 'eq',
            value: filterValue,
          };
          result.push(filter);
        }
      }
    }
  }

  return {
    filters: result,
  };
};

const parseSpecialCases = (
  result: Filter[],
  key: string,
  value: string,
): void => {
  // special case for not yet activated of activation status
  if (key === ACTIVATION_STATUS_KEY && value === 'activated_at') {
    const filter: Filter = {
      key: 'activated_at',
      operator: 'eq',
      value: 'null',
    };
    result.push(filter);
    const secondFilter: Filter = {
      key: 'deactivated_at',
      operator: 'eq',
      value: 'null',
    };
    result.push(secondFilter);
  } else if (key === DELETE_BULK_TYPE_KEY) {
    // Special case to filter bulk reponses that are a of type `delete_<model>`
    const filter: Filter = {
      key: 'type',
      operator: 'like',
      value: 'delete_',
    };
    result.push(filter);
  } else if (key === BULK_RESPONSE_STATUS_KEY) {
    // Special case to filter bulk reponses by their status
    const filter: Filter = {
      key: 'total',
      operator: 'ne',
      value: 0,
    };
    result.push(filter);
    const successFilter: Filter = {
      key: 'successes',
      operator: 'eq',
      value: 0,
    };
    const failureFilter: Filter = {
      key: 'failures',
      operator: 'eq',
      value: 0,
    };
    if (value === 'success') {
      successFilter.operator = 'ne';
    } else if (value === 'fail') {
      failureFilter.operator = 'ne';
    } else {
      successFilter.operator = 'ne';
      failureFilter.operator = 'ne';
    }
    result.push(successFilter);
    result.push(failureFilter);
  } else if (key === SOFTWARE_VERSION_KEY) {
    // Special case to filter bulk reponses that are a of type `delete_<model>`
    const filter: Filter = {
      key: 'software_version',
      operator: 'in',
      value,
    };
    result.push(filter);
  } else {
    const filter: Filter = {
      key:
        value.indexOf('_ne') !== -1
          ? value.substring(0, value.indexOf('_ne'))
          : value,
      operator: value.indexOf('_ne') !== -1 ? 'ne' : 'eq',
      value: 'null',
    };
    result.push(filter);
  }
};

export const parseMetadataFilters = (
  metadata: MetadataFilter[],
): {
  [key: string]: string;
} => {
  const parsed: { [key: string]: string } = {};
  for (let i = 0; i < metadata.length; i++) {
    if (!parsed[`metadata.${metadata[i].key}`]) {
      parsed[`metadata.${metadata[i].key}`] = Array.isArray(metadata[i].value)
        ? (metadata[i].value as string[]).join(',')
        : String(metadata[i].value);
    }
  }
  return parsed;
};

export const parseSoftwareVersionFilters = (
  softwareVersionsFilters: MetadataFilter[],
): string => {
  let parsed = '';
  for (let i = 0; i < softwareVersionsFilters.length; i++) {
    const filter = softwareVersionsFilters[i];
    parsed = `${parsed}${i !== 0 ? ',' : ''}${getMetadataFilterKey(
      filter.key,
    )}${
      filter.operator === 'installed'
        ? ''
        : `:${
            METADATA_OPERATORS_SYMBOLS[
              getMetadataFilterOperator(filter.operator)
            ]
          }${filter.value}`
    }`;
  }
  return parsed;
};

export const updateMetadataFilters = (
  filters: MetadataFilter[],
  index: number,
  key: string,
  value: string | string[],
  // the value will be number or string[] only when the key is `value`
): MetadataFilter[] => {
  const auxArray = [...filters];
  const isArray = METADATA_ARRAY_OPERATORS.includes(filters[index].operator);
  if (key === 'operator') {
    const oldValue = filters[index].value;
    const isNowArray = METADATA_ARRAY_OPERATORS.includes(value as string);
    set(
      auxArray,
      `${index}.key`,
      `${getMetadataFilterKey(filters[index].key)}_${value}`,
    );
    /**
     * Keeps the value when changing the operator.
     * cases:
     * - 'eq' or 'ne' => 'in' or 'nin'
     * - 'in' or 'nin' => 'eq' or 'ne'
     * for: 'eq' <=> 'ne' and 'in' <=> 'nin' the value is kept as is
     */
    if (!isArray && isNowArray) {
      set(auxArray, `${index}.value`, oldValue ? [oldValue] : []);
    } else if (isArray && !isNowArray) {
      set(auxArray, `${index}.value`, oldValue[0] ?? '');
    }
  }
  if (key === 'key') {
    set(auxArray, `${index}.key`, `${value}_${filters[index].operator}`);
  } else {
    set(
      auxArray,
      `${index}.${key}`,
      isArray && key === 'value' ? uniq(value as string[]) : value,
    );
  }
  return auxArray;
};

export const getMetadataFilterKey = (filter: string, start = 0): string => {
  const result = filter.substring(start, filter.lastIndexOf('_'));
  if (result) {
    return result;
  }
  return filter;
};

export const getMetadataFilterOperator = (filter: string): string => {
  return filter.substring(filter.lastIndexOf('_') + 1, filter.length);
};

export const getSoftwareVersionObject = (
  textFilter: string,
): MetadataFilter[] => {
  const softwareVersionFilters: MetadataFilter[] = [];
  if (textFilter) {
    // The filter is a simple key value, the value is a string, software version filters are seperated by coma
    const versions = textFilter.split(',');
    for (let index = 0; index < versions.length; index++) {
      const version = versions[index];
      // Each software version filter is of this structure `<name>:<operator><value>`
      // Except for the installed one that is '<name>'
      const parts = version.split(':');
      const value = parts[1];
      const filter: MetadataFilter = {
        key: parts[0],
        operator: 'installed',
        value: '',
      };
      if (value) {
        if (value.indexOf('>=') !== -1) {
          filter.operator = 'gte';
          filter.value = value.substring(2, value.length);
        } else if (value.indexOf('>') !== -1) {
          filter.operator = 'gt';
          filter.value = value.substring(1, value.length);
        } else if (value.indexOf('<=') !== -1) {
          filter.operator = 'lte';
          filter.value = value.substring(2, value.length);
        } else if (value.indexOf('<') !== -1) {
          filter.operator = 'lt';
          filter.value = value.substring(1, value.length);
        } else if (value.indexOf('=') !== -1) {
          filter.operator = 'eq';
          filter.value = value.substring(1, value.length);
        }
      }
      softwareVersionFilters.push(filter);
    }
  }
  return softwareVersionFilters;
};

export const clearSoftwareVersion = (
  filter: string,
  clearFilter: string,
): string => {
  const re = new RegExp(`[,]?${clearFilter}[,]?`);
  const result = filter.replace(re, '');
  return result;
};

export default parseFilters;
