import React, { useEffect, useState } from 'react';
import { Chip, Tooltip, Typography } from '@mui/material';
import { Companies, UserTypes } from '@edgeiq/edgeiq-api-js';
import {
  AccountTree as TreeIcon,
  CancelOutlined as CancelIcon,
  CorporateFare as AccountIcon,
  DataObject as DataObjectIcon,
  HealthAndSafety as HealthIcon,
  Label as TagIcon,
  MonitorHeart as HeartbeatIcon,
  SystemUpdate as SoftwareVersionIcon,
} from '@mui/icons-material';
import clsx from 'clsx';

import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
  activationStatusLabel,
  ACTIVATION_STATUS_KEY,
  activeFilterLabel,
  CASE_SENSITIVE_KEY,
  filtersKeysLabels,
  healthStatusLabel,
  heartBeatStatusLabel,
  METADATA_KEY,
  METADATA_OPERATORS_SYMBOLS,
  profileTypesMap,
  ruleTypesFilterLabel,
  SOFTWARE_VERSION_KEY,
} from '../../../app/constants';
import {
  clearSoftwareVersion,
  getMetadataFilterKey,
  getMetadataFilterOperator,
  getSoftwareVersionObject,
} from '../../../helpers/parseFilters';
import useStyles from '../styles';

type ChipData = {
  label: string;
  value: string;
  keyLabel: string;
  keyValue: string;
};

interface FiltersChipsProps {
  filterModel: string;
  filters: {
    [key: string]: string;
  };
  mainFilterInputKey?: string; // This key helps to not show a chip of the input search as it is already seen
  onChangeFiltersChips: (prop: string, value: string) => void;
}

const FiltersChips: React.FC<FiltersChipsProps> = ({
  filterModel,
  filters,
  mainFilterInputKey,
  onChangeFiltersChips,
}) => {
  const classes = useStyles();
  const { optionsDeviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const { userCompanies } = useAppSelector((state: RootState) => state.user);
  const { userTypes } = useAppSelector((state: RootState) => state.userTypes);
  const [chips, setChips] = useState<ChipData[]>([]);

  const getChipLabel = async (value: string, key: string): Promise<string> => {
    switch (key) {
      case 'device_type_id':
        const deviceType = optionsDeviceTypes.find(
          (type) => type._id === value,
        );
        return deviceType?.name ?? value;
      case 'heartbeat_status':
        return heartBeatStatusLabel[value];
      case 'type':
        if (filterModel === 'devicesTypes') {
          return profileTypesMap[value];
        }
        return value;
      case 'health_status':
        return healthStatusLabel[value];
      case ACTIVATION_STATUS_KEY:
        return activationStatusLabel[value];
      case 'cloud_rule':
        return ruleTypesFilterLabel[value];
      case 'active':
        return activeFilterLabel[value];
      case 'company_id':
      case 'ancestor_company_id':
        const account = userCompanies.find((company) => company._id === value);
        if (account) {
          return account.name;
        }
        return await Companies.getOneById(value).then((company) => {
          return company.name;
        });
      case 'user_type_id':
        const userType = userTypes.find((ut) => ut._id === value);
        if (userType) {
          return userType.name;
        }
        return await UserTypes.getOneById(value).then((result) => {
          return result.name;
        });

      default:
        if (key.indexOf(METADATA_KEY) !== -1) {
          return `${getMetadataFilterKey(key, METADATA_KEY.length)} ${
            METADATA_OPERATORS_SYMBOLS[getMetadataFilterOperator(key)]
          } ${value}`;
        }
        return value;
    }
  };

  useEffect(() => {
    const processChips = async (): Promise<void> => {
      const newChips: ChipData[] = [];
      for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
          const filter = filters[key];
          if (
            filter !== '' &&
            key !== mainFilterInputKey &&
            key !== CASE_SENSITIVE_KEY
          ) {
            if (filter.indexOf('|') !== -1) {
              const multipleFilter = filter.split('|');
              multipleFilter.forEach(async (simpleFilter) => {
                const label = await getChipLabel(simpleFilter, key);
                newChips.push({
                  keyLabel: filtersKeysLabels[key],
                  keyValue: key,
                  label,
                  value: simpleFilter,
                });
              });
            } else if (key === SOFTWARE_VERSION_KEY) {
              // For the software version filter, which is a single string where each value is seperated by coma, we need to process it diferently
              // Here for just this one key we might have multiple chips.
              const softwareVersionFilters = getSoftwareVersionObject(filter);
              softwareVersionFilters.forEach((softwareVersionFilter) => {
                newChips.push({
                  keyLabel: 'Software Version',
                  keyValue: key,
                  label: `${softwareVersionFilter.key} ${
                    softwareVersionFilter.operator === 'installed'
                      ? softwareVersionFilter.operator
                      : METADATA_OPERATORS_SYMBOLS[
                          softwareVersionFilter.operator
                        ]
                  } ${softwareVersionFilter.value}`,
                  value: `${softwareVersionFilter.key}${
                    softwareVersionFilter.operator === 'installed'
                      ? ''
                      : `:${
                          METADATA_OPERATORS_SYMBOLS[
                            softwareVersionFilter.operator
                          ]
                        }`
                  }${softwareVersionFilter.value}`,
                });
              });
            } else {
              const label = await getChipLabel(filter, key);
              newChips.push({
                keyLabel:
                  key.indexOf(METADATA_KEY) !== -1
                    ? 'Metadata filter'
                    : filtersKeysLabels[key],
                keyValue: key,
                label,
                value: filter,
              });
            }
          }
        }
      }
      setChips(newChips);
    };
    processChips();
  }, [filters, optionsDeviceTypes]);

  const clearFilter = (chip: ChipData) => (): void => {
    console.info(chip);
    // Use the chip.keyValue to know what type of filter we have to remove
    const filterString = filters[chip.keyValue];
    if (filterString) {
      if (chip.keyValue === SOFTWARE_VERSION_KEY) {
        onChangeFiltersChips(
          chip.keyValue,
          clearSoftwareVersion(filterString, chip.value),
        );
      } else {
        // The filters are all multiple and will have a | to seperate between values so we remove the one that has been clicked
        const filterValues = filterString.split('|');
        const filterNewValues = filterValues.filter(
          (value) => value !== chip.value,
        );
        onChangeFiltersChips(chip.keyValue, filterNewValues.join('|'));
      }
    }
  };

  const renderIcon = (key: string): React.ReactElement => {
    switch (key) {
      case 'ancestor_company_id':
        return <TreeIcon fontSize="small" />;
      case 'company_id':
        return <AccountIcon fontSize="small" />;
      case 'health_status':
        return <HealthIcon fontSize="small" />;
      case 'heartbeat_status':
        return <HeartbeatIcon fontSize="small" />;
      case 'tags':
        return <TagIcon fontSize="small" />;
      case SOFTWARE_VERSION_KEY:
        return <SoftwareVersionIcon fontSize="small" />;
      default:
        if (key.indexOf(METADATA_KEY) !== -1) {
          return <DataObjectIcon fontSize="small" />;
        }
        return <></>;
    }
  };

  return (
    <>
      {chips.map((chip, index) => (
        <Tooltip
          placement="top"
          title={
            <>
              {chip.keyLabel}: <em>{chip.label}</em>
            </>
          }
          key={`${chip.value}-${index}`}
        >
          <Chip
            data-cy={`filter-chip-${chip.value}`}
            className={clsx('br-5 mr-4', classes.chipContainer)}
            icon={renderIcon(chip.keyValue)}
            label={
              <Typography
                className={classes.chipText}
                variant="caption"
                component="span"
              >
                {chip.label}
              </Typography>
            }
            onDelete={clearFilter(chip)}
            deleteIcon={<CancelIcon className={classes.chipIcon} />}
          />
        </Tooltip>
      ))}
    </>
  );
};

export default FiltersChips;
