import { Company, DeviceConfig } from '@edgeiq/edgeiq-api-js';
import AccountIcon from '@mui/icons-material/AccountCircle';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import useStyles from './styles';

interface NetworkConfigurationCardProps {
  config: DeviceConfig;
  userCompanies?: Company[];
}

const NetworkConfigurationCard: React.FC<NetworkConfigurationCardProps> = ({
  config,
  userCompanies,
}) => {
  const { name } = config;
  const classes = useStyles();

  const company = userCompanies?.find(
    (userCompany) => userCompany._id === config.company_id,
  );

  return (
    <Box className={clsx(classes.cardContainer)}>
      <Typography
        variant="h6"
        component="div"
        noWrap
        className={clsx('mb-1', classes.name)}
      >
        {name}
      </Typography>
      {company && (
        <Typography
          component="div"
          variant="overline"
          className={clsx('mt-4', classes.account)}
        >
          <AccountIcon className={clsx('mr-2')} />
          {company.name}
        </Typography>
      )}
    </Box>
  );
};

export default NetworkConfigurationCard;
