import React from 'react';
import { CellularAPNConfig, DeviceConfig } from '@edgeiq/edgeiq-api-js';
import { Typography, Grid, Paper } from '@mui/material';

import TextInput from '../../../components/TextInput';
import InterfaceDataCollection from '../../../containers/Forms/NetworkConfigurationForm/InterfaceDataCollection';
import LatencyTest from '../../../containers/Forms/NetworkConfigurationForm/LatencyTest';
import PerformanceTest from '../../../containers/Forms/NetworkConfigurationForm/PerformanceTest';

interface NetworkConfigurationDetailsProps {
  newNetworkConfiguration: DeviceConfig;
  onInputChange: (
    prop: string,
    value: string | number | string[] | CellularAPNConfig[],
  ) => void;
}

const NetworkConfigurationDetails: React.FC<
  NetworkConfigurationDetailsProps
> = ({ newNetworkConfiguration, onInputChange }) => {
  return (
    <Paper className="p-8 shadow">
      <Typography variant="h5" className="mb-4">
        Details
      </Typography>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <TextInput
            prop="name"
            label="Name"
            placeholder="Name"
            type="text"
            required={true}
            value={newNetworkConfiguration.name}
            onInputChange={onInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InterfaceDataCollection
            deviceConfig={newNetworkConfiguration}
            onInputChange={onInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <LatencyTest
            deviceConfig={newNetworkConfiguration}
            onInputChange={onInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <PerformanceTest
            deviceConfig={newNetworkConfiguration}
            onInputChange={onInputChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NetworkConfigurationDetails;
