import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { DeviceTypes, PaginationFilter } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  setOptionsDeviceTypes,
  setStateDeviceTypes,
} from '../../redux/reducers/deviceTypes.reducer';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { usePageSetter } from '../../hooks/usePageSetter';
import Header from '../../containers/HeaderWithActionButton';
import ListSelection from '../../components/ListSelection';
import Card from '../../components/Card';
import CardsGrid from '../../components/CardsGrid';
import SharedTable from '../../components/SharedTable';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  genericViewOptions,
} from '../../app/constants';
import { SortingOption } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import DevicesProfilesFilters from './DevicesProfilesFilters';
import DeviceProfileCard from './DeviceProfileCard';
import { DevicesProfilesColumns } from './columns';
import useStyles from './styles';
import ActionDialog from '../../components/ActionDialog';
import { SORTING_OPTIONS_GENERIC_NAME } from '../../constants/sortings';

const DevicesProfilesPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const { deviceTypes, optionsDeviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const stateUser = useAppSelector((state: RootState) => state.user);

  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(
      filters.devicesTypes.sortBy,
      SORTING_OPTIONS_GENERIC_NAME,
    ),
  );
  const [selectedView, setSelectedView] = useState(filters.devicesTypes.view);
  const { setTotalAndPage, total, page } = usePageSetter(0, 1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const getProfiles = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    DeviceTypes.list(
      parseFilters(filters.devicesTypes.filters ?? {}, filters.case_sensitive),
      pagination,
    )
      .then((result) => {
        const newDeviceTypes = addPage
          ? [...deviceTypes, ...result.deviceTypes]
          : result.deviceTypes;
        dispatch(setStateDeviceTypes(newDeviceTypes));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const whenComponetMounted = (newPage: number): void => {
    setLoading(true);
    setSelectedProfiles([]);
    getProfiles(newPage);
  };

  useEffect(() => {
    whenComponetMounted(1);
  }, [filters.devicesTypes]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getProfiles(page + 1, true);
  };

  const checkProfileCallback =
    (deviceId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedProfiles([...selectedProfiles, deviceId]);
      } else {
        setSelectedProfiles(
          selectedProfiles.filter((item) => item !== deviceId),
        );
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'devicesTypes'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'devicesTypes'));
    setSelectedView(view);
  };

  const handleSelectAll = (): void => {
    if (selectedProfiles.length !== deviceTypes.length) {
      setSelectedProfiles(deviceTypes.map((deviceType) => deviceType._id));
    } else {
      setSelectedProfiles([]);
    }
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    if (selectedProfiles.length === 1) {
      DeviceTypes.delete(selectedProfiles[0])
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: deleteHighlight(
                1,
                'Device Profile',
                'Device Profiles',
              ),
              type: 'success',
            }),
          );
          dispatch(
            setOptionsDeviceTypes(
              optionsDeviceTypes.filter(
                (optionDeviceType) =>
                  !selectedProfiles.includes(optionDeviceType._id),
              ),
            ),
          );
          whenComponetMounted(page);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    } else {
      DeviceTypes.deleteMultiple(selectedProfiles)
        .then((_result) => {
          dispatch(
            setAlert({
              link: 'messages#bulk-jobs',
              linkText: 'bulk job',
              message:
                'A <link> has been created to delete the selected device profiles',
              type: 'success',
            }),
          );
          dispatch(
            setOptionsDeviceTypes(
              optionsDeviceTypes.filter(
                (optionDeviceType) =>
                  !selectedProfiles.includes(optionDeviceType._id),
              ),
            ),
          );
          whenComponetMounted(1);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    }
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        action="create"
        title="Profiles"
        link="new-device-profile"
        actionLabel="Create New Profile"
        model="device_type"
      />
      <div className={clsx(classes.filterSortWrapper)}>
        <DevicesProfilesFilters total={total} />
        <ListSelection
          selectedSorting={selectedSorting}
          selectedView={selectedView}
          sortingOptions={SORTING_OPTIONS_GENERIC_NAME}
          viewsOptions={genericViewOptions}
          itemsSelected={selectedProfiles.length !== 0}
          allSelected={
            deviceTypes.length !== 0 &&
            selectedProfiles.length === deviceTypes.length
          }
          deleteAction={true}
          sortingCallback={handleSorting}
          selectAllCallback={handleSelectAll}
          selectViewCallback={handleSelectView}
          deleteCallback={openDeleteModal}
          itemsSelectedCount={selectedProfiles.length}
          selectedLabel="profile"
        />
      </div>
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && (
            <CardsGrid
              twoColumns={true}
              cards={deviceTypes.map((deviceType) => (
                <Card
                  checked={selectedProfiles.includes(deviceType._id)}
                  checkboxCallback={checkProfileCallback}
                  id={deviceType._id}
                  baseLink="/device-profile"
                  content={
                    <DeviceProfileCard
                      deviceType={deviceType}
                      company={stateUser?.userCompanies?.find(
                        (company) => company._id === deviceType.company_id,
                      )}
                    />
                  }
                />
              ))}
            />
          )}
          {selectedView === 'list' && (
            <SharedTable
              columns={DevicesProfilesColumns}
              rows={deviceTypes}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={selectedProfiles.length === deviceTypes.length}
              loading={loading}
              selectedItemsIds={selectedProfiles}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkProfileCallback}
            />
          )}
          {deviceTypes.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedProfiles.length === 1
                ? 'device profile'
                : 'device profiles'
            }:`}</span>
            <ul>
              {deviceTypes
                .filter((profile) => selectedProfiles.includes(profile._id))
                .map((profile) => (
                  <li key={profile._id}>
                    {profile.name} - Id: {profile._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default DevicesProfilesPage;
