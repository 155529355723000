import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, Button, CircularProgress } from '@mui/material';
import { PaginationFilter, Report, Reports } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../../../redux/hooks';
import { setAlert } from '../../../../redux/reducers/alert.reducer';
import {
  activityColorThemeMap,
  errorHighlight,
  defaultItemsPerPage,
} from '../../../../app/constants';
import timeHelpers from '../../../../helpers/timeHelpers';
import { getNoItemsInRange } from '../../../../helpers/utils';
import { DateRangePickerValues } from '../../../../components/DateRangePicker/DateRangePicker';
import TimelineCard from '../../../../components/TimelineCard';
import TextInput from '../../../../components/TextInput';
import RightDrawer from '../../../../components/RightDrawer/RightDrawer';

const DeviceReportsActivities: React.FC<{
  range: DateRangePickerValues;
  heartbeat?: boolean;
}> = ({ range, heartbeat = false }) => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();

  const [reports, setReports] = useState<Report[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState<Report>();
  const [infoDrawer, setInfoDrawer] = useState(false);
  const [selectedReportPayload, setSelectedReportPayload] =
    useState<string>('');

  const handleOpenDrawer = (r: Report | undefined = undefined): void => {
    setInfoDrawer(!infoDrawer);
    if (r) {
      const p = r.payload;
      const payload = JSON.stringify(p, null, 2);
      setSelectedReport(r);
      setSelectedReportPayload(payload);
    }
  };

  useEffect(() => {
    setLoading(true);
    getReports(1, false);
  }, [range]);

  const getReports = (pageNumber: number, addPage: boolean): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      page: pageNumber,
    };
    Reports.list(
      {
        device_id: {
          operator: 'eq',
          value: id as string,
        },
        filters: [
          {
            key: 'heartbeat',
            operator: 'eq',
            value: heartbeat,
          },
          {
            key: 'created_at',
            operator: 'gte',
            value: range.startDate,
          },
          {
            key: 'created_at',
            operator: 'lte',
            value: range.endDate,
          },
        ],
      },
      pagination,
    )
      .then((res) => {
        const newReports = addPage ? [...reports, ...res.reports] : res.reports;
        setReports(newReports);
        setTotalAndPage(res.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };
  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getReports(page + 1, true);
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const openActivityDrawer = (activity: Report) => (): void => {
    handleOpenDrawer(activity);
  };

  return (
    <>
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={50} thickness={5} />
        </Grid>
      ) : (
        <>
          {reports.length === 0 && (
            <Typography variant="subtitle1" className="mt-4">
              {getNoItemsInRange(range, heartbeat ? 'heartbeats' : 'reports')}
            </Typography>
          )}
          {reports.map((activity, i) => (
            <TimelineCard
              key={i}
              extraLabel="View Details"
              title={activity.device_name}
              tag={activity.type}
              type={activity.type}
              tagTheme={activityColorThemeMap}
              isLast={i === reports.length - 1}
              caption={timeHelpers.getDate(activity.created_at, false, true)}
              extraCallback={openActivityDrawer(activity)}
            />
          ))}
          {reports.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      <RightDrawer
        open={infoDrawer}
        actionLabel="Done"
        title={'Report Details'}
        disableAction={false}
        actionCallback={handleOpenDrawer}
        onCloseDrawer={handleOpenDrawer}
        content={
          <Box>
            <div>
              <Typography variant="h5" className="custom-label">
                Device Name
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedReport?.device_name}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Type
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedReport?.type}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Date
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedReport?.created_at}
              </Typography>
            </div>
            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Payload
              </Typography>
              <TextInput
                autosize={true}
                prop="payload"
                value={selectedReportPayload}
                multiline
                disabled={true}
              />
            </div>
          </Box>
        }
      />
    </>
  );
};

export default DeviceReportsActivities;
