import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
    },
    errorButton: {
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
      color: theme.palette.error.dark,
    },
    icon: {
      fontSize: '14px',
      marginLeft: '8px',
    },
  }),
);

export default useStyles;
