import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DeviceConfig,
  DeviceConfigs,
  DeviceType,
  NetworkInterfaceReports,
  NetworkInterfaceSummary,
  NetworkInterfacePerformanceReport,
  NetworkInterfaceLatencyReport,
  Devices,
  GatewayCommandRequest,
  GatewayCommandType,
  NetworkInterfaceInfo,
  NetworkInterfaceReport,
  Connection,
  ConnectionType,
} from '@edgeiq/edgeiq-api-js';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AccessTime as AccessTimeIcon,
  Info as InfoIcon,
  FiberManualRecord as DotIcon,
  Refresh as RefreshIcon,
  SignalCellular1Bar as QuarterSignalIcon,
  SignalCellular2Bar as HalfSignalIcon,
  SignalCellular3Bar as ThreeQuartersSignalIcon,
  SignalCellular4Bar as FullSignalIcon,
  Speed as SpeedIcon,
} from '@mui/icons-material';
import clsx from 'clsx';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import timeHelpers from '../../../helpers/timeHelpers';
import { errorHighlight } from '../../../app/constants';
import TextInput from '../../../components/TextInput';
import DateRangePicker from '../../../components/DateRangePicker';
import { DateRangePickerValues } from '../../../components/DateRangePicker/DateRangePicker';
import Accordion from '../../../components/Accordion';
import ContentActivityContainer from '../../../components/ContentActivityContainer';
import { renderTypeIcon } from '../../../containers/Forms/NetworkConfigurationForm/helper';
import DeviceEventsList from '../deviceDetails/deviceActivities/DeviceEventsList';
import NetworkPerformance from './DeviceNetworkPerformance';
import NetworkLatency from './DeviceNetworkLatency';
import NetworkInfo from './DeviceNetworkInfo';
import useStyles from './styles';

interface DeviceNetworkConnectivityProps {
  id: string;
  uniqueId: string;
  deviceType: DeviceType;
  goToItem: (type: string, itemId?: string) => () => void;
}

const DeviceNetworkConnectivity: React.FC<DeviceNetworkConnectivityProps> = ({
  id,
  uniqueId,
  deviceType,
  goToItem,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [networkConfiguration, setNetworkConfiguration] =
    useState<DeviceConfig>();
  const [networkReport, setNetworkReport] = useState<NetworkInterfaceReport>();
  const [networkPerformance, setNetworkPerformance] =
    useState<NetworkInterfacePerformanceReport>();
  const [networkLatency, setNetworkLatency] =
    useState<NetworkInterfaceLatencyReport>();
  // const [openConnectionHistory, setOpenConnectionHistory] = useState(false);
  // const [selectedConnection, setSelectedConnection] = useState<Connection>();
  const [reportsByInterface, setReportsByInterface] = useState<{
    [key: string]: NetworkInterfaceSummary;
  }>();
  const [discoveredInterfaces, setDiscoveredInterfaces] = useState<{
    [key: string]: NetworkInterfaceSummary;
  }>();

  const [open, setOpen] = useState(false);
  const [rangeFilter, setRangeFilter] = useState<DateRangePickerValues>({
    endDate: timeHelpers.getLastDaysDate(0),
    label: 'Last 7 Days',
    startDate: timeHelpers.getLastDaysDate(7),
  });
  const [listExpanded, setListExpanded] = useState(true);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getAndSortReports = (connections?: Connection[]): void => {
    NetworkInterfaceReports.getDeviceNetworkConnectivity(id)
      .then((summary) => {
        if (connections && connections.length) {
          const interfacesNames = connections.map((c) => c.name);
          const auxInterfaces: {
            [key: string]: NetworkInterfaceSummary;
          } = {};
          const auxDiscovered: {
            [key: string]: NetworkInterfaceSummary;
          } = {};
          for (const key in summary) {
            if (Object.prototype.hasOwnProperty.call(summary, key)) {
              const element = summary[key];
              if (interfacesNames.includes(key)) {
                auxInterfaces[key] = element;
              } else {
                auxDiscovered[key] = element;
              }
            }
          }
          setReportsByInterface(auxInterfaces);
          setDiscoveredInterfaces(auxDiscovered);
        } else {
          setDiscoveredInterfaces(summary);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => setLoading(false));
  };

  const getNetworkData = (): void => {
    if (deviceType.device_network_config_id) {
      // Get the network configuration data.
      DeviceConfigs.getOneById(deviceType.device_network_config_id)
        .then((result) => {
          setNetworkConfiguration(result);
          if (result && result.connections) {
            // Get device latest network info, report, latency and performance regardless of the interface
            NetworkInterfaceReports.getDeviceNetworkInterfaceInfo(id)
              .then((deviceInfo) => {
                return NetworkInterfaceReports.getDeviceNetworkInterfaceReport(
                  deviceInfo?.network_interfaces_report_id,
                );
              })
              .then((deviceNetworkReport) => {
                setNetworkReport(deviceNetworkReport);
              })
              .catch((error) => {
                console.info(
                  'Error getting network configuration latest info and report',
                );
                console.info(error);
              });

            NetworkInterfaceReports.getDeviceNetworkInterfaceLatency(id)
              .then((deviceLatency) => {
                setNetworkLatency(deviceLatency);
              })
              .catch((error) => {
                console.info('Error getting network configuration latency');
                console.info(error);
              });

            NetworkInterfaceReports.getDeviceNetworkInterfacePerformance(id)
              .then((devicePerformance) => {
                setNetworkPerformance(devicePerformance);
              })
              .catch((error) => {
                console.info(
                  'Error getting network configuration latency and performance',
                );
                console.info(error);
              });

            getAndSortReports(result.connections);
          } else {
            getAndSortReports();
          }
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    } else {
      getAndSortReports();
    }
  };

  useEffect(() => {
    getNetworkData();
  }, []);

  const processNetworkCommand = (type: string) => (): void => {
    const gatewayRequest: GatewayCommandRequest = {
      command_type: type as GatewayCommandType,
      device_config_id: deviceType.device_network_config_id,
    };
    Devices.processGatewayCommand(id, gatewayRequest)
      .then((_result) => {
        dispatch(
          setAlert({
            message: 'Command sent successfully. Refresh to see the data',
            type: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  const handleRefresh = (): void => {
    getNetworkData();
  };

  const goToNetworkConfigurations = (): void => {
    navigate('/network-configurations');
  };

  const renderButtons = (): ReactElement => (
    <div>
      <Tooltip title="Refresh data" placement="top">
        <IconButton
          aria-label="Refresh data"
          color="primary"
          onClick={handleRefresh}
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Get Connection Info" placement="top">
        <IconButton
          aria-label="Get Connection Info"
          className="ml-2 p-1"
          color="primary"
          onClick={processNetworkCommand('network_connectivity_info')}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Test Latency" placement="top">
        <IconButton
          aria-label="Test Latency"
          className="ml-2 p-1"
          color="primary"
          onClick={processNetworkCommand('network_connectivity_latency_test')}
        >
          <AccessTimeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Test Performance" placement="top">
        <IconButton
          aria-label="Test Performance"
          className="ml-2 p-1"
          color="primary"
          onClick={processNetworkCommand(
            'network_connectivity_performance_test',
          )}
        >
          <SpeedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  const renderDataPair = (
    label: string,
    data: string,
    icon?: ReactElement,
  ): ReactElement => (
    <>
      <Typography
        component="div"
        variant="button"
        className={classes.dataLabel}
      >
        {icon && icon}
        {label}
      </Typography>
      <Typography component="div" variant="button" className={classes.data}>
        {data ? data : '--'}
      </Typography>
    </>
  );

  const renderLinkStatus = (info: NetworkInterfaceInfo): ReactElement => {
    const isUp = info.link_status.toLocaleLowerCase() === 'up';
    return (
      <Typography
        variant="caption"
        component="div"
        className={clsx(`ml-2 mr-2 d-flex flex-items-center`, classes.label, {
          ['error']: !isUp,
          ['success']: isUp,
        })}
        color={isUp ? 'success' : 'error'}
      >
        <DotIcon className={clsx('mr-1', classes.icon)} />
        INTERFACE {isUp ? 'UP' : 'DOWN'}
      </Typography>
    );
  };

  const renderInternetStatus = (
    report: NetworkInterfaceReport,
  ): ReactElement => {
    const isConnected = report.internet_reachable;
    return (
      <Typography
        variant="caption"
        component="div"
        className={clsx(
          `ml-2 mr-2 d-flex flex-items-center ${
            isConnected ? 'success' : 'error'
          }`,
          classes.label,
        )}
        color={isConnected ? 'success' : 'error'}
      >
        <DotIcon className={clsx('mr-1', classes.icon)} />
        INTERNET {isConnected ? 'UP' : 'DOWN'}
      </Typography>
    );
  };

  const renderSignal = (info: NetworkInterfaceInfo): ReactElement => {
    let signal;
    if (info.cellular_strength) {
      signal = parseInt(info.cellular_strength);
    }
    return typeof signal !== 'undefined' ? (
      <Typography
        variant="caption"
        component="div"
        className="d-flex flex-items-center"
      >
        {signal <= 0.25 && (
          <QuarterSignalIcon className={clsx('mr-1', classes.icon)} />
        )}
        {signal > 0.25 && signal <= 0.5 && (
          <HalfSignalIcon className={clsx('mr-1', classes.icon)} />
        )}
        {signal > 0.5 && signal <= 0.75 && (
          <ThreeQuartersSignalIcon className={clsx('mr-1', classes.icon)} />
        )}
        {signal > 0.75 && (
          <FullSignalIcon className={clsx('mr-1', classes.icon)} />
        )}
        <em>
          <b>
            {signal}% {info.cellular_mode.toLocaleUpperCase()}
            {info.cellular_3gpp_registration_state === 'roaming' && ' R'}
          </b>
        </em>
      </Typography>
    ) : (
      <></>
    );
  };

  const renderInterfaceAccordion = (
    name: string,
    type: ConnectionType,
    info?: NetworkInterfaceInfo,
    latencies?: NetworkInterfaceLatencyReport[],
    performances?: NetworkInterfacePerformanceReport[],
    showNoData = true,
  ): ReactElement => (
    <Accordion
      className="px-4"
      title={name}
      openByDefault={true}
      fullBorder={true}
      nextTitle={
        <>
          {renderTypeIcon(type)}
          {info && renderLinkStatus(info)}
          {info && renderSignal(info)}
        </>
      }
      content={
        <Grid container spacing={3}>
          {info && (
            <NetworkInfo
              classes={classes}
              info={info}
              renderDataPair={renderDataPair}
            />
          )}
          {latencies && (
            <Grid item xs={12}>
              <NetworkLatency
                latencies={latencies}
                classes={classes}
                renderDataPair={renderDataPair}
              />
            </Grid>
          )}
          {performances && (
            <Grid item xs={12}>
              <NetworkPerformance
                performances={performances}
                classes={classes}
                renderDataPair={renderDataPair}
              />
            </Grid>
          )}
          {showNoData && !info && !latencies && !performances && (
            <Grid item xs={12}>
              <Typography component="div" variant="button">
                {`No Connection Info received for this interface yet. Please run the 'Get Connection Info' command to retrieve the interface data from the device. If no data is shown, verify that the interface with the given name is present on the device.`}
                <Button
                  variant="text"
                  size="small"
                  className={clsx('ml-2 p-0', classes.button)}
                  onClick={goToItem(
                    'network-configuration',
                    networkConfiguration?._id,
                  )}
                >
                  See network configuration
                </Button>
              </Typography>
            </Grid>
          )}
        </Grid>
      }
    />
  );

  const handleRangeChange = (range: DateRangePickerValues): void => {
    setRangeFilter(range);
    toggleRangePicker();
  };

  const toggleRangePicker = (): void => {
    setOpen(!open);
  };

  return (
    <Grid container>
      {loading ? (
        <Grid
          item
          xs={12}
          component={Paper}
          className={clsx('p-7 shadow loading-container')}
        >
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : networkConfiguration ? (
        <ContentActivityContainer
          tooltipLabel="Connection History"
          contentShadow={false}
          sidebarShadow={true}
          listExpanded={listExpanded}
          updateCollapsedState={setListExpanded}
          content={
            <Grid container>
              <Grid
                item
                xs={12}
                component={Paper}
                className={clsx('p-7 shadow')}
              >
                <div className="mb-3 d-flex flex-justify-between">
                  {networkConfiguration && (
                    <div className="d-flex flex-items-center">
                      <Typography component="span" variant="h5">
                        {networkConfiguration.name}
                      </Typography>
                      {networkReport && renderInternetStatus(networkReport)}
                      <Button
                        variant="text"
                        size="small"
                        className={clsx('ml-2 p-0', classes.button)}
                        onClick={goToItem(
                          'network-configuration',
                          networkConfiguration._id,
                        )}
                      >
                        Go to network configuration
                      </Button>
                    </div>
                  )}
                  {renderButtons()}
                </div>
                {networkPerformance && (
                  <NetworkPerformance
                    performances={[networkPerformance]}
                    classes={classes}
                    renderDataPair={renderDataPair}
                  />
                )}
                {networkLatency && (
                  <NetworkLatency
                    latencies={[networkLatency]}
                    classes={classes}
                    renderDataPair={renderDataPair}
                  />
                )}
                <Typography variant="h6" className="mb-4">
                  Interfaces
                </Typography>
                {networkConfiguration.connections?.map((connection, i) => {
                  if (reportsByInterface?.[connection.name]) {
                    const info = reportsByInterface?.[connection.name].info;
                    const latencies =
                      reportsByInterface?.[connection.name].latency;
                    const performances =
                      reportsByInterface?.[connection.name].performance;
                    return (
                      <div key={`interface-${i}-${connection.name}`}>
                        {renderInterfaceAccordion(
                          connection.name,
                          connection.type,
                          info,
                          latencies,
                          performances,
                        )}
                      </div>
                    );
                  }
                  return <></>;
                })}
              </Grid>

              {discoveredInterfaces &&
                Object.keys(discoveredInterfaces).length !== 0 && (
                  <Grid
                    item
                    xs={12}
                    component={Paper}
                    className={clsx('mt-6 p-7 shadow')}
                  >
                    <Typography variant="h5" className="mb-4">
                      Discovered interfaces
                    </Typography>
                    {Object.keys(discoveredInterfaces).map(
                      (discoveredInterface, i) => {
                        const info =
                          discoveredInterfaces[discoveredInterface].info;
                        const latencies =
                          discoveredInterfaces[discoveredInterface].latency;
                        const performances =
                          discoveredInterfaces[discoveredInterface].performance;
                        return (
                          <div
                            key={`discovered-interface-${i}-${info?.interface_name}`}
                          >
                            {renderInterfaceAccordion(
                              info?.interface_name ?? '',
                              (info?.interface_type as ConnectionType) ??
                                'cellular',
                              info,
                              latencies,
                              performances,
                              false,
                            )}
                          </div>
                        );
                      },
                    )}
                  </Grid>
                )}
            </Grid>
          }
          sidebar={
            <Grid item className="p-3">
              <Grid item xs={12}>
                <Typography className={clsx('mb-4')} variant="h5">
                  Connection History
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  prop="date_range"
                  placeholder="Date range"
                  value={rangeFilter?.label ?? ''}
                  handleClick={toggleRangePicker}
                />
                <DateRangePicker
                  openDialog={open}
                  onDatesChange={handleRangeChange}
                  onClose={toggleRangePicker}
                />
              </Grid>
              <Grid item xs={12} className="mt-4">
                <DeviceEventsList
                  id={uniqueId}
                  range={rangeFilter}
                  noDataLabel="connection history"
                  onlyConnection={true}
                />
              </Grid>
            </Grid>
          }
        />
      ) : (
        <Grid
          component={Paper}
          item
          xs={12}
          className={clsx('p-7 shadow loading-container')}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography component="div" variant="button">
                The Device Profile has no associated Network Configuration,
                please create or associate a Network Configuration with the
                Device Profile.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.link}
                variant="text"
                color="primary"
                onClick={goToNetworkConfigurations}
              >
                go to Network Configurations
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DeviceNetworkConnectivity;
