import { ReactElement, useEffect, useState } from 'react';
import { ClassNameMap, Grid, Tab, Tabs, Typography } from '@mui/material';
import {
  Download as DownloadIcon,
  Upload as UploadIcon,
} from '@mui/icons-material';
import { NetworkInterfacePerformanceReport } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import timeHelpers from '../../../helpers/timeHelpers';

/**
 * Performance Report component, any data related to the performance should be added/proccessed here.
 */
const NetworkPerformance: React.FC<{
  performances: NetworkInterfacePerformanceReport[];
  classes: ClassNameMap;
  renderDataPair: (
    label: string,
    data: string,
    icon?: ReactElement,
  ) => ReactElement;
}> = ({ performances, classes, renderDataPair }) => {
  const [isOnlyOne, setIsOnlyOne] = useState(true);
  const [chosenTab, setChosenTab] = useState(performances[0]?.server_id ?? '');
  const [performance, setPerformance] =
    useState<NetworkInterfacePerformanceReport>();

  useEffect(() => {
    if (performances.length !== 0) {
      setChosenTab(performances[0].server_id);
      setPerformance(performances[0]);
      setIsOnlyOne(performances.length === 1);
    }
  }, [performances]);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: string,
  ): void => {
    setChosenTab(newValue);
    setPerformance(performances.find((p) => newValue === p.server_id));
  };

  return (
    <Grid container spacing={2} className="mb-4">
      <Grid item xs={12}>
        {isOnlyOne ? (
          <Typography variant="h6" className="m-0">
            Latest Performance Test
            <Typography
              component="span"
              variant="overline"
              className={clsx('ml-3', classes.dataLabel)}
            >
              {' '}
              {performance ? timeHelpers.getDate(performance.created_at) : '--'}
            </Typography>
          </Typography>
        ) : (
          <div className="d-flex flex-items-center">
            <Typography component="span" variant="h6" className="m-0">
              Performance Tests{' '}
              <Typography
                component="span"
                variant="overline"
                className={clsx('ml-3', classes.dataLabel)}
              >
                Servers
              </Typography>
            </Typography>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={chosenTab}
              onChange={handleChange}
            >
              {performances.map(
                (per, index): ReactElement => (
                  <Tab
                    key={`tab-${per.server_id}-${index}`}
                    label={per.server_id}
                    value={per.server_id}
                  />
                ),
              )}
            </Tabs>
          </div>
        )}
      </Grid>
      {performance && (
        <>
          {!isOnlyOne && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                'Tested at',
                timeHelpers.getDate(performance.created_at),
              )}
            </Grid>
          )}
          {performance.server_id && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair('Server ID', performance.server_id)}
            </Grid>
          )}
          {performance.jitter_ms && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair('Jitter', `${String(performance.jitter_ms)} ms`)}
            </Grid>
          )}
          {performance.packet_loss_percent &&
            performance.packet_loss_percent >= 0 && (
              <Grid item xs={12} md={6} lg={3}>
                {renderDataPair(
                  'Packet Loss',
                  `${String(performance.packet_loss_percent)}%`,
                )}
              </Grid>
            )}
          {performance.download_speed_mbps && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                'Download Speed',
                `${String(performance.download_speed_mbps.toFixed(2))} Mbps`,
                <DownloadIcon className={clsx('mr-1', classes.icon)} />,
              )}
            </Grid>
          )}
          {performance.upload_speed_mbps && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                'Upload Speed',
                `${String(performance.upload_speed_mbps.toFixed(2))} Mbps`,
                <UploadIcon className={clsx('mr-1', classes.icon)} />,
              )}
            </Grid>
          )}
          {performance.latency_ms && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                'Latency',
                `${String(performance.latency_ms)} ms`,
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default NetworkPerformance;
