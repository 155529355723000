import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Breakpoint,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';

import useStyles from './styles';

export interface BasicDialogProps {
  open: boolean; // Status of the dialog (dafault: false)
  onClose: () => void; // Trigger when the dialog is closing
  title?: JSX.Element | string; // Header part of the dialog
  content?: JSX.Element | string; // Main part of the dialog, use it to insert most of the information
  actions?: JSX.Element | string; // Bottom part of the dialog, use it to insert the actions
  maxWidth?: Breakpoint | false; // Width of the Dialog
  showCloseIcon?: boolean; // Includes a close icon button
  noPadding?: boolean; // Removes the padding from the dialog content
}

const BasicDialog: React.FC<BasicDialogProps> = ({
  open,
  onClose,
  title,
  content,
  actions,
  maxWidth,
  showCloseIcon = true,
  noPadding = false,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className="p-6"
      open={open}
      maxWidth={maxWidth}
      onClose={onClose}
      data-testid="basic-dialog"
      children={
        <>
          {(typeof title !== 'undefined' || showCloseIcon) && (
            <DialogTitle>
              {typeof title === 'string' ? (
                <Typography variant="h6" component="div" data-testid="title">
                  {title}
                </Typography>
              ) : (
                title
              )}
              {showCloseIcon ? (
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  data-testid="close-btn"
                  className={clsx(classes.closeIcon)}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </DialogTitle>
          )}
          <DialogContent
            data-cy="dialog-content"
            data-testid="content"
            className={clsx({
              ['p-0']: noPadding,
            })}
          >
            {typeof content === 'string' ? (
              <Typography className={clsx(classes.content)} variant="button">
                {content}
              </Typography>
            ) : (
              content
            )}
          </DialogContent>
          {actions && (
            <DialogActions className="p-6" data-testid="actions">
              {actions}
            </DialogActions>
          )}
        </>
      }
    />
  );
};

export default BasicDialog;
