import React, { useState } from 'react';
import { DateTime } from 'luxon';
import {
  DateRangePicker as Picker,
  DateRange,
  DefinedRange,
} from 'materialui-daterange-picker';
import { Box, Button } from '@mui/material';

import timeHelpers from '../../helpers/timeHelpers';
import BasicDialog from '../BasicDialog';
import './DateRangePicker.css';

export interface DateRangePickerValues {
  startDate: string;
  endDate: string;
  label: string;
}
const nowDateTime = DateTime.now();
const nowEndDate = nowDateTime.toJSDate();
export const DEFINED_RANGES: DefinedRange[] = [
  {
    endDate: nowEndDate,
    label: 'Last 7 Days',
    startDate: nowDateTime.minus({ days: 7 }).toJSDate(),
  },
  {
    endDate: nowEndDate,
    label: 'This Month',
    startDate: nowDateTime.startOf('month').toJSDate(),
  },
  {
    endDate: nowDateTime.minus({ month: 1 }).endOf('month').toJSDate(),
    label: 'Last Month',
    startDate: nowDateTime.minus({ month: 1 }).startOf('month').toJSDate(),
  },
  {
    endDate: nowEndDate,
    label: 'This Year',
    startDate: nowDateTime.startOf('year').toJSDate(),
  },
  {
    endDate: nowDateTime.minus({ year: 1 }).endOf('year').toJSDate(),
    label: 'Last Year',
    startDate: nowDateTime.minus({ year: 1 }).startOf('year').toJSDate(),
  },
];

export interface DateTimePickerProps {
  openDialog: boolean;
  onClose: () => void;
  onDatesChange: (range: DateRangePickerValues) => void;
}

const DateRangePicker: React.FC<DateTimePickerProps> = ({
  openDialog,
  onClose,
  onDatesChange,
}) => {
  const [open, setOpen] = useState(true);
  const [dateRange, setDateRange] = React.useState<DateRange | DefinedRange>(
    DEFINED_RANGES[0],
  );

  const toggle = (): void => setOpen(!open);

  const handleDateChange = (range: DateRange | DefinedRange): void => {
    setDateRange(range);
  };

  const applyChange = (): void => {
    let label = '';
    if ((dateRange as DefinedRange).label) {
      label = (dateRange as DefinedRange).label;
    } else if (dateRange.startDate && dateRange.endDate) {
      label = `From ${timeHelpers.getPlainDate(
        dateRange.startDate.toISOString(),
      )} To ${timeHelpers.getPlainDate(dateRange.endDate.toISOString())}`;
    }
    onDatesChange({
      endDate: dateRange.endDate?.toISOString() ?? '',
      label,
      startDate: dateRange.startDate?.toISOString() ?? '',
    });
  };

  return (
    <BasicDialog
      maxWidth={false}
      showCloseIcon={false}
      noPadding={true}
      open={openDialog}
      onClose={onClose}
      content={
        <>
          <Picker
            open={open}
            closeOnClickOutside={false}
            initialDateRange={dateRange}
            definedRanges={DEFINED_RANGES}
            toggle={toggle}
            onChange={handleDateChange}
            wrapperClassName="date-range-picker"
          />
          <Box className="d-flex flex-justify-end px-4 mb-4">
            <Button
              size="large"
              variant="outlined"
              className="mr-3"
              style={{
                width: '150px',
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              style={{
                width: '150px',
              }}
              onClick={applyChange}
            >
              Apply
            </Button>
          </Box>
        </>
      }
    />
  );
};

export default DateRangePicker;
