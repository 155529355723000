import { Actions, Abilities, AbilityAndAction } from '@edgeiq/edgeiq-api-js';
import {
  AWSIOT_ATTACH_THING_GROUPS,
  AWSIOT_DETACH_THING_GROUPS,
  CANCEL_PASSWORD_CHANGE,
  FILE_FROM_DEVICE,
  FILE_TO_DEVICE,
  LWM2M_FILE,
} from '../app/constants';

const AWSIOT_THING_GROUP_ABILITY = 'aws_iot';

// not all gateway commands correspond 1:1 to the action (or ability on the device profile),
// so we need to map them
// source: https://github.com/MachineShop-IOT/models/blob/develop/pkg/models/gatewaycommand.go#L162
const mapGatewayCommandToAction = (gatewayCommand: string): keyof Actions => {
  if (
    gatewayCommand === AWSIOT_ATTACH_THING_GROUPS ||
    gatewayCommand === AWSIOT_DETACH_THING_GROUPS ||
    gatewayCommand === 'greengrass_initialize' ||
    gatewayCommand === 'greengrass_restart' ||
    gatewayCommand === 'greengrass_redeploy'
  ) {
    return AWSIOT_THING_GROUP_ABILITY as keyof Actions;
  }
  if (gatewayCommand === CANCEL_PASSWORD_CHANGE) {
    return 'reset' as keyof Actions;
  }
  if (
    gatewayCommand === FILE_TO_DEVICE ||
    gatewayCommand === FILE_FROM_DEVICE ||
    gatewayCommand === LWM2M_FILE
  ) {
    return 'file_transfer' as keyof Actions;
  }
  if (
    gatewayCommand === 'network_connectivity_info' ||
    gatewayCommand === 'network_connectivity_latency_test' ||
    gatewayCommand === 'network_connectivity_performance_test'
  ) {
    return 'network_connectivity_monitoring' as keyof Actions;
  }
  return gatewayCommand as keyof Actions;
};

const parsePermissions = (
  abilities: Abilities,
): {
  [key: string]: {
    create: boolean;
    update: boolean;
    read: boolean;
    delete: boolean;
  };
} => {
  const result: {
    [key: string]: {
      create: boolean;
      update: boolean;
      read: boolean;
      delete: boolean;
    };
  } = {};
  for (const model in abilities) {
    if (Object.prototype.hasOwnProperty.call(abilities, model)) {
      const modelPermissions = abilities[model as keyof Abilities];
      result[model] = {
        create: modelPermissions.create !== 'not_allowed',
        delete: modelPermissions.delete !== 'not_allowed',
        read: modelPermissions.read !== 'not_allowed',
        update: modelPermissions.update !== 'not_allowed',
      };
    }
  }
  return result;
};

const allowActions = (
  abilities: Abilities,
  actions: string[],
  model: string,
  userCompanyId?: string,
  itemCompanyId?: string,
): boolean => {
  const modelPermissions = abilities[
    model as keyof Abilities
  ] as AbilityAndAction;
  let allowed = true;
  actions.forEach((action) => {
    if (modelPermissions[action] === 'not_allowed') {
      allowed = false;
    } else if (
      modelPermissions[action] === 'self' &&
      userCompanyId &&
      itemCompanyId &&
      itemCompanyId !== userCompanyId
    ) {
      allowed = false;
    }
  });
  return allowed;
};

const allowOnlyRead = (abilities: Abilities, model: string): boolean => {
  const modelPermissions = abilities[
    model as keyof Abilities
  ] as AbilityAndAction;
  const nonReadActions = ['create', 'update', 'delete'];
  let onlyRead = true;
  nonReadActions.forEach((action) => {
    if (modelPermissions[action] !== 'not_allowed') {
      onlyRead = false;
    }
  });
  return onlyRead;
};

const allowOneOf = (
  abilities: Abilities,
  actions: string[],
  model: string,
): boolean => {
  const modelPermissions = abilities[
    model as keyof Abilities
  ] as AbilityAndAction;
  let allow = false;
  actions.forEach((action) => {
    if (modelPermissions[action] !== 'not_allowed') {
      allow = true;
    }
  });
  return allow;
};
// TODO: add a function to ask if a user has permission to a specific item

export {
  allowActions,
  allowOneOf,
  allowOnlyRead,
  parsePermissions,
  mapGatewayCommandToAction,
};
