import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { PaginationFilter, Workflows, Workflow } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setStateWorkflows } from '../../redux/reducers/workflows.reducer';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  defaultItemsPerPage,
  errorHighlight,
  genericViewOptions,
} from '../../app/constants';
import {
  CREATED_OLDEST_SORTING,
  CREATED_NEWEST_SORTING,
  LAST_UPDATED_SORTING,
  SORTING_OPTIONS_GENERIC_NAME,
} from '../../constants/sortings';
import { SortingOption } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import ListSelection from '../../components/ListSelection';
import SharedTable from '../../components/SharedTable';
import CardsGrid from '../../components/CardsGrid';
import Card from '../../components/Card';
import Header from '../../containers/HeaderWithActionButton';
import WorkflowsFilters from './WorkflowsFilters';
import WorkflowCard from './WorkflowCard';
import { WorkflowColumns } from './columns';
import useStyles from './styles';

const WorkflowsPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const { userCompanies } = useAppSelector((state: RootState) => state.user);

  const [selectedWorkflows, setSelectedWorkflows] = useState<string[]>([]);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.workflows.sortBy, SORTING_OPTIONS_GENERIC_NAME),
  );
  const [selectedView, setSelectedView] = useState(filters.workflows.view);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getWorkflows = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    Workflows.list(
      parseFilters(filters.workflows.filters ?? {}, filters.case_sensitive),
      pagination,
    )
      .then((result) => {
        dispatch(setStateWorkflows(result.workflows));
        setTotal(result.pagination.total);
        sortWorkflows(result.workflows);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setSelectedWorkflows([]);
    getWorkflows(1);
  }, [filters.workflows.filters]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getWorkflows(page + 1, true);
  };

  const checkProfileCallback =
    (deviceId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedWorkflows([...selectedWorkflows, deviceId]);
      } else {
        setSelectedWorkflows(
          selectedWorkflows.filter((item) => item !== deviceId),
        );
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'workflows'));
    setSelectedSorting(option);
  };

  const sortDates = (
    a: Workflow,
    b: Workflow,
    key: 'created_at' | 'updated_at',
    order = 'desc',
  ): number => {
    const keyA = new Date(a[key]);
    const keyB = new Date(b[key]);
    if (keyA < keyB) {
      return order === 'desc' ? 1 : -1;
    }
    if (keyA > keyB) {
      return order === 'desc' ? -1 : 1;
    }
    return 0;
  };

  const sortWorkflows = (workflowsToSort: Workflow[]): void => {
    const wfs = [...workflowsToSort];
    if (wfs.length > 1) {
      let sortedWorkflows: Workflow[] = [];
      if (selectedSorting.value === LAST_UPDATED_SORTING.value) {
        sortedWorkflows = wfs.sort((a, b) => {
          return sortDates(a, b, 'updated_at');
        });
      }

      if (selectedSorting.value === CREATED_NEWEST_SORTING.value) {
        sortedWorkflows = wfs.sort((a, b) => {
          return sortDates(a, b, 'created_at');
        });
      }

      if (selectedSorting.value === CREATED_OLDEST_SORTING.value) {
        sortedWorkflows = wfs.sort((a, b) => {
          return sortDates(a, b, 'created_at', 'asc');
        });
      }

      if (selectedSorting.value === 'name') {
        sortedWorkflows = wfs.sort((a, b) => a.name.localeCompare(b.name));
      }
      setWorkflows(sortedWorkflows);
    } else {
      setWorkflows(workflowsToSort);
    }
  };

  /**
   * This useEffect is to sort the WFs as it is not possible to do in the BE for now.
   * Instead of changing the whole structure, we just detect each time the selected sorting
   * is changed and sort the WFs according to that.
   */
  useEffect(() => {
    sortWorkflows(workflows);
  }, [selectedSorting]);

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'workflows'));
    setSelectedView(view);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Workflows"
        link="new-workflow"
        actionLabel="Create WorkFlow"
        model={'workflow'}
      />
      <WorkflowsFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={SORTING_OPTIONS_GENERIC_NAME}
        viewsOptions={genericViewOptions}
        itemsSelected={false}
        allSelected={false}
        showSelectAll={false}
        deleteAction={false}
        sortingCallback={handleSorting}
        selectViewCallback={handleSelectView}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && (
            <CardsGrid
              cards={workflows.map((workflow) => (
                <Card
                  checked={selectedWorkflows.includes(workflow._id)}
                  checkboxCallback={checkProfileCallback}
                  noCheckbox={true}
                  id={workflow._id}
                  baseLink={`/workflow`}
                  content={
                    <WorkflowCard
                      workflow={workflow}
                      companies={userCompanies}
                    />
                  }
                />
              ))}
            />
          )}
          {selectedView === 'list' && (
            <SharedTable
              columns={WorkflowColumns(userCompanies)}
              rows={workflows}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={selectedWorkflows.length === workflows.length}
              loading={loading}
              selectedItemsIds={selectedWorkflows}
              viewOnly={true}
            />
          )}

          {workflows.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default WorkflowsPage;
