import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { DeviceConfig, DeviceConfigInput } from '@edgeiq/edgeiq-api-js';

// import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import CheckboxInput from '../../../components/CheckboxInput';

interface InterfaceDataCollectionProps {
  deviceConfig: DeviceConfig | DeviceConfigInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
}

const InterfaceDataCollection: React.FC<InterfaceDataCollectionProps> = ({
  deviceConfig,
  onInputChange,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CheckboxInput
          label="Enable Interface Data Collection"
          prop="interface_data_collection.enabled"
          value="interface_data_collection.enabled_checkbox"
          checked={deviceConfig.interface_data_collection?.enabled}
          onCheckboxClick={onInputChange}
        />
      </Grid>
      {deviceConfig.interface_data_collection?.enabled && (
        <>
          {/* <Grid item xs={12} lg={6}>
            <TextInput
              label="Frequency (s)"
              prop="interface_data_collection.frequency_seconds"
              type="number"
              value={deviceConfig.interface_data_collection.frequency_seconds}
              onInputChange={onInputChange}
            />
          </Grid> */}
          {deviceConfig.connections && (
            <Grid item xs={12} lg={6}>
              <SelectInput
                multiple
                label="Interfaces"
                prop="interface_data_collection.interfaces"
                values={deviceConfig.interface_data_collection.interfaces ?? []}
                onMultiSelectChange={onInputChange}
                options={deviceConfig.connections?.map((network) => (
                  <MenuItem
                    dense
                    key={`data_collection_interface_${network.name}`}
                    value={network.name}
                  >
                    {network.name}
                  </MenuItem>
                ))}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default InterfaceDataCollection;
