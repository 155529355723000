import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
    },
    data: {
      color: theme.palette.grey[100],
    },
    dataLabel: {
      color: theme.palette.grey[200],
    },
    icon: {
      '&.error': {
        color: theme.palette.error.dark,
      },
      '&.success': {
        color: theme.palette.success.dark,
      },
      fontSize: '14px',
    },
    label: {
      '&.error': {
        color: theme.palette.error.dark,
      },
      '&.success': {
        color: theme.palette.success.dark,
      },
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    tag: {
      height: '18px',
    },
  }),
);

export default useStyles;
