import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { Button, Divider, Grid, IconButton, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { MetadataFilter } from '../../../models/common';
import {
  errorHighlight,
  SOFTWARE_VERSION_OPERATORS,
} from '../../../app/constants';
import {
  getMetadataFilterKey,
  parseSoftwareVersionFilters,
  updateMetadataFilters,
} from '../../../helpers/parseFilters';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import { SoftwareVersion, SoftwareVersions } from '@edgeiq/edgeiq-api-js';

interface DeviceSoftwareVersionFilterProps {
  softwareVersionFilters: MetadataFilter[];
  onFiltersChange: (
    prop: string,
    value: string | { [key: string]: string },
  ) => void;
}

const DeviceSoftwareVersionFilter: React.FC<
  DeviceSoftwareVersionFilterProps
> = ({ softwareVersionFilters, onFiltersChange }) => {
  const dispatch = useAppDispatch();
  const [softwareVersionItems, setSoftwareVersionItems] = useState<
    MetadataFilter[]
  >(softwareVersionFilters);

  const [availableSoftwares, setAvailableSoftwares] = useState<
    SoftwareVersion[]
  >([]);

  const dispatchError = (errorMessage: string, highlight?: string): void => {
    dispatch(
      setAlert({
        highlight: highlight ?? errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  useEffect(() => {
    SoftwareVersions.listDistinctVersions()
      .then((result) => {
        setAvailableSoftwares(result);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const updateSoftwareVersionItems = (
    softwareVersion: MetadataFilter[],
  ): void => {
    onFiltersChange(
      'software_version_in',
      parseSoftwareVersionFilters(softwareVersion),
    );
  };

  const updateSoftwareVersionDebounce = useCallback(
    debounce(updateSoftwareVersionItems, 300),
    [],
  );

  const handleChange = (
    index: number,
    key: string,
    changeValue: string | string[],
  ): void => {
    const auxArray = updateMetadataFilters(
      softwareVersionItems,
      index,
      key,
      changeValue,
    );
    setSoftwareVersionItems(auxArray);
    updateSoftwareVersionDebounce.cancel();
    updateSoftwareVersionDebounce(auxArray);
  };

  const handleRemove = (index: number): void => {
    const auxArray = [...softwareVersionItems];
    auxArray.splice(index, 1);
    setSoftwareVersionItems(auxArray);
    onFiltersChange(
      'software_version_in',
      parseSoftwareVersionFilters(auxArray),
    );
  };

  const addNewMetadata = (): void => {
    setSoftwareVersionItems([
      ...softwareVersionItems,
      { key: '', operator: 'eq', value: '' },
    ]);
  };

  const handleInputChange =
    (index: number, prop: string) =>
    (_name: string, changeValue: string | number): void => {
      handleChange(index, prop, changeValue as string);
    };

  const handleRemoveClick = (index: number) => (): void => {
    handleRemove(index);
  };

  const renderRemoveButton = (index: number): JSX.Element => (
    <Grid item xs={1} className="mt-6">
      <IconButton onClick={handleRemoveClick(index)}>
        <DeleteIcon />
      </IconButton>
    </Grid>
  );

  return (
    <Box>
      {softwareVersionItems.map((item, index) => {
        const isInstalled = item.operator === 'installed';
        return (
          <Grid
            key={index}
            container
            direction="row"
            spacing={2}
            className={clsx({
              ['mt-2']: index === 0,
            })}
            alignItems="center"
          >
            <Grid item xs={4}>
              <SelectInput
                label="Software Name"
                prop="key"
                value={getMetadataFilterKey(item.key)}
                onSelectChange={handleInputChange(index, 'key')}
                options={availableSoftwares.map((software) => (
                  <MenuItem
                    dense
                    key={`metadata-filter-${software.name}-${index}`}
                    value={software.name}
                  >
                    {software.name}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                label="Operator"
                prop="operator"
                value={item.operator}
                onSelectChange={handleInputChange(index, 'operator')}
                options={Object.keys(SOFTWARE_VERSION_OPERATORS).map(
                  (operator) => (
                    <MenuItem
                      dense
                      key={`metadata-filter-${operator}-${index}`}
                      value={operator}
                    >
                      {SOFTWARE_VERSION_OPERATORS[operator]}
                    </MenuItem>
                  ),
                )}
              />
            </Grid>
            {!isInstalled && (
              <Grid item xs={3}>
                <TextInput
                  label="Value"
                  prop="value"
                  value={item.value as string}
                  onInputChange={handleInputChange(index, 'value')}
                />
              </Grid>
            )}
            {renderRemoveButton(index)}
            {index !== softwareVersionItems.length - 1 && (
              <Grid item xs={12} className="my-2">
                <Divider />
              </Grid>
            )}
          </Grid>
        );
      })}
      <Button
        variant="outlined"
        size="medium"
        className="mt-6"
        onClick={addNewMetadata}
        startIcon={<AddIcon />}
      >
        Add new
      </Button>
    </Box>
  );
};

export default DeviceSoftwareVersionFilter;
