import React, { ReactElement, useState } from 'react';
import { Button, Grid, IconButton, MenuItem } from '@mui/material';
import {
  Add as AddIcon,
  CellTower as CellTowerIcon,
  Delete as DeleteIcon,
  Lan as LanIcon,
  Language as LanguageIcon,
  SettingsEthernet as SettingsEthernetIcon,
  Wifi as WifiIcon,
} from '@mui/icons-material';
import {
  DeviceConfigInput,
  DeviceConfig,
  ConnectionType,
  CellularAPNConfig,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { connectionsLabel } from '../../../app/constants';
import Accordion from '../../../components/Accordion';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import CellularInterface from './NetworkInterfacesForms/CellularInterface';
import EthernetLanWanInterface from './NetworkInterfacesForms/EthernetLanWanInterface';
import useStyles from './styles';
import WifiInterface from './NetworkInterfacesForms/WifiInterface';
import { cloneDeep } from 'lodash';

interface NetworkConfigurationInterfacesProps {
  deviceConfig: DeviceConfigInput | DeviceConfig;
  disabled?: boolean;
  onInputChange: (
    prop: string,
    value: string | number | string[] | CellularAPNConfig[],
  ) => void;
  onAddNewInteface: () => void;
  onRemoveInteface: (index: number) => void;
}

const NetworkConfigurationInterfaces: React.FC<
  NetworkConfigurationInterfacesProps
> = ({
  deviceConfig,
  disabled,
  onInputChange,
  onAddNewInteface,
  onRemoveInteface,
}) => {
  const classes = useStyles();
  const [showAdvancedConfig, setShowAdvancedConfig] = useState<boolean[]>([]);

  const handleClickAddInterface = () => (): void => {
    setShowAdvancedConfig([...showAdvancedConfig, false]);
    onAddNewInteface();
  };

  const handleRemoveInterface =
    (index: number) =>
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      onRemoveInteface(index);
    };

  const renderTypeIcon = (type: ConnectionType): ReactElement => {
    switch (type) {
      case 'cellular':
        return <CellTowerIcon className={classes.icon} />;
      case 'ethernet_lan':
        return (
          <>
            <SettingsEthernetIcon className={classes.icon} />
            <LanIcon className={clsx('ml-1', classes.icon)} />
          </>
        );
      case 'ethernet_wan':
        return (
          <>
            <SettingsEthernetIcon className={classes.icon} />
            <LanguageIcon className={clsx('ml-1', classes.icon)} />
          </>
        );
      case 'ethernet_wan_lan':
        return (
          <>
            <SettingsEthernetIcon className={classes.icon} />
            <LanguageIcon className={clsx('ml-1', classes.icon)} />
            <LanIcon className={clsx('ml-1', classes.icon)} />
          </>
        );
      case 'wifi':
        return <WifiIcon className={classes.icon} />;
      default:
        return <></>;
    }
  };

  const handleAdvancedConfig = (index: number) => (): void => {
    const aux = cloneDeep(showAdvancedConfig);
    aux[index] = !showAdvancedConfig[index];
    setShowAdvancedConfig(aux);
  };

  const handleInterfaceConfigChange = (
    prop: string,
    value: string | number | CellularAPNConfig[],
    _field: string,
    index: number,
  ): void => {
    onInputChange(`connections.${index}.config.${prop}`, value);
  };

  return (
    <>
      {deviceConfig.connections &&
        deviceConfig.connections.map((connection, index) => (
          <Accordion
            key={`network-connection-${index}`}
            className="pl-4 pr-2"
            title={connection.name}
            openByDefault={true}
            fullBorder={true}
            nextIcon={
              <IconButton
                onClick={handleRemoveInterface(index)}
                disabled={disabled}
                className={classes.errorButton}
              >
                <DeleteIcon />
              </IconButton>
            }
            nextTitle={renderTypeIcon(connection.type)}
            content={
              <Grid key={index} container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    prop={`connections.${index}.name`}
                    label="Interface Name"
                    placeholder="Interface Name"
                    type="text"
                    required={true}
                    value={connection.name}
                    onInputChange={onInputChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Type"
                    prop={`connections.${index}.type`}
                    value={connection.type}
                    onSelectChange={onInputChange}
                    options={Object.keys(connectionsLabel).map((type) => (
                      <MenuItem
                        dense
                        key={`network_inerface_${index}_${type}`}
                        value={type}
                      >
                        {connectionsLabel[type]}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'none' }}>
                  <Button
                    variant="text"
                    size="small"
                    className={clsx('p-0', classes.button)}
                    onClick={handleAdvancedConfig(index)}
                  >
                    {showAdvancedConfig[index] ? 'Hide' : 'Show'} interface
                    configuration
                  </Button>
                </Grid>
                {showAdvancedConfig[index] && (
                  <Grid item xs={12}>
                    {connection.type === 'cellular' && (
                      <CellularInterface
                        connection={connection}
                        configIndex={index}
                        onInputChange={handleInterfaceConfigChange}
                      />
                    )}
                    {(connection.type === 'ethernet_lan' ||
                      connection.type === 'ethernet_wan' ||
                      connection.type === 'ethernet_wan_lan') && (
                      <EthernetLanWanInterface
                        connection={connection}
                        configIndex={index}
                        onInputChange={handleInterfaceConfigChange}
                      />
                    )}
                    {connection.type === 'wifi' && (
                      <WifiInterface
                        connection={connection}
                        configIndex={index}
                        onInputChange={handleInterfaceConfigChange}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            }
          />
        ))}
      <Grid item xs={12} className="d-flex flex-items-center">
        <Button
          className="w-100"
          variant="outlined"
          size="large"
          onClick={handleClickAddInterface()}
          startIcon={<AddIcon />}
          disabled={disabled}
        >
          Add Interface
        </Button>
      </Grid>
    </>
  );
};

export default NetworkConfigurationInterfaces;
