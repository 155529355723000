import React, { ReactElement, useState } from 'react';
import { Typography, Grid, MenuItem, Button } from '@mui/material';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import {
  activityFilterOptions,
  activityFilterValues,
} from '../../../../app/constants';
import timeHelpers from '../../../../helpers/timeHelpers';
import CommandExecutionList from '../../../commandContent/commandExecutionList';
import RecentSoftwareUpdates from '../../../../containers/RecentSoftwareUpdates';
import GatewayCommandActivities from '../../../../containers/GatewayCommandActivities';
import WorkflowExecutionList from '../../../../containers/WorkflowExecutionList';
import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import DateRangePicker from '../../../../components/DateRangePicker';
import { DateRangePickerValues } from '../../../../components/DateRangePicker/DateRangePicker';
import DeviceReportsActivities from './DeviceReportsActivities';
import DeviceErrorsActivity from './DeviceErrosActivities';
import DeviceEventsList from './DeviceEventsList';
import useStyles from './styles';

interface DeviceActivitiesProps {
  deviceType?: DeviceType | null;
  deviceId?: string;
  deviceUniqueId?: string;
  handleOpenGatewayCommand: () => void;
}

const DeviceActivities: React.FC<DeviceActivitiesProps> = ({
  deviceType,
  deviceId,
  deviceUniqueId,
  handleOpenGatewayCommand,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [reportFilterValue, setReportFilterValue] = useState('reports');
  const [rangeFilter, setRangeFilter] = useState<DateRangePickerValues>({
    endDate: timeHelpers.getLastDaysDate(0),
    label: 'Last 7 Days',
    startDate: timeHelpers.getLastDaysDate(7),
  });

  const handleChangeReportFilter = (_prop: string, value: string): void => {
    setReportFilterValue(value);
  };

  const toggleRangePicker = (): void => {
    setOpen(!open);
  };

  const handleRangeChange = (range: DateRangePickerValues): void => {
    setRangeFilter(range);
    toggleRangePicker();
  };

  return (
    <Grid item className="p-3">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className={clsx('mb-4')} variant="h5">
            Device Activity
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SelectInput
            prop="activity_filter"
            value={reportFilterValue}
            onSelectChange={handleChangeReportFilter}
            classes={clsx('mb-4', classes.filter)}
            options={activityFilterOptions.map(
              (filterOption, index): ReactElement => (
                <MenuItem
                  dense
                  key={index}
                  value={filterOption.value}
                  className="m-4 p-2"
                >
                  {filterOption.label}
                </MenuItem>
              ),
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            prop="date_range"
            placeholder="Date range"
            value={rangeFilter?.label ?? ''}
            handleClick={toggleRangePicker}
          />
          <DateRangePicker
            openDialog={open}
            onDatesChange={handleRangeChange}
            onClose={toggleRangePicker}
          />
        </Grid>
      </Grid>

      {reportFilterValue === activityFilterValues.recent_command && (
        <Grid item xs={12}>
          <Button
            className="mb-4 py-1 px-2"
            variant="contained"
            size="medium"
            onClick={handleOpenGatewayCommand}
          >
            <Typography variant="overline">Issue Gateway Command</Typography>
          </Button>
        </Grid>
      )}

      {reportFilterValue === activityFilterValues.reports && (
        <DeviceReportsActivities range={rangeFilter} />
      )}
      {reportFilterValue === activityFilterValues.heartbeats && (
        <DeviceReportsActivities heartbeat={true} range={rangeFilter} />
      )}
      {reportFilterValue === activityFilterValues.errors && (
        <DeviceErrorsActivity range={rangeFilter} />
      )}
      {reportFilterValue === activityFilterValues.events && deviceUniqueId && (
        <DeviceEventsList
          id={deviceUniqueId}
          range={rangeFilter}
          noDataLabel="events"
        />
      )}
      {reportFilterValue === activityFilterValues.recent_software_updates && (
        <RecentSoftwareUpdates deviceType={deviceType} range={rangeFilter} />
      )}
      {reportFilterValue === activityFilterValues.recent_command && (
        <GatewayCommandActivities deviceType={deviceType} range={rangeFilter} />
      )}
      {reportFilterValue ===
        activityFilterValues.recent_user_defined_command && (
        <CommandExecutionList
          deviceId={deviceId as string}
          range={rangeFilter}
        />
      )}
      {reportFilterValue === activityFilterValues.workflows_executions && (
        <WorkflowExecutionList isWorkflowPage={false} range={rangeFilter} />
      )}
    </Grid>
  );
};

export default DeviceActivities;
