import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  DeviceConfigInput,
  DeviceConfigs,
  DeviceTypes,
  DeviceType,
  CellularAPNConfig,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  cleanNewNetworkConfiguration,
  setNewNetworkConfigurationInput,
} from '../../redux/reducers/networkConfigurations.reducer';
import { GATEWAY_TYPE } from '../../app/constants';
import {
  dispatchError,
  getAttachError,
  getCreatingError,
  getCreatingSuccess,
} from '../../helpers/utils';
import { handleNetworkFormChange } from '../../containers/Forms/NetworkConfigurationForm/helper';
import Publish from '../../containers/Publish';
import Header from '../../containers/HeaderWithActionButton';
import SideTabs from '../../components/SideTabs';
import TextInput from '../../components/TextInput';
import EntitiesSection from '../../containers/EntitiesSection';
import InterfaceDataCollection from '../../containers/Forms/NetworkConfigurationForm/InterfaceDataCollection';
import LatencyTest from '../../containers/Forms/NetworkConfigurationForm/LatencyTest';
import PerformanceTest from '../../containers/Forms/NetworkConfigurationForm/PerformanceTest';
import NetworkConfigurationInterfaces from '../../containers/Forms/NetworkConfigurationForm/NetworkConfigurationInterfaces';
import useStyles from './styles';

const CreateNetworkConfiguration: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errorDispatcher = dispatchError(dispatch);
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const [attachingDeviceTypes, setAttachingDeviceTypes] = useState(false);
  const deviceConfigInput = useAppSelector(
    (state: RootState) => state.configs.newNetworkConfigurationInput,
  );

  useEffect(() => {
    DeviceTypes.list({
      type: {
        operator: 'eq',
        value: GATEWAY_TYPE,
      },
    })
      .then((_result) => {
        // setGatewayDeviceTypes(result.deviceTypes);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  useEffect(() => {
    checkSubmitEnable();
  }, [deviceConfigInput]);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(deviceConfigInput?.name !== '');
  };

  const saveDeviceTypes = (id: string): void => {
    if (selectedDeviceTypes.length !== 0) {
      setAttachingDeviceTypes(true);
      Promise.all(
        selectedDeviceTypes.map(async (deviceType) => {
          await DeviceTypes.update({
            ...deviceType,
            device_network_config_id: id,
          });
        }),
      )
        .then(() => {
          console.info('Device profiles attached');
          dispatch(cleanNewNetworkConfiguration());
          navigate(`/network-configuration/${id}`);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getAttachError('device profiles', 'network configuration'),
          );
        })
        .finally(() => {
          setAttachingDeviceTypes(false);
        });
    }
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    DeviceConfigs.create(deviceConfigInput as DeviceConfigInput)
      .then((deviceConfig) => {
        dispatch(
          setAlert({
            highlight: getCreatingSuccess('Network Configuration'),
            type: 'success',
          }),
        );
        saveDeviceTypes(deviceConfig._id);
      })
      .catch((error) => {
        errorDispatcher(
          error.messages || error.message,
          getCreatingError('network configuration'),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleChange = (
    prop: string,
    value: string | number | string[] | CellularAPNConfig[],
  ): void => {
    if (deviceConfigInput) {
      dispatch(
        setNewNetworkConfigurationInput(
          handleNetworkFormChange(deviceConfigInput, prop, value),
        ),
      );
    }
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleChange('company_id', companyId);
  };

  const handleChangeDeviceTypes = (newDeviceTypes: DeviceType[]): void => {
    setSelectedDeviceTypes(newDeviceTypes);
  };

  const handleAddInterface = (): void => {
    if (deviceConfigInput) {
      dispatch(
        setNewNetworkConfigurationInput({
          ...deviceConfigInput,
          connections: [
            ...(deviceConfigInput.connections ?? []),
            {
              name: '',
              type: 'cellular',
            },
          ],
        }),
      );
    }
  };

  const handleRemoveInterface = (index: number): void => {
    console.info(index);
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="network-configurations"
          goBackLabel="Network Configurations"
          isCreatePage={true}
          model="device_config"
        />
      </Grid>
      <Grid item xs={8}>
        {deviceConfigInput && (
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <Paper className="shadow p-8">
                <div className={clsx('mb-6', classes.titleContainer)}>
                  <Typography
                    data-cy="create-net-config-title"
                    variant="h5"
                    className={classes.title}
                  >
                    Create Network Configuration
                  </Typography>
                </div>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={12}>
                    <TextInput
                      prop="name"
                      label="Name"
                      placeholder="Name"
                      type="text"
                      required={true}
                      value={deviceConfigInput.name}
                      onInputChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className="shadow p-8">
                <SideTabs
                  defaultTab="interfaces"
                  rightPadding="pr-4"
                  leftPadding="pl-4"
                  /* eslint sort-keys: 0 */
                  tabs={{
                    interfaces: (
                      <NetworkConfigurationInterfaces
                        deviceConfig={deviceConfigInput}
                        onInputChange={handleChange}
                        onAddNewInteface={handleAddInterface}
                        onRemoveInteface={handleRemoveInterface}
                      />
                    ),
                    data_collection: (
                      <InterfaceDataCollection
                        deviceConfig={deviceConfigInput}
                        onInputChange={handleChange}
                      />
                    ),
                    latency_test: (
                      <LatencyTest
                        deviceConfig={deviceConfigInput}
                        onInputChange={handleChange}
                      />
                    ),
                    performance_test: (
                      <PerformanceTest
                        deviceConfig={deviceConfigInput}
                        onInputChange={handleChange}
                      />
                    ),
                    relations: (
                      <EntitiesSection
                        title="Select Device Profiles"
                        subTitle="You can choose Device Profiles to attach to the network configuration"
                        newInput={deviceConfigInput}
                        selectedDeviceTypes={selectedDeviceTypes}
                        onChangeDeviceTypes={handleChangeDeviceTypes}
                      />
                    ),
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="deviceConfig"
          submitting={submitting || attachingDeviceTypes}
          companyId={deviceConfigInput?.company_id ?? ''}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateNetworkConfiguration;
