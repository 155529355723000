import React, { ReactElement, useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';

import useStyles from './styles';

interface AccordionProps {
  content: JSX.Element;
  title: string;
  titleVariant?: 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  keyProp?: string;
  openByDefault?: boolean;
  nextTitle?: ReactElement;
  nextIcon?: ReactElement;
  noBottomBorder?: boolean;
  fullBorder?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  content,
  title,
  titleVariant = 'h6',
  className,
  openByDefault = false,
  keyProp,
  nextTitle,
  nextIcon,
  noBottomBorder,
  fullBorder,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(openByDefault);

  const handleChange = (): void => {
    setExpanded(!expanded);
  };

  const renderTitle = (): ReactElement => (
    <Typography
      variant={titleVariant}
      className="mr-3 d-flex flex-items-center"
      component="div"
    >
      {title}
      {nextTitle && nextTitle}
    </Typography>
  );

  return (
    <MuiAccordion
      key={keyProp}
      square={true}
      data-cy={keyProp}
      expanded={expanded}
      onChange={handleChange}
      className={clsx(`${className}`, {
        [classes.fullBorder]: fullBorder,
        ['br-1']: fullBorder,
        [classes.noBorder]: noBottomBorder,
      })}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {nextIcon ? (
          <div className="d-flex w-100 flex-justify-between flex-items-center">
            {renderTitle()}
            {nextIcon}
          </div>
        ) : (
          renderTitle()
        )}
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
