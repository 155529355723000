import { ReactElement } from 'react';
import { ClassNameMap, Grid, Typography } from '@mui/material';
import {
  Memory as MemoryIcon,
  Router as RouterIcon,
  Smartphone as SmartphoneIcon,
} from '@mui/icons-material';
import { NetworkInterfaceInfo } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import timeHelpers from '../../../helpers/timeHelpers';

/**
 * Network Info Report component, any data related to the overall info ofr the network should be added/proccessed here.
 */
const NetworkInfo: React.FC<{
  info: NetworkInterfaceInfo;
  classes: ClassNameMap;
  renderDataPair: (
    label: string,
    data: string,
    icon?: ReactElement,
  ) => ReactElement;
}> = ({ info, classes, renderDataPair }) => {
  let signal;
  if (info.cellular_strength) {
    signal = parseInt(info.cellular_strength);
  }
  return (
    <>
      {info.ipv4_enabled && (
        <>
          <Grid item xs={12} md={6} lg={3}>
            {renderDataPair('IPv4 Address', info.ipv4_address)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {renderDataPair('IPv4 Netmask ', info.ipv4_netmask)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {renderDataPair('IPv4 Gateway', info.ipv4_gateway)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {renderDataPair('IPv4 DNS', info.ipv4_dns)}
          </Grid>
        </>
      )}
      {info.ipv6_enabled && (
        <>
          <Grid item xs={12} md={6} lg={3}>
            {renderDataPair('IPv6 Address', info.ipv6_address)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {renderDataPair('IPv6 Netmask ', info.ipv6_netmask)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {renderDataPair('IPv6 Gateway', info.ipv6_gateway)}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {renderDataPair('IPv6 DNS', info.ipv6_dns)}
          </Grid>
        </>
      )}
      {info.bytes_sent && (
        <Grid item xs={12} md={6} lg={3}>
          {renderDataPair('Bytes sent', String(info.bytes_sent))}
        </Grid>
      )}
      {info.bytes_received && (
        <Grid item xs={12} md={6} lg={3}>
          {renderDataPair('Bytes received', String(info.bytes_received))}
        </Grid>
      )}
      {info.packets_sent && (
        <Grid item xs={12} md={6} lg={3}>
          {renderDataPair('Packets sent', String(info.packets_sent))}
        </Grid>
      )}
      {info.packets_received && (
        <Grid item xs={12} md={6} lg={3}>
          {renderDataPair('Packets received', String(info.packets_received))}
        </Grid>
      )}
      {info.packets_sent_errors && (
        <Grid item xs={12} md={6} lg={3}>
          {renderDataPair(
            'Packets sent errors',
            String(info.packets_sent_errors),
          )}
        </Grid>
      )}
      {info.packets_received_errors && (
        <Grid item xs={12} md={6} lg={3}>
          {renderDataPair(
            'Packets received errors',
            String(info.packets_received_errors),
          )}
        </Grid>
      )}
      {info.created_at && (
        <Grid item xs={12} md={6} lg={3}>
          {renderDataPair(
            'Timestamp of last event',
            timeHelpers.getDate(info.created_at),
          )}
        </Grid>
      )}

      {info.interface_mac_address && (
        <Grid item xs={12} md={6} lg={3}>
          {renderDataPair(
            `MAC Address`,
            info.interface_mac_address,
            <MemoryIcon className={clsx('mr-1', classes.icon)} />,
          )}
        </Grid>
      )}

      {info.interface_type === 'cellular' && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6" className="m-0">
              Cellular Data
            </Typography>
          </Grid>

          {typeof signal !== 'undefined' && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                'Signal strength',
                `${String(signal)}% ${
                  typeof info.cellular_mode !== 'undefined'
                    ? info.cellular_mode.toLocaleUpperCase()
                    : ''
                } ${
                  info.cellular_3gpp_registration_state === 'roaming'
                    ? ' Roaming'
                    : ''
                }`,
                <SmartphoneIcon className={clsx('mr-1', classes.icon)} />,
              )}
            </Grid>
          )}

          {info.cellular_modem_name && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                `Modem Name`,
                info.cellular_modem_name,
                <RouterIcon className={clsx('mr-1', classes.icon)} />,
              )}
            </Grid>
          )}

          {info.cellular_modem_manufacturer && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                `Modem Manufacturer`,
                info.cellular_modem_manufacturer,
              )}
            </Grid>
          )}

          {info.cellular_modem_revision && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(`Modem Revision`, info.cellular_modem_revision)}
            </Grid>
          )}

          {info.cellular_3gpp_operator_name && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                `Operator Name & code`,
                `${info.cellular_3gpp_operator_name} Code: ${info.cellular_3gpp_operator_code}`,
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default NetworkInfo;
