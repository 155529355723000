import React, { Dispatch } from 'react';
import { Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import clsx from 'clsx';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import useStyles from './styles';

interface ContentActivityContainerProps {
  content: JSX.Element;
  sidebar: JSX.Element;
  contentShadow?: boolean;
  sidebarShadow?: boolean;
  sidebarHeight?: string;
  tooltipLabel?: string;
  listExpanded: boolean;
  updateCollapsedState: Dispatch<React.SetStateAction<boolean>>;
}

const ContentActivityContainer: React.FC<ContentActivityContainerProps> = ({
  content,
  sidebar,
  contentShadow,
  sidebarShadow,
  sidebarHeight = 'auto',
  tooltipLabel,
  listExpanded,
  updateCollapsedState,
}) => {
  const stateUser = useAppSelector((state: RootState) => state.user);
  const classes = useStyles({
    branding: stateUser.userCompany?.branding,
  });

  const handleListExpanded = (): void => {
    updateCollapsedState(!listExpanded);
  };

  const renderSidebar = (): JSX.Element => (
    <>
      <div
        data-cy="collapse-right-list-button"
        className={clsx(classes.expandButtonContainer, {
          ['collapsed']: !listExpanded,
        })}
      >
        <Tooltip
          title={listExpanded ? 'Collapse' : `Expand ${tooltipLabel}`}
          placement="left"
        >
          <IconButton
            color="inherit"
            aria-label="expand list"
            edge="start"
            onClick={handleListExpanded}
            className={classes.expandButton}
          >
            {listExpanded ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Tooltip>
      </div>
      <Grid
        container
        className={clsx('scrollbar w-100')}
        style={{
          height: sidebarHeight,
          opacity: !listExpanded ? '0' : '1',
          overflowX: 'hidden',
          overflowY: !listExpanded ? 'hidden' : 'auto',
        }}
      >
        {sidebar}
      </Grid>
    </>
  );

  return (
    <div className="w-100 d-flex flex-row">
      <div
        className={clsx(classes.mainContentContainer, classes.withTransition, {
          ['collapsed']: listExpanded,
          ['expanded']: !listExpanded,
        })}
      >
        {contentShadow ? (
          <Paper className="p-7 shadow">{content}</Paper>
        ) : (
          content
        )}
      </div>
      <div
        className={clsx(classes.listContainer, classes.withTransition, {
          ['collapsed']: !listExpanded,
          ['expanded']: listExpanded,
        })}
      >
        {sidebarShadow ? (
          <Paper className="p-4 shadow h-100">{renderSidebar()}</Paper>
        ) : (
          renderSidebar()
        )}
      </div>
    </div>
  );
};

export default ContentActivityContainer;
