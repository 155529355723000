import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    fullBorder: {
      border: '1px solid #ECF1F7',
      marginBottom: '8px !important',
    },
    noBorder: {
      border: 'none !important',
    },
  }),
);

export default useStyles;
