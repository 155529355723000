import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Box, Paper } from '@mui/material';
import {
  DeviceConfigs,
  DeviceTypes,
  DeviceConfig,
  CellularAPNConfig,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getDevieConfigConnectionSelector,
  setActualNetworkConfiguration,
  setNewNetworkConfiguration,
} from '../../redux/reducers/networkConfigurations.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import {
  dispatchError,
  getAttachError,
  getFetchError,
  getPageHash,
} from '../../helpers/utils';
import {
  DETAILS_DEFAULT_TAB,
  errorHighlight,
  networkConfigTabsLabel,
} from '../../app/constants';
import { LocationState } from '../../models/common';
import {
  handleNetworkFormChange,
  processRemoveIntercae,
} from '../../containers/Forms/NetworkConfigurationForm/helper';
import Header from '../../containers/HeaderWithActionButton';
import NetworkConfigurationInterfaces from '../../containers/Forms/NetworkConfigurationForm/NetworkConfigurationInterfaces';
import EntitiesSection from '../../containers/EntitiesSection';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import FooterBar from '../../components/FooterBar';
import NetworkConfigurationDetails from './networkConfigDetails';
// import useStyles from './styles';

const NetworkConfigurationContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const { id } = useParams<string>();
  const location = useLocation();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;

  const stateNetworkConfiguration = useAppSelector((state: RootState) =>
    getDevieConfigConnectionSelector(state.configs, id),
  );
  const [networkConfigurationData, setNetworkConfigurationData] = useState<
    DeviceConfig | undefined | null
  >(stateNetworkConfiguration);

  const newNetworkConfiguration = useAppSelector(
    (state: RootState) => state.configs.newNetworkConfiguration,
  );

  const [activeTab, setActiveTab] = useState(DETAILS_DEFAULT_TAB);
  const [loading, setLoading] = useState(false);
  const [configurationCompany] = useFetchCompany(
    networkConfigurationData?.company_id,
    errorDispatcher,
  );
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );

  const getDeviceTypes = (): void => {
    if (id) {
      DeviceTypes.list({
        filters: [
          {
            key: 'device_network_config_id',
            operator: 'eq',
            value: id,
          },
        ],
      })
        .then((response) => {
          setSelectedDeviceTypes(response.deviceTypes);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('device profiles.'),
          );
        });
    }
  };

  useEffect(() => {
    setActiveTab(
      getPageHash(location.hash, DETAILS_DEFAULT_TAB, networkConfigTabsLabel),
    );
  }, [location.hash]);

  useEffect(() => {
    if (networkConfigurationData) {
      dispatch(setActualNetworkConfiguration(networkConfigurationData));
      getDeviceTypes();
    } else if (id) {
      DeviceConfigs.getOneById(id)
        .then((response) => {
          dispatch(setActualNetworkConfiguration(response));
          setNetworkConfigurationData(response);
          getDeviceTypes();
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, []);

  const handleDelete = (): void => {
    if (!networkConfigurationData) {
      return;
    }
    setLoading(true);

    DeviceConfigs.delete(networkConfigurationData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Config Deleted',
            message: 'Device config successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/network-configurations');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    setLoading(true);
    DeviceConfigs.update(newNetworkConfiguration as DeviceConfig)
      .then((response) => {
        dispatch(setActualNetworkConfiguration(response));
        dispatch(
          setAlert({
            highlight: 'Config Updated',
            message: 'Device Config successfully updated',
            type: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (
    prop: string,
    value: string | number | string[] | CellularAPNConfig[],
  ): void => {
    if (newNetworkConfiguration) {
      dispatch(
        setNewNetworkConfiguration(
          handleNetworkFormChange(
            newNetworkConfiguration,
            prop,
            value,
          ) as DeviceConfig,
        ),
      );
    }
  };

  const handleChangeDeviceTypes = (deviceTypes: DeviceType[]): void => {
    const deviceTypesToDetach = selectedDeviceTypes.filter(
      (dt) => !deviceTypes.find((d) => d._id === dt._id),
    );
    const deviceTypesToAttach = deviceTypes.filter(
      (dt) => !selectedDeviceTypes.find((d) => d._id === dt._id),
    );
    if (id) {
      Promise.all([
        Promise.all(
          deviceTypesToAttach.map(async (deviceType) => {
            await DeviceTypes.update({
              ...deviceType,
              device_network_config_id: id,
            });
          }),
        ),
        Promise.all(
          deviceTypesToDetach.map(async (deviceType) => {
            await DeviceTypes.update({
              ...deviceType,
              device_network_config_id: '',
            });
          }),
        ),
      ])
        .then(() => {
          dispatch(
            setAlert({
              highlight: 'Updated Attached Device Profiles',
              type: 'success',
            }),
          );
          setSelectedDeviceTypes(deviceTypes);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getAttachError('device profiles', 'network configuration'),
          );
        });
    }
  };

  const handleAddInterface = (): void => {
    if (newNetworkConfiguration) {
      dispatch(
        setNewNetworkConfiguration({
          ...newNetworkConfiguration,
          connections: [
            ...(newNetworkConfiguration.connections ?? []),
            {
              name: '',
              type: 'cellular',
            },
          ],
        }),
      );
    }
  };

  const handleRemoveInterface = (index: number): void => {
    if (newNetworkConfiguration) {
      dispatch(
        setNewNetworkConfiguration(
          processRemoveIntercae(newNetworkConfiguration, index) as DeviceConfig,
        ),
      );
    }
  };

  /**
   * PLEASE KEEP THE ORDER THE WAY IT IS AND IGNORE THE LINTING ORDER
   */
  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    /* eslint sort-keys: 0 */
    const tabs: {
      [key: string]: JSX.Element;
    } = {
      details: newNetworkConfiguration ? (
        <NetworkConfigurationDetails
          onInputChange={handleChange}
          newNetworkConfiguration={newNetworkConfiguration}
        />
      ) : (
        <></>
      ),
      interfaces: newNetworkConfiguration ? (
        <Paper className="shadow p-8">
          <NetworkConfigurationInterfaces
            deviceConfig={newNetworkConfiguration}
            onInputChange={handleChange}
            onAddNewInteface={handleAddInterface}
            onRemoveInteface={handleRemoveInterface}
          />
        </Paper>
      ) : (
        <></>
      ),
      device_profiles: newNetworkConfiguration ? (
        <Paper className="shadow p-8">
          <EntitiesSection
            title="Select Device Profiles"
            subTitle="You can choose Device Profiles to attach to the configuration"
            newInput={newNetworkConfiguration}
            selectedDeviceTypes={selectedDeviceTypes}
            onChangeDeviceTypes={handleChangeDeviceTypes}
          />
        </Paper>
      ) : (
        <></>
      ),
    };
    return tabs;
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newNetworkConfiguration, networkConfigurationData);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item xs={12}>
        <Header
          title="Network Configuration Content"
          model="device_config"
          goBack={goBackUrl ? goBackUrl : 'network-configurations'}
          goBackLabel={goBackLabel || `Network Configurations`}
        />
      </Grid>
      <Grid item xs={12}>
        {newNetworkConfiguration && (
          <Box className="content-page-container">
            <ContentHeader
              contentType="config-connection"
              title={newNetworkConfiguration.name}
              subtitle={newNetworkConfiguration._id}
              copySubtitleToClipboard
              extraImage={configurationCompany?.branding?.logo_url}
              extraTitle={configurationCompany?.name}
              extraSubtitle={configurationCompany?._id}
            />
            <VerticalTabs
              changeUrl={true}
              defaultTab={activeTab}
              tabsLabel={networkConfigTabsLabel}
              tabs={getTabs()}
            />
          </Box>
        )}
      </Grid>
      <FooterBar
        deleteModalContent="You are about to delete the configuration"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDelete}
      />
    </Grid>
  );
};

export default NetworkConfigurationContent;
