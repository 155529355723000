import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { DiscoveredDevice, DiscoveredDevices } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';
import clsx from 'clsx';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getDiscoveredDeviceSelector,
  setActualDiscoveredDevice,
} from '../../redux/reducers/devices.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  heartbeatColorThemeMap,
  deviceDetailsTabsLabel,
  errorHighlight,
  DETAILS_DEFAULT_TAB,
} from '../../app/constants';
import { getPageHash } from '../../helpers/utils';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import FooterBar from '../../components/FooterBar';
import ColoredBox from '../../components/ColoredBox';
import DeviceDetails from './DiscoveredDeviceDetails';
import DeviceMetadata from './DiscoveredDeviceMetadata';
import useStyles from './styles';

const DiscoveredDeviceContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<string>();
  const location = useLocation();
  const classes = useStyles();
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const [discoveredDeviceData, setDeviceData] = useState<
    DiscoveredDevice | null | undefined
  >(getDiscoveredDeviceSelector(devicesState, id));
  const newDiscoveredDevice = useAppSelector(
    (state: RootState) => state.devices.newDiscoveredDevice,
  );
  const deviceCompany = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(DETAILS_DEFAULT_TAB);

  useEffect(() => {
    if (discoveredDeviceData) {
      dispatch(setActualDiscoveredDevice(discoveredDeviceData));
    } else if (id) {
      DiscoveredDevices.getOneById(id)
        .then((response) => {
          dispatch(setActualDiscoveredDevice(response));
          setDeviceData(response);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [discoveredDeviceData, id]);

  useEffect(() => {
    setActiveTab(
      getPageHash(location.hash, DETAILS_DEFAULT_TAB, deviceDetailsTabsLabel),
    );
  }, [location.hash]);

  const handleDeleteDevice = (): void => {
    if (!discoveredDeviceData) {
      return;
    }
    setLoading(true);
    DiscoveredDevices.delete(discoveredDeviceData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Discovered device deleted',
            message: 'Discovered device successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/devices');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    if (newDiscoveredDevice !== discoveredDeviceData) {
      setLoading(true);
      DiscoveredDevices.update(newDiscoveredDevice as DiscoveredDevice)
        .then((response) => {
          dispatch(setActualDiscoveredDevice(response));
          dispatch(
            setAlert({
              highlight: 'Discovered device updated',
              message: 'Discovered device successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const renderTagSection = (): ReactElement => (
    <>
      {discoveredDeviceData?.heartbeat_status && (
        <ColoredBox
          className={clsx('ml-4', classes.tag)}
          type="heartbeat_status"
          value={discoveredDeviceData.heartbeat_status}
          colorTheme={
            heartbeatColorThemeMap[discoveredDeviceData.heartbeat_status]
          }
        />
      )}
    </>
  );

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newDiscoveredDevice, discoveredDeviceData);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header title="Devices content" goBack="devices" model="device" />
      {discoveredDeviceData && (
        <Box>
          <ContentHeader
            title={discoveredDeviceData.name}
            contentType="discovered-device"
            tagSection={renderTagSection()}
            subtitle={discoveredDeviceData.unique_id}
            extraImage={deviceCompany?.branding?.logo_url}
            extraTitle={deviceCompany?.name}
            extraSubtitle={deviceCompany?._id}
            copySubtitleToClipboard={true}
          />
          <VerticalTabs
            tabs={{
              details: <DeviceDetails />,
              metadata: <DeviceMetadata />,
            }}
            defaultTab={activeTab}
            tabsLabel={deviceDetailsTabsLabel}
            changeUrl={true}
          />
        </Box>
      )}
      <FooterBar
        deleteModalContent="You are about to delete this device"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteDevice}
      />
    </Grid>
  );
};

export default DiscoveredDeviceContent;
