import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button, CircularProgress } from '@mui/material';
import {
  DeviceEvent,
  DeviceEvents,
  DeviceEventsFilters,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../../../redux/hooks';
import { setAlert } from '../../../../redux/reducers/alert.reducer';
import {
  deviceEventsColorThemeMap,
  errorHighlight,
  defaultItemsPerPage,
} from '../../../../app/constants';
import timeHelpers from '../../../../helpers/timeHelpers';
import {
  getNoItemsInRange,
  formatReadableActionName,
} from '../../../../helpers/utils';
import { DateRangePickerValues } from '../../../../components/DateRangePicker/DateRangePicker';
import TimelineCard from '../../../../components/TimelineCard';

const deviceEventsLabels: {
  [key: string]: string;
} = {
  connect: 'MQTT Connect',
  create: 'Create',
  delete: 'Delete',
  disconnect: 'MQTT Disconnect',
  heartbeat_status_change: 'Heartbeat Status Change',
  password_change_successful: 'Password Change successful',
  register: 'Register',
  touch: 'Touch',
  update_edge_version: 'Edge Version Update',
};

// const _disconnectReasons: {
//   [key: string]: string;
// } = {
//   MQTT_KEEP_ALIVE_TIMEOUT: 'MQTT Keep alive timeout',
//   CLIENT_INITIATED_DISCONNECT: 'Cl',
//   delete: 'Delete',
//   disconnect: 'MQTT Disconnect',
//   heartbeat_status_change: 'Heartbeat Status Change',
//   password_change_successful: 'Password Change successful',
//   register: 'Register',
//   touch: 'Touch',
//   update_edge_version: 'Edge Version Update',
// };

const DeviceEventsList: React.FC<{
  id: string;
  range: DateRangePickerValues;
  noDataLabel: string;
  onlyConnection?: boolean;
}> = ({ id, range, noDataLabel, onlyConnection = false }) => {
  const dispatch = useAppDispatch();

  const [events, setEvents] = useState<DeviceEvent[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const getEvents = (pageNumber: number, addPage: boolean): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      page: pageNumber,
    };
    const filters: DeviceEventsFilters = {
      filters: [
        {
          key: 'created_at',
          operator: 'gte',
          value: range.startDate,
        },
        {
          key: 'created_at',
          operator: 'lte',
          value: range.endDate,
        },
      ],
      unique_id: {
        operator: 'eq',
        value: id as string,
      },
    };
    if (onlyConnection) {
      filters.device_event_type = {
        operator: 'in',
        value: ['connect', 'disconnect'],
      };
    }
    DeviceEvents.list(filters, pagination)
      .then((res) => {
        const newEvents = addPage
          ? [...events, ...res.deviceEvents]
          : res.deviceEvents;
        setEvents(newEvents);
        setTotalAndPage(res.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  useEffect(() => {
    setLoading(true);
    getEvents(1, false);
  }, [range]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getEvents(page + 1, true);
  };

  const renderTitle = (event: DeviceEvent): string => {
    let result = '';
    switch (event.device_event_type) {
      case 'connect':
        if (event.metadata?.ipAddress) {
          result = `IP Address: ${event.metadata.ipAddress}`;
        }
        break;
      case 'disconnect':
        if (
          event.metadata?.disconnectReason &&
          typeof event.metadata?.disconnectReason === 'string'
        ) {
          result = `Disconnect reason: ${formatReadableActionName(
            event.metadata.disconnectReason,
          )}`;
        }
        break;
      default:
        break;
    }
    return result;
  };

  return (
    <>
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={50} thickness={5} />
        </Grid>
      ) : (
        <>
          {events.length === 0 && (
            <Typography variant="subtitle1" className="mt-4">
              {getNoItemsInRange(range, noDataLabel)}
            </Typography>
          )}
          {events.map((event, i) => (
            <TimelineCard
              key={i}
              type="device_event"
              title={renderTitle(event)}
              tag={deviceEventsLabels[event.device_event_type]}
              tagTheme={deviceEventsColorThemeMap}
              tagThemeKey={event.device_event_type}
              isLast={i === events.length - 1}
              caption={timeHelpers.getDate(event.created_at, false, true)}
            />
          ))}
          {events.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default DeviceEventsList;
