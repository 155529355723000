import React, { Dispatch, useMemo } from 'react';
import { DeviceType, FilterOperatorType } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setMoreFiltersView } from '../../../redux/reducers/filters.reducer';
import { MetadataFilter, SortingOption } from '../../../models/common';
import {
  activationStatusLabel,
  ACTIVATION_STATUS_KEY,
  devicesFiltersTabsLabels,
  healthStatusLabel,
  heartbeatStatus,
  heartBeatStatusLabel,
  METADATA_ARRAY_OPERATORS,
  METADATA_KEY,
  SOFTWARE_VERSION_KEY,
} from '../../../app/constants';
import {
  getMetadataFilterOperator,
  getSoftwareVersionObject,
} from '../../../helpers/parseFilters';
import AdvancedSelect from '../../../components/AdvancedSelect';
import VerticalTabs from '../../../components/VerticalTabs';
import AccountsExplorer from '../../../containers/AccountsExplorer';
import DeviceMetadataFilter from './ActiveDeviceMetadataFilter';
import DeviceSoftwareVersionFilter from './ActiveDeviceSoftwareVersionFilter';

interface ActiveDevicesMoreFiltersProps {
  deviceTypes: DeviceType[];
  filters: {
    [key: string]: string;
  };
  defaultTab: string;
  onFiltersChange: (
    prop: string,
    value: string | { [key: string]: string },
  ) => void;
  updateLoading: Dispatch<React.SetStateAction<boolean>>;
}

const ActiveDevicesMoreFilters: React.FC<ActiveDevicesMoreFiltersProps> = ({
  deviceTypes,
  filters,
  defaultTab,
  onFiltersChange,
  updateLoading,
}) => {
  const dispatch = useAppDispatch();
  const { userCompanies } = useAppSelector((state: RootState) => state.user);

  const accountsSelected: string[] = filters.company_id
    ? filters.company_id.split('|')
    : [];

  const ancestorSelected = filters.ancestor_company_id
    ? filters.ancestor_company_id
    : '';

  const deviceTypesSelected: string[] = filters.device_type_id
    ? filters.device_type_id.split('|')
    : [];
  const deviceTypesOptions: SortingOption[] = deviceTypes.map((deviceType) => {
    return {
      label: deviceType.name,
      value: deviceType._id,
    };
  });

  const heartbeatSelected: string[] = filters.heartbeat_status
    ? filters.heartbeat_status.split('|')
    : [];
  const heartbeatStatusOptions: SortingOption[] = heartbeatStatus.map(
    (item) => {
      return {
        label: heartBeatStatusLabel[item],
        value: item,
      };
    },
  );

  const allTags: string[] = useMemo(() => {
    const setOfTags = new Set<string>();

    userCompanies.forEach((company) => {
      if (company.device_tags?.length) {
        for (const tag of company.device_tags) {
          setOfTags.add(tag);
        }
      }
    });

    return Array.from(setOfTags);
  }, [userCompanies]);

  const tagsSelected: string[] = filters.tags ? filters.tags.split('|') : [];
  const tagsOptions: SortingOption[] = allTags.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const healthStatusSelected: string[] = filters.health_status
    ? filters.health_status.split('|')
    : [];
  const healthStatusOptions: SortingOption[] = Object.keys(
    healthStatusLabel,
  ).map((item) => {
    return {
      label: healthStatusLabel[item],
      value: item,
    };
  });

  const activationStatusSelected: string[] = filters[ACTIVATION_STATUS_KEY]
    ? filters[ACTIVATION_STATUS_KEY].split('|')
    : [];
  const activationStatusOptions: SortingOption[] = Object.keys(
    activationStatusLabel,
  ).map((item) => {
    return {
      label: activationStatusLabel[item],
      value: item,
    };
  });

  const metadataFilters: MetadataFilter[] = [];
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      if (key.indexOf(METADATA_KEY) !== -1) {
        const operator = getMetadataFilterOperator(key) as FilterOperatorType;
        const isArray = METADATA_ARRAY_OPERATORS.includes(operator);
        metadataFilters.push({
          key: key.slice(METADATA_KEY.length, key.length),
          operator,
          value: isArray ? filters[key].split(',') : filters[key],
        });
      }
    }
  }

  let softwareVersionFilters: MetadataFilter[] = [];
  if (filters[SOFTWARE_VERSION_KEY]) {
    softwareVersionFilters = getSoftwareVersionObject(
      filters[SOFTWARE_VERSION_KEY],
    );
  }

  const handleChooseAccount = (id: string): void => {
    const newValue = accountsSelected.includes(id)
      ? accountsSelected.filter((account) => account !== id)
      : [...accountsSelected, id];
    onFiltersChange('company_id', newValue.join('|'));
  };

  const handleChooseAncestor = (id: string): void => {
    onFiltersChange('ancestor_company_id', id);
  };

  const handleMoreFiltersTabChange = (tab: string): void => {
    dispatch(setMoreFiltersView(tab, 'devices'));
  };

  return (
    <VerticalTabs
      defaultTab={defaultTab}
      tabsLabel={devicesFiltersTabsLabels}
      contentFullHeight={true}
      handleTabChange={handleMoreFiltersTabChange}
      /* eslint sort-keys: 0 */
      tabs={{
        accounts: (
          <AccountsExplorer
            selectedAccountsIds={accountsSelected}
            ancestorId={ancestorSelected}
            onChoosingAccount={handleChooseAccount}
            onChoosingAncestor={handleChooseAncestor}
            updateLoading={updateLoading}
          />
        ),
        deviceProfiles: (
          <AdvancedSelect
            searchPlaceholder="Search Device Profile"
            prop="device_type_id"
            options={deviceTypesOptions}
            value={deviceTypesSelected}
            onOptionsChange={onFiltersChange}
          />
        ),
        heartbeat: (
          <AdvancedSelect
            prop="heartbeat_status"
            options={heartbeatStatusOptions}
            value={heartbeatSelected}
            onOptionsChange={onFiltersChange}
          />
        ),
        tags: (
          <AdvancedSelect
            prop="tags"
            options={tagsOptions}
            value={tagsSelected}
            onOptionsChange={onFiltersChange}
          />
        ),
        healthStatus: (
          <AdvancedSelect
            prop="health_status"
            options={healthStatusOptions}
            value={healthStatusSelected}
            onOptionsChange={onFiltersChange}
          />
        ),
        activationStatus: (
          <AdvancedSelect
            prop={ACTIVATION_STATUS_KEY}
            options={activationStatusOptions}
            value={activationStatusSelected}
            onOptionsChange={onFiltersChange}
          />
        ),
        metadata: (
          <DeviceMetadataFilter
            metadataFilters={metadataFilters}
            onFiltersChange={onFiltersChange}
          />
        ),
        softwareVersion: (
          <DeviceSoftwareVersionFilter
            softwareVersionFilters={softwareVersionFilters}
            onFiltersChange={onFiltersChange}
          />
        ),
      }}
      padding={false}
    />
  );
};

export default ActiveDevicesMoreFilters;
