import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activityContainer: {
      backgroundColor: '#fff',
      marginLeft: '2%',
      maxWidth: 'none',
      width: '28%',
    },
    container: {
      backgroundColor: '#fff',
      maxWidth: 'none',
      width: '70%',
    },
    containerNoBg: {
      backgroundColor: 'inherit !important',
    },
    errorButton: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
    },
    filter: {
      fontSize: '14px',
    },
    linkButton: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      margin: '0',
      textDecoration: 'none',
    },
    linkContainer: {
      alignItems: 'center',
      border: '1px solid rgba(0, 0, 0, 0.26)',
      borderRadius: '4px',
      display: 'flex',
      height: '44px',
      padding: '12px',
    },
    tag: {
      height: '18px',
    },
    terminalButton: {
      width: '230px',
    },
    version: {
      color: theme.palette.grey[200],
    },
  }),
);

export default useStyles;
