import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Paper } from '@mui/material';
import {
  PollableAttribute,
  PollableAttributes,
  PollableAttributeDataType,
  Attribute,
} from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  getPollableSelector,
  setActualPollableAttribute,
  setNewPollableAttribute,
} from '../../../redux/reducers/pollableAttributes.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import DynamicRows from '../../../components/DynamicRows';
import PollableForm from '../../../containers/Forms/PollableForm';
import FooterBar from '../../../components/FooterBar';
import Header from '../../../containers/HeaderWithActionButton';
import ContentHeader from '../../../components/ContentHeader';
import PollableAttributeMibForm from '../../../containers/Forms/PollableAttributeMibForm';
import PollableAttributeNotificationsForm from '../../../containers/Forms/PollableAttributeNotificationsForm';
import { ItemList } from '../../../components/DynamicRows/constants';
import { POLLABLE_DATA_TYPES } from '../../../constants/pollableAttributes';
import { dispatchError } from '../../../helpers/utils';
import { useFetchCompany } from '../../../hooks/useFetchCompany';

const PollableContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const pollableState = useAppSelector(
    (state: RootState) => state.pollableAttributes,
  );
  const [loading, setLoading] = useState(false);
  const { newPollableAttribute, pollableAttribute } = pollableState;
  const pollableData = useAppSelector((state: RootState) =>
    getPollableSelector(state.pollableAttributes, id),
  );
  const errorDispatcher = dispatchError(dispatch);
  const [pollableCompany] = useFetchCompany(
    pollableData?.company_id,
    errorDispatcher,
  );

  useEffect(() => {
    if (pollableData) {
      dispatch(setActualPollableAttribute(pollableData));
    } else if (id) {
      PollableAttributes.getOneById(id)
        .then((response) => {
          dispatch(setActualPollableAttribute(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [pollableData]);

  const handleChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    switch (prop) {
      case 'mib.identity':
        dispatch(
          setNewPollableAttribute({
            ...newPollableAttribute,
            mib: {
              ...newPollableAttribute?.mib,
              identity: value,
            },
          } as PollableAttribute),
        );
        break;
      default:
        dispatch(
          setNewPollableAttribute({
            ...newPollableAttribute,
            [prop]: value,
          } as PollableAttribute),
        );
    }
  };

  const handleSaveChanges = (): void => {
    if (!isAbleToBeSaved()) {
      setLoading(true);
      PollableAttributes.update(newPollableAttribute as PollableAttribute)
        .then((response) => {
          dispatch(setActualPollableAttribute(response));
          dispatch(
            setAlert({
              highlight: 'Pollable Attribute Updated',
              message: 'Pollable Attribute successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.messages || error.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDelete = (): void => {
    if (!pollableAttribute) {
      return;
    }
    setLoading(true);
    PollableAttributes.delete(pollableAttribute._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Pollable Attribute Deleted',
            message: 'Pollable Attribute successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/data-management');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages || error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ): void => {
    switch (prop) {
      case 'defaults':
        const defaultsObj = { ...newPollableAttribute?.defaults };
        const defaultsValue = newPollableAttribute?.defaults
          ? newPollableAttribute?.defaults[index]
          : '';
        if (field === 'name') {
          defaultsObj[index] = defaultsObj[value];
          defaultsObj[value] = defaultsValue;
          delete defaultsObj[index];
        } else {
          defaultsObj[index] = value;
        }
        dispatch(
          setNewPollableAttribute({
            ...newPollableAttribute,
            defaults: defaultsObj,
          } as PollableAttribute),
        );
        break;
      case 'attributes':
        const attbArray = newPollableAttribute?.attributes || [];
        if (field === 'name') {
          attbArray[Number(index)].name = String(value);
        } else if (field === 'value') {
          attbArray[Number(index)].value = String(value);
        } else {
          attbArray[Number(index)].data_type = String(
            value,
          ) as PollableAttributeDataType;
        }
        dispatch(
          setNewPollableAttribute({
            ...newPollableAttribute,
            attributes: attbArray,
          } as PollableAttribute),
        );
        break;
    }
  };

  const handleAddRow = (prop: string): void => {
    switch (prop) {
      case 'defaults':
        dispatch(
          setNewPollableAttribute({
            ...newPollableAttribute,
            defaults: {
              ...newPollableAttribute?.defaults,
              '': '',
            } as unknown,
          } as PollableAttribute),
        );
        break;
      case 'attributes':
        const attbArray = newPollableAttribute?.attributes || [];
        dispatch(
          setNewPollableAttribute({
            ...newPollableAttribute,
            attributes: [
              ...(attbArray as Attribute[]),
              {
                data_type: 'snmp_mib_integer' as PollableAttributeDataType,
                name: '',
                value: '',
              } as Attribute,
            ] as Attribute[],
          } as PollableAttribute),
        );
        break;
    }
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newPollableAttribute, pollableData);
  };

  const handleRemoveArrayRow = (prop: string, index: number | string): void => {
    switch (prop) {
      case 'attributes':
        const defaultsArray = newPollableAttribute?.attributes;

        defaultsArray?.splice(index as number, 1);
        dispatch(
          setNewPollableAttribute({
            ...newPollableAttribute,
            attributes: defaultsArray,
          } as PollableAttribute),
        );
        break;
    }
  };

  const handleRemoveRow = (prop: string, item: string): void => {
    switch (prop) {
      case 'defaults':
        const defaultsObj = { ...newPollableAttribute?.defaults };
        delete defaultsObj[item];
        dispatch(
          setNewPollableAttribute({
            ...newPollableAttribute,
            defaults: defaultsObj,
          } as PollableAttribute),
        );
        break;
    }
  };

  const formatDefaultToArray = (itemList: Attribute[]): ItemList[] => {
    return itemList.map((item) => {
      return {
        key: item.name,
        label: item.value,
        type: item.data_type,
      } as ItemList;
    });
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        goBack="data-management#pollable-attributes"
        goBackLabel="Pollable attributes"
        model="pollableAttribute"
      />
      {pollableAttribute && (
        <Box className="content-page-container">
          <ContentHeader
            contentType="pollable-attribute"
            title={pollableAttribute.name}
            subtitle={pollableAttribute._id}
            origin={pollableAttribute.origin}
            extraImage={pollableCompany?.branding?.logo_url}
            extraTitle={pollableCompany?.name}
            extraSubtitle={pollableCompany?._id}
            hideTitleImage={true}
            copySubtitleToClipboard
          />
          <Grid
            container
            columnSpacing={3}
            direction="row"
            className="py-6 px-8"
          >
            <Grid item xs={12}>
              <Paper className="p-7 shadow">
                <PollableForm
                  onInputChange={handleChange}
                  labelRequiredField={true}
                  pollableAttribute={newPollableAttribute || undefined}
                />

                <DynamicRows
                  prop="defaults"
                  objectRowsTypes={newPollableAttribute?.defaults}
                  propLabel="Defaults"
                  itemKey="name"
                  itemValue="value"
                  buttonLabel="Defatuls"
                  onInputChange={handleDynamicChange}
                  onAddRow={handleAddRow}
                  onRemoveRow={handleRemoveRow}
                />

                <DynamicRows
                  prop="attributes"
                  arrayRowsTypes={formatDefaultToArray(
                    (newPollableAttribute?.attributes as Attribute[]) || [],
                  )}
                  propLabel="Attributes"
                  itemKey="name"
                  itemValue="value"
                  buttonLabel="Attributes"
                  dropDownDefaultValue="choose_type"
                  dropDownData={POLLABLE_DATA_TYPES}
                  onInputChange={handleDynamicChange}
                  onAddRow={handleAddRow}
                  onRemoveArrayRow={handleRemoveArrayRow}
                  dropdownKey={'data_type'}
                />
                <div className="mt-6">
                  <PollableAttributeMibForm onInputChange={handleChange} />
                </div>
                <div className="mt-6">
                  <PollableAttributeNotificationsForm />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
      <FooterBar
        deleteModalContent="You are about to delete this pollable attribute"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDelete}
      />
    </Grid>
  );
};

export default PollableContent;
