import { Company, User, DeviceConfig } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../components/SharedTable';

export const ConfigsColumns = (userCompanies?: Company[]): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const config = item as DeviceConfig;
        return config.name;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      link: (item: TableItemType): string => {
        const config = item;
        return `/network-configuration/${config._id}`;
      },
      type: 'link',
    },
    {
      cellValue: (item: TableItemType): string => {
        const user = item as User;
        const company = userCompanies?.find(
          (userCompany) => userCompany._id === user.company_id,
        );
        return company?.name || '';
      },
      id: 'company',
      label: 'Company',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const config = item as DeviceConfig;
        return config.interface_data_collection?.enabled
          ? 'Enabled'
          : 'Not enabled';
      },
      id: 'interface_data_collection',
      label: 'Interface Data Collection',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const config = item as DeviceConfig;
        return config.latency_test?.enabled ? 'Enabled' : 'Not enabled';
      },
      id: 'latency_test',
      label: 'Latency Test',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const config = item as DeviceConfig;
        return config.performance_test?.enabled ? 'Enabled' : 'Not enabled';
      },
      id: 'performance_test',
      label: 'Performance Test',
      type: 'text',
    },
  ];
};
