import React, { useState, useEffect } from 'react';
import { Grid, MenuItem } from '@mui/material';
import {
  CellularAPNConfig,
  Connection,
  ConnectionConfig,
} from '@edgeiq/edgeiq-api-js';

import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import SwitchButton from '../../../../components/SwitchButton';
import {
  CALL_APN_BEARER,
  CALL_APN_PROTOCOLES,
  CALL_APN_TYPES,
  CEL_AUTH_TYPES,
  DEFAULT_APN_CONFIG,
  EMPTY_CONFIG_CONNECTION_CELULAR,
  PREFERRED_NETWORK_TYPES,
} from '../../../../constants/configConnections';

export interface DeviceConfigConnectionFormProps {
  configIndex: number;
  connection: Connection;
  onInputChange: (
    prop: string,
    value: string | number | CellularAPNConfig[],
    field: string,
    index: number,
  ) => void;
}

const CellularInterface: React.FC<DeviceConfigConnectionFormProps> = ({
  configIndex,
  connection,
  onInputChange,
}) => {
  const [configConnection, setConfigConnection] = useState<ConnectionConfig>(
    connection.config || EMPTY_CONFIG_CONNECTION_CELULAR,
  );
  const [cellAPNconfig, setCellAPNConfig] =
    useState<CellularAPNConfig>(DEFAULT_APN_CONFIG);

  const handleLocalConfigConnection = (
    prop: string,
    value: string | number | boolean,
  ): void => {
    setConfigConnection((currentConfig) => {
      return (currentConfig = {
        ...currentConfig,
        [prop]: value,
      });
    });
  };

  useEffect(() => {
    if (configConnection?.cell_auto_apn) {
      onInputChange(
        'cell_apns',
        [cellAPNconfig] as CellularAPNConfig[],
        '',
        configIndex,
      );
    } else {
      onInputChange('cell_apns', [], '', configIndex);
    }
  }, [configConnection?.cell_auto_apn]);

  const handleDynamicChange =
    (prop: string, index: number) =>
    (field: string, value: string | number): void => {
      handleLocalConfigConnection(prop, value as string);
      onInputChange(prop, value, field, index);
    };

  const handleChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    let realValue = value;
    if (typeof value === 'boolean') {
      realValue = value ? 1 : 0;
    }
    handleLocalConfigConnection(prop, realValue as boolean);
    onInputChange(prop, realValue as number, '', configIndex);
  };

  const handleAPNChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    const newConfig = {
      ...cellAPNconfig,
      [prop]: value,
    };
    setCellAPNConfig(newConfig);
    onInputChange(
      'cell_apns',
      [newConfig] as CellularAPNConfig[],
      '',
      configIndex,
    );
  };

  return (
    <>
      <Grid container direction="row" className="mt-2" spacing={3}>
        <Grid item xs={12}>
          <SwitchButton
            label="Enabled"
            value={configConnection?.net_enable === 1}
            prop="net_enable"
            onSwitchChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Modem Preferred Network Type"
            prop="cell_modem_prefered_network_type"
            value={configConnection?.cell_modem_preferred_network_type ?? ''}
            onSelectChange={handleDynamicChange(
              'cell_modem_preferred_network_type',
              configIndex,
            )}
            options={Object.keys(PREFERRED_NETWORK_TYPES).map((key, index) => (
              <MenuItem className="m-4 p-2" key={index} dense value={key}>
                {PREFERRED_NETWORK_TYPES[key]}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            prop="cell_modem_serial_dev"
            label="Modem Serial Dev"
            type="text"
            value={configConnection?.cell_modem_serial_dev}
            onInputChange={handleDynamicChange(
              'cell_modem_serial_dev',
              configIndex,
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            prop="cell_modem_serial_baudrate"
            label="Modem Serial Dev"
            type="number"
            value={configConnection?.cell_modem_serial_baudrate}
            onInputChange={handleDynamicChange(
              'cell_modem_serial_baudrate',
              configIndex,
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SwitchButton
            label="Automatic APN"
            value={configConnection?.cell_auto_apn === 1}
            prop="cell_auto_apn"
            onSwitchChange={handleChange}
          />
        </Grid>
        {configConnection.cell_auto_apn === 0 && (
          <>
            <Grid item xs={6}>
              <TextInput
                prop="cell_user"
                label="User Name"
                type="text"
                value={configConnection?.cell_user}
                onInputChange={handleDynamicChange('cell_user', configIndex)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="cell_pass"
                label="Password"
                type="text"
                value={configConnection?.cell_pass}
                onInputChange={handleDynamicChange('cell_pass', configIndex)}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Authentication Type"
                prop="cell_apn_auth"
                value={cellAPNconfig.cell_apn_auth}
                onSelectChange={handleAPNChange}
                options={Object.keys(CEL_AUTH_TYPES).map((key, index) => (
                  <MenuItem className="m-4 p-2" key={index} dense value={key}>
                    {CEL_AUTH_TYPES[key]}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="cell_apn_name"
                label="APN Name"
                type="text"
                value={cellAPNconfig.cell_apn_name}
                onInputChange={handleAPNChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="APN Type"
                prop="cell_apn_type"
                value={cellAPNconfig.cell_apn_type}
                onSelectChange={handleAPNChange}
                options={Object.keys(CALL_APN_TYPES).map((key, index) => (
                  <MenuItem className="m-4 p-2" key={index} dense value={key}>
                    {CALL_APN_TYPES[key]}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="APN Protocol"
                prop="cell_apn_protocol"
                value={cellAPNconfig.cell_apn_protocol}
                onSelectChange={handleAPNChange}
                options={Object.keys(CALL_APN_PROTOCOLES).map((key, index) => (
                  <MenuItem className="m-4 p-2" key={index} dense value={key}>
                    {CALL_APN_PROTOCOLES[key]}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={6} className="d-flex align-items-center">
              <SwitchButton
                label="APN Roaming"
                value={cellAPNconfig.cell_apn_roaming}
                prop="cell_apn_roaming"
                onSwitchChange={handleAPNChange}
              />
            </Grid>
            <Grid item xs={6}>
              {cellAPNconfig.cell_apn_roaming && (
                <SelectInput
                  label="APN Roaming Protocol"
                  prop="cell_apn_roaming_protocol"
                  value={cellAPNconfig.cell_apn_roaming_protocol}
                  onSelectChange={handleAPNChange}
                  options={Object.keys(CALL_APN_PROTOCOLES).map(
                    (key, index) => (
                      <MenuItem
                        className="m-4 p-2"
                        key={index}
                        dense
                        value={key}
                      >
                        {CALL_APN_PROTOCOLES[key]}
                      </MenuItem>
                    ),
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="cell_apn_proxy"
                label="APN Proxy"
                type="text"
                value={cellAPNconfig.cell_apn_proxy}
                onInputChange={handleAPNChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="cell_apn_proxy_port"
                label="APN Proxy Port"
                type="number"
                value={cellAPNconfig.cell_apn_proxy_port}
                onInputChange={handleAPNChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="cell_apn_server"
                label="APN Server"
                type="text"
                value={cellAPNconfig.cell_apn_server}
                onInputChange={handleAPNChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="APN Bearer"
                prop="cell_apn_bearer"
                multiple={true}
                values={cellAPNconfig.cell_apn_bearer}
                onMultiSelectChange={handleAPNChange}
                options={Object.keys(CALL_APN_BEARER).map((key, index) => (
                  <MenuItem className="m-4 p-2" key={index} dense value={key}>
                    {CALL_APN_BEARER[key]}
                  </MenuItem>
                ))}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default CellularInterface;
