import React from 'react';
import { Grid, Typography, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { GATEWAY_TYPE, hearbeatValuesLabel } from '../../app/constants';
import useStyles from './styles';
import TextInput from '../TextInput';
import SelectInput from '../SelectInput';
import CheckboxInput from '../CheckboxInput';

export interface DeviceHeartbeatProps {
  heartbeat_period?: number;
  heartbeat_values?: string[];
  heartbeatUnit: string;
  deviceType?: DeviceType | null;
  showLabel?: boolean;
  onInputChange: (prop: string, value: string | number) => void;
}

const DeviceHeartbeat: React.FC<DeviceHeartbeatProps> = ({
  heartbeat_period,
  heartbeat_values,
  heartbeatUnit,
  deviceType,
  showLabel,
  onInputChange,
}) => {
  const classes = useStyles();
  const isGateway = deviceType && deviceType.type === GATEWAY_TYPE;
  return (
    <Grid container direction="row" spacing={0}>
      <Grid
        item
        xs={6}
        className={clsx({
          ['mt-4']: showLabel,
        })}
      >
        {showLabel && (
          <>
            <Typography className="mt-6 mb-3" variant="h5">
              Heartbeat
            </Typography>
            <Typography variant="body2" className="custom-label">
              Select reporting frequency
            </Typography>
          </>
        )}
        <Box className={clsx(classes.rowBox)}>
          <TextInput
            data-testid="heartbeat-interval"
            placeholder="Heartbeat interval"
            value={heartbeat_period ?? '0'}
            prop="heartbeat_period"
            onInputChange={onInputChange}
            type="number"
          />

          <SelectInput
            data-testid="heartbeatUnit"
            value={heartbeatUnit}
            prop="heartbeatUnit"
            onSelectChange={onInputChange}
            classes={clsx('ml-2', classes.unitInput)}
            options={[
              <MenuItem key="seconds" className="m-4 p-2" dense value="seconds">
                Seconds
              </MenuItem>,
              <MenuItem key="minutes" className="m-4 p-2" dense value="minutes">
                Minutes
              </MenuItem>,
              <MenuItem key="hours" className="m-4 p-2" dense value="hours">
                Hours
              </MenuItem>,
              <MenuItem key="days" className="m-4 p-2" dense value="days">
                Days
              </MenuItem>,
            ]}
          />
        </Box>
      </Grid>
      {isGateway && (
        <Grid item xs={12} className="mt-2">
          <Grid container spacing={0}>
            {hearbeatValuesLabel.map((item, key) => (
              <Grid
                key={`${item.value}-${key}`}
                item
                xs={12}
                sm={6}
                lg={6}
                xl={4}
              >
                <CheckboxInput
                  label={item.label}
                  prop="heartbeat_values"
                  value={item.value}
                  checked={
                    heartbeat_values &&
                    heartbeat_values.indexOf(item.value) > -1
                  }
                  onCheckboxClick={onInputChange}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DeviceHeartbeat;
