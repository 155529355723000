import {
  Configuration,
  ConfigurationInput,
  DeviceConfig,
  DeviceConfigInput,
  Rule,
  RuleInput,
  ScheduledJob,
  ScheduledJobInput,
  Setting,
  SettingInput,
} from '@edgeiq/edgeiq-api-js';
import EntitiesSection from './EntitiesSection';

export type EntitiesSectionItemType =
  | Configuration
  | ConfigurationInput
  | DeviceConfigInput
  | DeviceConfig
  | Rule
  | RuleInput
  | ScheduledJob
  | ScheduledJobInput
  | Setting
  | SettingInput
  | null;

export default EntitiesSection;
