import React, { useState, ReactElement, useEffect } from 'react';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { DeviceType } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setFilters } from '../../redux/reducers/filters.reducer';
import Filters from '../../containers/Filters';
import AccountFilter from '../../containers/AccountSelect/AccountFilter';
import useStyles from './styles';

interface NetworkConfigurationFiltersProps {
  total: number;
  deviceTypes: DeviceType[];
}

const NetworkConfigurationFilters: React.FC<
  NetworkConfigurationFiltersProps
> = ({ total, deviceTypes }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.configs.filters;

  const [deviceTypesSelected, setDeviceTypesSelected] = useState<string[]>([]);
  const allDevicesTypeSelected =
    deviceTypes.length > 0 && deviceTypesSelected.length === deviceTypes.length;

  useEffect(() => {
    if (filters) {
      setDeviceTypesSelected(
        filters.device_type_id && filters.device_type_id !== ''
          ? filters.device_type_id.split('|')
          : [],
      );
    }
  }, [filters]);

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'configs',
      ),
    );
  };

  const handleMultiSelect =
    (prop: string) =>
    (event: SelectChangeEvent<string[]>): void => {
      const {
        target: { value },
      } = event;

      if (value.includes('all')) {
        if (prop === 'device_type_id') {
          changeFilters(
            prop,
            deviceTypes.reduce((preVal, current, i): string => {
              return i === 0 ? current._id : `${preVal}|${current._id}`;
            }, ''),
          );
        }
        return;
      }

      changeFilters(
        prop,
        typeof value === 'string'
          ? value.replaceAll(',', '|')
          : value.length > 0
          ? value.join('|')
          : '',
      );
    };

  return (
    <Filters
      filterModel="configs"
      total={total}
      hasSearchInput={true}
      inputPlaceholder="Search config by Name"
      inputKey="name"
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <Select
          className={classes.input}
          multiple
          value={deviceTypesSelected}
          onChange={handleMultiSelect('device_type_id')}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected): string[] | ReactElement => {
            if (selected.length === 0) {
              return <Typography variant="button">Device Profile</Typography>;
            }

            if (selected.length > 0) {
              return (
                <Typography variant="button">
                  {`Profiles selected (${selected.length})`}
                </Typography>
              );
            }

            return <Typography variant="button">{selected}</Typography>;
          }}
        >
          <MenuItem dense value="all">
            <Checkbox checked={allDevicesTypeSelected} />
            <ListItemText primary="All" />
          </MenuItem>
          {deviceTypes.map((item, key) => (
            <MenuItem dense key={key} value={item._id}>
              <Checkbox checked={deviceTypesSelected.indexOf(item._id) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      }
    />
  );
};

export default NetworkConfigurationFilters;
