import { Theme } from '@mui/material';
import { createStyles, CSSProperties, makeStyles } from '@mui/styles';
import { StyleProps } from '../../layouts/main/styles';

const openedMixin = (theme: Theme): CSSProperties => ({
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
});

const closedMixin = (theme: Theme): CSSProperties => ({
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
});

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    expandButton: {
      '&:hover': {
        backgroundColor: (props: StyleProps): string => {
          const { branding } = props;
          if (branding?.primary_color) {
            return branding.primary_color;
          }
          return theme.palette.grey[700];
        },
        color: theme.palette.common.white,
      },
      backgroundColor: theme.palette.common.white,
      boxShadow:
        'rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px',
      height: '24px',
      left: '-28px',
      margin: '0',
      position: 'absolute',
      width: '24px',
    },
    expandButtonContainer: {
      '&.collapsed': {
        right: '20px',
      },
      left: `0px`,
      position: 'relative',
      transition: theme.transitions.create('left', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: '0',
      zIndex: '1000',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    listContainer: {
      '&.collapsed': {
        width: '2%',
      },
      '&.expanded': {
        width: '25%',
      },
    },
    mainContentContainer: {
      '&.collapsed': {
        width: '73%',
      },
      '&.expanded': {
        width: '96%',
      },
      marginRight: '2%',
    },
    withTransition: {
      '&.collapsed': {
        ...closedMixin(theme),
      },
      '&.expanded': {
        ...openedMixin(theme),
      },
    },
  }),
);

export default useStyles;
