import Header from '../../components/Header';
import { HeaderProps } from '../../components/Header/Header';
import React, { ReactElement } from 'react';
import ActionButton from '../ActionButton';
import { ActionButtonContainerProps } from '../ActionButton/ActionButton';

interface Props
  extends HeaderProps,
    Omit<ActionButtonContainerProps, 'action'> {
  extraActions?: ReactElement;
  action?: 'create' | 'update' | 'read' | 'delete';
}

const HeaderWithActionButton: React.FC<Props> = (props) => {
  const {
    extraActions,
    model,
    actionLabel: label,
    link,
    action,
    actionIcon,
    actionCallback,
    ...headerProps
  } = props;

  return (
    <Header {...headerProps}>
      {extraActions}
      {action && (
        <ActionButton
          action={action}
          model={model}
          actionLabel={label}
          link={link}
          actionIcon={actionIcon}
          actionCallback={actionCallback}
        />
      )}
    </Header>
  );
};

export default HeaderWithActionButton;
