import React from 'react';

import Home from '@mui/icons-material/Home';
import Email from '@mui/icons-material/Email';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import UsbIcon from '@mui/icons-material/Usb';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleIcon from '@mui/icons-material/People';
import ConfigIcon from '@mui/icons-material/Settings';
import {
  PortalNavigationVisibility,
  UINavigationVisibility,
} from '@edgeiq/edgeiq-api-js';
import { DeviceHub } from '@mui/icons-material';

export interface LinkType {
  text: string;
  link?: string;
  icon?: React.ReactElement;
  sublinks?: LinkType[];
  highlightLinks?: string[];
  readModel?: string;

  portal_configuration?: keyof PortalNavigationVisibility;
  ui_configuration?: keyof UINavigationVisibility;
}

// Leave highlightLinks on parent level to decide on later

const links: LinkType[] = [
  {
    highlightLinks: ['dashboard'],
    icon: <Home />,
    link: 'dashboard',
    portal_configuration: 'overview_hidden',
    text: 'Dashboard',
    ui_configuration: 'dashboard_hidden',
  },
  {
    icon: <DesktopMacIcon />,
    portal_configuration: 'devices_hidden',
    sublinks: [
      {
        highlightLinks: ['new-device', 'device', 'devices'],
        link: 'devices',
        readModel: 'device',
        text: 'All Devices',
      },
      {
        highlightLinks: [
          'new-device-profile',
          'device-profile',
          'device-profiles',
        ],
        link: 'device-profiles',
        readModel: 'device_type',
        text: 'Device Profiles',
      },
      {
        highlightLinks: ['transfers'],
        link: 'transfers',
        portal_configuration: 'transfers_hidden',
        readModel: 'transfers',
        text: 'Transfers',
      },
    ],
    text: 'Devices',
    ui_configuration: 'devices_hidden',
  },
  {
    icon: <ConfigIcon />,
    portal_configuration: 'configs_hidden',
    sublinks: [
      {
        highlightLinks: [
          'new-configuration',
          'configuration',
          'configurations',
        ],
        link: 'configurations',
        readModel: 'configurations',
        text: 'Manage Configurations',
      },
      {
        highlightLinks: [
          'new-network-configuration',
          'network-configuration',
          'network-configurations',
        ],
        link: 'network-configurations',
        readModel: 'device_config',
        text: 'Network Configurations ',
      },
    ],
    text: 'Configuration',
    ui_configuration: 'configuration_hidden',
  },
  {
    icon: <TabletMacIcon />,
    portal_configuration: 'software_hidden',
    sublinks: [
      {
        highlightLinks: ['new-command', 'command', 'commands'],
        link: 'commands',
        readModel: 'command',
        text: 'Commands',
      },
      {
        highlightLinks: ['new-command-tranlator', 'command-translators'],
        link: 'command-translators',
        readModel: 'commandTranslator',
        text: 'Command Translator',
      },
      {
        highlightLinks: ['new-software-update', 'software-update'],
        link: 'software-updates',
        readModel: 'software-update',
        text: 'Software Packages',
      },
    ],
    text: 'Software',
    ui_configuration: 'software_hidden',
  },
  {
    icon: <UsbIcon />,
    sublinks: [
      {
        highlightLinks: ['data-management'],
        link: 'data-management',
        text: 'Consume Data',
      },
      {
        highlightLinks: ['new-policy', 'policy', 'policies'],
        link: 'policies',
        portal_configuration: 'rules_hidden',
        readModel: 'rule',
        text: 'Policies',
      },
    ],
    text: 'Data management',
    ui_configuration: 'data_management_hidden',
    // highlightLinks: [
    //   'data-management',
    //   ...['new-ingestor', 'ingestor', 'ingestors'],
    //   ...['new-translator', 'translator', 'translators'],
    //   ...['new-policy', 'policy', 'policies'],
    // ],
  },
  {
    highlightLinks: ['new-integration', 'integration', 'integrations'],
    icon: <DeviceHub />,
    link: 'integrations',
    portal_configuration: 'integrations_hidden',
    readModel: 'integration',
    text: 'Integrations',
  },
  {
    highlightLinks: ['workflows', 'workflow'],
    icon: <AccountTreeIcon />,
    link: 'workflows',
    portal_configuration: 'workflows_hidden',
    readModel: 'workflow',
    text: 'Workflows',
    ui_configuration: 'workflows_hidden',
  },

  {
    highlightLinks: ['messages'],
    icon: <Email />,
    link: 'messages',
    portal_configuration: 'messages_hidden',
    text: 'Messages',
    ui_configuration: 'messages_hidden',
  },
  {
    highlightLinks: ['scheduled-jobs', 'new-scheduled-job'],
    icon: <WorkHistoryIcon />,
    link: 'scheduled-jobs',
    portal_configuration: 'overview_hidden',
    text: 'Scheduled Jobs',
    ui_configuration: 'scheduled_jobs_hidden',
  },
  {
    icon: <PeopleIcon />,
    sublinks: [
      {
        highlightLinks: ['new-account', 'account', 'accounts'],
        link: 'accounts',
        portal_configuration: 'companies_hidden',
        readModel: 'company',
        text: 'Accounts',
      },
      {
        highlightLinks: ['new-user', 'user', 'users'],
        link: 'users',
        portal_configuration: 'users_hidden',
        readModel: 'user',
        text: 'Users',
      },
      {
        highlightLinks: ['new-file', 'file', 'files'],
        link: 'files',
        readModel: 'file',
        text: 'Files',
      },
    ],
    text: 'Account',
  },
];

export default links;
