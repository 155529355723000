import React from 'react';
import { Box, Typography, Button, Tooltip, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import clsx from 'clsx';

import { StatusTheme } from '../../models/common';
import ColoredBox from '../ColoredBox';
import useStyles from './styles';

interface CardProps {
  title?: string; // Main text of the card
  tag?: string; // Tag set above the title
  tagTheme?: { [key: string]: StatusTheme }; // Color Theme of the tag
  tagThemeKey?: string; // The key to use for the color theme of the colored box
  caption?: string; // Text set below the title
  extraLabel?: string; // Extra actios label
  extraCallback?: () => void; // Triggers when the extra button is clicked
  extraActionButtonLabel?: string;
  extraActionButtonCallBack?: () => void;
  type?: string;
  titleWithToolTip?: boolean;
  loading?: boolean;
  isLast: boolean;
}

const TimelineCard: React.FC<CardProps> = ({
  title,
  tag,
  tagTheme,
  tagThemeKey,
  caption,
  extraActionButtonLabel,
  extraActionButtonCallBack,
  extraLabel,
  extraCallback,
  type = 'activity',
  titleWithToolTip = false,
  loading = false,
  isLast,
}) => {
  const classes = useStyles();
  return (
    <Box>
      {tag && tagTheme && (
        <ColoredBox
          className={clsx('mb-2', classes.tag)}
          type={type}
          value={tag}
          colorTheme={tagTheme[tagThemeKey ?? tag] ?? 'info'}
        />
      )}
      {!titleWithToolTip && (
        <Typography variant="button" component="div">
          {title}
        </Typography>
      )}
      {titleWithToolTip && (
        <Tooltip placement="top-start" title={title || ''}>
          <Typography noWrap variant="button" component="div">
            {title}
          </Typography>
        </Tooltip>
      )}
      {caption && (
        <Typography className={clsx(classes.caption)} variant="button">
          {caption}
        </Typography>
      )}
      {extraLabel && (
        <Box>
          <Button
            color="primary"
            variant="text"
            type="button"
            onClick={extraCallback}
            className={clsx('p-0')}
          >
            <Typography variant="overline">{extraLabel}</Typography>
          </Button>
        </Box>
      )}

      {extraActionButtonLabel && (
        <Box>
          <LoadingButton
            variant="contained"
            size="small"
            onClick={extraActionButtonCallBack}
            loading={loading}
          >
            {extraActionButtonLabel}
          </LoadingButton>
        </Box>
      )}

      {!isLast && <Divider className="my-3" />}
    </Box>
  );
};

export default TimelineCard;
