import { DeviceConfig, DeviceConfigInput } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getDevieConfigConnectionSelector = createSelector(
  (state: NetworkConfigurationsState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.configs.length) {
      return state.actualNetworkConfiguration;
    }
    return state.configs.find((config: DeviceConfig) => config._id === id);
  },
);

export const newNetworkConfiguration: DeviceConfigInput = {
  company_id: '',
  connections: [],
  interface_data_collection: {
    enabled: false,
    frequency_seconds: 300,
    interfaces: [],
  },
  latency_test: {
    enabled: false,
    frequency_seconds: 300,
    hosts: [],
    interfaces: [],
    options: {
      count: 4,
      packet_size: 32,
      timeout_seconds: 2,
    },
    type: 'ping',
  },
  name: '',
  performance_test: {
    enabled: false,
    frequency_seconds: 3600,
    interfaces: [],
    options: {
      download: true,
      servers: [],
      timeout_seconds: 2,
      upload: true,
    },
    type: 'speedtest',
  },
};

export const NetworkConfigurationsTypes = {
  CLEAN_NEW_NETWORK_CONFIGURATION_INPUT:
    'CLEAN_NEW_NETWORK_CONFIGURATION_INPUT',
  SET_ACTUAL_NETWORK_CONFIGURATION: 'SET_ACTUAL_NETWORK_CONFIGURATION',
  SET_NETWORK_CONFIGURATIONS: 'SET_NETWORK_CONFIGURATIONS',
  SET_NEW_NETWORK_CONFIGURATION: 'SET_NEW_NETWORK_CONFIGURATION',
  SET_NEW_NETWORK_CONFIGURATION_INPUT: 'SET_NEW_NETWORK_CONFIGURATION_INPUT',
};

export const cleanNewNetworkConfiguration =
  (): NetworkConfigurationsAction => ({
    data: newNetworkConfiguration as DeviceConfigInput,
    type: NetworkConfigurationsTypes.CLEAN_NEW_NETWORK_CONFIGURATION_INPUT,
  });

export const setStateNetworkConfigurations = (
  configs: DeviceConfig[],
): NetworkConfigurationsAction => ({
  data: configs,
  type: NetworkConfigurationsTypes.SET_NETWORK_CONFIGURATIONS,
});

export const setActualNetworkConfiguration = (
  config: DeviceConfig,
): NetworkConfigurationsAction => ({
  data: config,
  type: NetworkConfigurationsTypes.SET_ACTUAL_NETWORK_CONFIGURATION,
});

export const setNewNetworkConfiguration = (
  config: DeviceConfig,
): NetworkConfigurationsAction => ({
  data: config,
  type: NetworkConfigurationsTypes.SET_NEW_NETWORK_CONFIGURATION,
});

export const setNewNetworkConfigurationInput = (
  config: DeviceConfigInput,
): NetworkConfigurationsAction => ({
  data: config,
  type: NetworkConfigurationsTypes.SET_NEW_NETWORK_CONFIGURATION_INPUT,
});

export type NetworkConfigurationsState = {
  actualNetworkConfiguration: DeviceConfig | null;
  configs: DeviceConfig[];
  newNetworkConfiguration: DeviceConfig | null;
  newNetworkConfigurationInput: DeviceConfigInput | null;
};

// INITIAL STATE
const configsInitialState: NetworkConfigurationsState = {
  actualNetworkConfiguration: null,
  configs: [],
  newNetworkConfiguration: null,
  newNetworkConfigurationInput: newNetworkConfiguration as DeviceConfigInput,
};

export type NetworkConfigurationsAction = {
  data: DeviceConfig[] | DeviceConfig | DeviceConfigInput | string;
  type: string;
};

const networkConfigurationsReducer = (
  state = configsInitialState,
  action: NetworkConfigurationsAction,
): NetworkConfigurationsState => {
  switch (action.type) {
    case NetworkConfigurationsTypes.SET_NETWORK_CONFIGURATIONS:
      return {
        ...state,
        configs: action.data as DeviceConfig[],
      };

    case NetworkConfigurationsTypes.SET_ACTUAL_NETWORK_CONFIGURATION:
      return {
        ...state,
        actualNetworkConfiguration: action.data as DeviceConfig,
        newNetworkConfiguration: action.data as DeviceConfig,
      };

    case NetworkConfigurationsTypes.SET_NEW_NETWORK_CONFIGURATION:
      return {
        ...state,
        newNetworkConfiguration: action.data as DeviceConfig,
      };

    case NetworkConfigurationsTypes.SET_NEW_NETWORK_CONFIGURATION_INPUT:
      return {
        ...state,
        newNetworkConfigurationInput: action.data as DeviceConfigInput,
      };

    case NetworkConfigurationsTypes.CLEAN_NEW_NETWORK_CONFIGURATION_INPUT:
      return {
        ...state,
        newNetworkConfigurationInput: action.data as DeviceConfigInput,
      };

    default:
      return state;
  }
};

export default networkConfigurationsReducer;
