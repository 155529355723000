import ActionButton from '../../components/ActionButton';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { ActionButtonProps } from '../../components/ActionButton/ActionButton';

export interface ActionButtonContainerProps
  extends Omit<ActionButtonProps, 'hasPermission'> {
  model: string;
  action: 'create' | 'update' | 'read' | 'delete';
}

const ActionButtonContainer: React.FC<ActionButtonContainerProps> = (props) => {
  const { permissions } = useAppSelector((state: RootState) => state.user);

  return permissions[props.model] ? (
    <ActionButton
      hasPermission={permissions[props.model][props.action]}
      {...props}
    />
  ) : (
    <ActionButton hasPermission={true} {...props} />
  );
};

export default ActionButtonContainer;
