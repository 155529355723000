import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  Configuration,
  Configurations,
  DeviceTypes,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setStateConfigurations } from '../../redux/reducers/configuration.reducer';
import {
  setStateDeviceTypes,
  setOptionsDeviceTypes,
} from '../../redux/reducers/deviceTypes.reducer';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  genericViewOptions,
  optionsPaginationsFilter,
} from '../../app/constants';
import { SortingOption } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import { SORTING_OPTIONS_GENERIC_NAME } from '../../constants/sortings';
import Header from '../../containers/HeaderWithActionButton';
import ActionDialog from '../../components/ActionDialog';
import ListSelection from '../../components/ListSelection';
import SharedTable from '../../components/SharedTable';
import CardsGrid from '../../components/CardsGrid';
import Card from '../../components/Card';
import ConfigurationCard from './ConfigurationCard';
import ConfigurationFilters from './ConfigurationFilters';
import { ConfigurationColumns } from './columns';

const ConfigurationPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const configurationState = useAppSelector(
    (state: RootState) => state.configurations,
  );

  const [selectedConfigurations, setSelectedConfigurations] = useState<
    string[]
  >([]);
  const [configurations, setConfigurations] = useState<Configuration[]>(
    configurationState.configurations,
  );
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(
      filters.configurations.sortBy,
      SORTING_OPTIONS_GENERIC_NAME,
    ),
  );
  const { deviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [selectedView, setSelectedView] = useState(filters.configurations.view);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getConfiguration = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    Configurations.list(
      parseFilters(
        filters.configurations.filters ?? {},
        filters.case_sensitive,
      ),
      pagination,
    )
      .then((result) => {
        const newConfigurations = addPage
          ? [...configurations, ...result.configurations]
          : result.configurations;
        setConfigurations(newConfigurations);
        dispatch(setStateConfigurations(newConfigurations));
        setTotal(result.pagination.total);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    if (deviceTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          dispatch(setStateDeviceTypes(result.deviceTypes));
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    whenComponetMounted(1);
  }, [filters.configurations]);

  const whenComponetMounted = (newPage: number): void => {
    setLoading(true);
    setSelectedConfigurations([]);
    getConfiguration(newPage);
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getConfiguration(page + 1, true);
  };

  const checkProfileCallback =
    (deviceId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedConfigurations([...selectedConfigurations, deviceId]);
      } else {
        setSelectedConfigurations(
          selectedConfigurations.filter((item) => item !== deviceId),
        );
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'configurations'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'configurations'));
    setSelectedView(view);
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    if (selectedConfigurations.length === 1) {
      Configurations.delete(selectedConfigurations[0])
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: deleteHighlight(
                selectedConfigurations.length,
                'Configuration',
                'Configurations',
              ),
              type: 'success',
            }),
          );
          whenComponetMounted(page);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    } else {
      Configurations.deleteMultiple(selectedConfigurations)
        .then((_result) => {
          dispatch(
            setAlert({
              link: 'messages#bulk-jobs',
              linkText: 'bulk job',
              message:
                'A <link> has been created to delete the selected configurations',
              type: 'success',
            }),
          );
          whenComponetMounted(1);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    }
  };

  const handleSelectAll = (): void => {
    if (selectedConfigurations.length !== configurations.length) {
      setSelectedConfigurations(
        configurations.map((deviceType) => deviceType._id),
      );
    } else {
      setSelectedConfigurations([]);
    }
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        action="create"
        title="Configurations"
        link="new-configuration"
        actionLabel="Create New Configuration"
        model="configuration"
      />
      <ConfigurationFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={SORTING_OPTIONS_GENERIC_NAME}
        selectedLabel="configuration"
        viewsOptions={genericViewOptions}
        itemsSelected={selectedConfigurations.length !== 0}
        itemsSelectedCount={selectedConfigurations.length}
        allSelected={
          configurations.length !== 0 &&
          selectedConfigurations.length === configurations.length
        }
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          <Grid container className="pr-8">
            <Grid item xs={12}>
              {selectedView === 'grid' && (
                <CardsGrid
                  cards={configurations.map((configurationItem) => (
                    <Card
                      key={configurationItem._id}
                      baseLink="/configuration"
                      id={configurationItem._id}
                      checkboxCallback={checkProfileCallback}
                      checked={selectedConfigurations.includes(
                        configurationItem._id,
                      )}
                      content={
                        <ConfigurationCard
                          configuration={configurationItem}
                          deviceTypes={deviceTypes}
                        />
                      }
                    />
                  ))}
                />
              )}
              {selectedView === 'list' && (
                <SharedTable
                  columns={ConfigurationColumns}
                  rows={configurations}
                  sortBy={selectedSorting.value}
                  sortDirection={
                    selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
                  }
                  allSelected={
                    selectedConfigurations.length === configurations.length
                  }
                  loading={loading}
                  selectedItemsIds={selectedConfigurations}
                  onRequestSort={handleTableSorting}
                  selectAllCallback={handleSelectAll}
                  checkboxCallback={checkProfileCallback}
                />
              )}
            </Grid>
          </Grid>
          {configurations.length !== total && (
            <Grid item xs={12} className="mb-9 loading-container">
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedConfigurations.length === 1
                ? 'configuration'
                : 'configurations'
            }:`}</span>
            <ul>
              {configurations
                .filter((configuration) =>
                  selectedConfigurations.includes(configuration._id),
                )
                .map((configuration) => (
                  <li key={configuration._id}>
                    {configuration.name} - Id: {configuration._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default ConfigurationPage;
