import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import {
  AwsThingGroup,
  DeviceType,
  ImportantMetadata,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setNewDeviceType } from '../../../redux/reducers/deviceTypes.reducer';
import { updateThingGroupOnChange } from '../../../helpers/utils';
import { LWM2M_TYPE } from '../../../app/constants';
import IntegrationConfig from '../../../containers/IntegrationConfig';
import SwitchButton from '../../../components/SwitchButton';
import DeviceTypeForm from './deviceTypeForm';
import DeviceTypeObservablePaths from './deviceTypeObservablePaths';
import DeviceTypeImportantMetadata from './deviceTypeImportantMetadata';
import NetworkConfigurationSelect from '../../../containers/NetworkConfigurationSelect';

const DeviceTypeDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );

  const handleInputChange = (
    prop: string,
    value: string | boolean | number,
  ): void => {
    switch (prop) {
      case 'firmware-upgrade':
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            capabilities: {
              ...editableDeviceType?.capabilities,
              firmware: {
                ...editableDeviceType?.capabilities.firmware,
                upgrade: value,
              },
            },
          } as DeviceType),
        );
        break;
      case 'ip-tables':
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            capabilities: {
              ...editableDeviceType?.capabilities,
              iptables: value,
            },
          } as DeviceType),
        );
        break;
      default:
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            [prop]: value,
          } as DeviceType),
        );
    }
  };

  const handleIntegrationChange = (prop: string, value: string): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        [prop]: value,
      } as DeviceType),
    );
  };

  const handleDefaultThingGroupChange = (value: AwsThingGroup): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        default_thing_groups: updateThingGroupOnChange(
          editableDeviceType?.default_thing_groups,
          value,
        ),
      } as DeviceType),
    );
  };

  const updateImportantMetadata = (metadata: ImportantMetadata[]): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        important_metadata: metadata,
      } as DeviceType),
    );
  };

  const handleObservablePathsChange = (
    _prop: string,
    value: string[],
  ): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        capabilities: {
          ...editableDeviceType?.capabilities,
          observable_paths: value,
        },
      } as DeviceType),
    );
  };

  return (
    <Grid container>
      {editableDeviceType && (
        <Grid component={Paper} item xs={12} className={clsx('p-7 shadow')}>
          {/* Details Form */}
          <Typography variant="h5">Details</Typography>
          <DeviceTypeForm
            deviceType={editableDeviceType}
            onInputChange={handleInputChange}
          />
          {/* Integration Form */}
          <Grid container spacing={2}>
            <IntegrationConfig
              deviceType={editableDeviceType}
              onIntegrationChange={handleIntegrationChange}
              onDefaultThingGroupChange={handleDefaultThingGroupChange}
            />
            <NetworkConfigurationSelect
              deviceType={editableDeviceType}
              onChange={handleInputChange}
            />
            <Grid item xs={12}>
              <SwitchButton
                label="Support Firmware Upgrades"
                value={editableDeviceType.capabilities.firmware.upgrade}
                prop="firmware-upgrade"
                onSwitchChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SwitchButton
                label="Support IP Tables"
                value={editableDeviceType.capabilities.iptables}
                prop="ip-tables"
                onSwitchChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <DeviceTypeImportantMetadata
            deviceType={editableDeviceType}
            updateImportantMetadata={updateImportantMetadata}
          />

          {editableDeviceType.type === LWM2M_TYPE && (
            <DeviceTypeObservablePaths
              deviceType={editableDeviceType}
              onInputChange={handleObservablePathsChange}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default DeviceTypeDetails;
