/* eslint-disable sort-keys */
import { PaginationFilter } from '@edgeiq/edgeiq-api-js/dist/models';
import { StatusTheme } from '../models/common';

export const MAX_ACCOUNTS = 24;
export const DEFAULT_TOASTER_TIMER = 30000;

export const loginSessionTokenExpiration = 60000 * 60 * 48; // two days
export const defaultItemsPerPage = 24;
export const SEARCH_LETTERS_QUANTITY = 3;
export const errorHighlight = 'Something went wrong';
export const warningHighlight = 'Warning!';
export const mainBoxShadow = '0px 8px 16px rgba(112, 144, 176, 0.12)';
export const devicesTemplateURL =
  'https://s3.amazonaws.com/edgeiq-assets/sample_gateway.csv';
export const devicesDocsURL = 'https://dev.edgeiq.io/docs/bulk-device-creation';
export const promoteDevicesTemplateUrl =
  'https://edgeiq-assets.s3.amazonaws.com/sample_bulk_promote_discovered_devices.csv';

export const recoverPassData = {
  greeting: `We've received a request to reset your password.\n
  Use the link below to choose a new password for your account. If you did not request to reset your password, ignore this email and the link will expire on its own.`,
  subject: 'Password Reset',
};
export const errorsMessages: {
  [key: string]: string;
} = {
  'Invalid password': 'The password is incorrect. Try again.',
};

export const DEFAULT_CRON_SPEC = '* * * * *';

export const CASE_SENSITIVE_KEY = 'case_sensitive';

export const METADATA_KEY = 'metadata.';
export const SOFTWARE_VERSION_KEY = 'software_version_in';
export const TRANSFER_STATUS_KEY = 'transfer_status';
export const ACTIVATION_STATUS_KEY = 'activation_status';
export const DELETE_BULK_TYPE_KEY = 'delete_bulk_type';
export const BULK_RESPONSE_STATUS_KEY = 'bulk_response_status';

export const accountsDetailsTabsLabel = {
  details: 'Details',
  metadata: 'Metadata',
  pages: 'Pages',
  sharedData: 'Shared Data',
  uiConfiguration: 'UI Configuration',
};

export const heartbeatMetricsLabels: Record<string, string> = {
  as_expected: 'Online',
  inconsistent: 'At Risk',
  not_responsive: 'Offline',
  never_reported: 'Never Reported',
};

export const heartbeatStatus = ['never_reported', 'online', 'offline', 'idle'];

export const heartBeatStatusLabel: {
  [key: string]: string;
} = {
  idle: 'Offline',
  never_reported: 'Never Reported',
  offline: 'At Risk',
  online: 'Online',
};

export const heartbeatColorThemeMap: {
  [key: string]: StatusTheme;
} = {
  idle: 'error',
  never_reported: 'info',
  offline: 'warning',
  online: 'success',
};

export const deviceEventsColorThemeMap: {
  [key: string]: StatusTheme;
} = {
  create: 'info',
  connect: 'success',
  delete: 'error',
  disconnect: 'error',
  heartbeat_status_change: 'info',
  password_change_successful: 'success',
  register: 'info',
  touch: 'info',
  update_edge_version: 'success',
};

export const hearbeatFields: {
  [key: string]: string;
} = {
  cell_signal: 'Cell Signal',
  cell_usage: 'Cell Usage',
  connections: 'Connections',
  cpu_usage: 'CPU Usage',
  disk_free: 'Disk Space free',
  disk_size: 'Disk Size',
  disk_usage: 'Disk Space Usage',
  ram_usage: 'RAM Usage',
  sim_card: 'SIM Card',
  wifi_clients: 'WIFI Clients',
};

export const defaultPaginationFilter: PaginationFilter = {
  itemsPerPage: 24,
  page: 1,
};

export const API_MAX_ITEMS = 1000;

export const optionsPaginationsFilter: PaginationFilter = {
  itemsPerPage: API_MAX_ITEMS,
  page: 1,
};

export const transferStatus = [
  'transfer_initiated_at',
  'transfer_initiated_at_ne',
  'transfer_completed_at_ne',
];

export const transferStatusLabel: {
  [key: string]: string;
} = {
  transfer_completed_at_ne: 'Transfer complete',
  transfer_initiated_at: 'Not yet initiated',
  transfer_initiated_at_ne: 'Transfer initiated',
};

export const deviceDetailsTabsLabel = {
  commands: 'Commands',
  details: 'Details',
  files: 'Files',
  ingestors: 'Ingestors',
  logs: 'Logs',
  lwm2m_details: 'Lwm2m Details',
  metadata: 'Metadata',
  network_connectivity: 'Network Connectivity',
  parent_device: 'Parent Device',
  policies: 'Policies',
  relations: 'Relations',
  settings: 'Settings',
  software_updates: 'Software Updates',
  software_versions: 'Installed Software',
  remote_terminal: 'Remote Terminal',
};

export const policyDetailsTabsLabel = {
  actions: 'Actions',
  conditions: 'Conditions',
  derivedValues: 'Derived Values',
  details: 'Details',
  elseAction: 'Else Actions',
  entities: 'Entities',
};

export const userDetailsTabsLabel = {
  details: 'Details',
  metadata: 'Metadata',
};

export const configurationTabsLabel = {
  details: 'Details',
  commands: 'Commands',
  device_profiles: 'Device Profiles',
  settings: 'Settings',
};

export const settingsTabsLabel = {
  details: 'Details',
  devices_and_device_profiles: 'Devices & Device Profiles',
};

export const networkConfigTabsLabel = {
  details: 'Details',
  device_profiles: 'Device Profiles',
  interfaces: 'Interfaces',
};

// Device Genre
export const GENRE_ACTIVE = 'active';
export const GENRE_ESCROW = 'escrow';
export const GENRE_DISCOVERED = 'discovered';

// Tranfers type
export const TRANFERS_RECEIVED = 'received';
export const TRANFERS_SENT = 'sent';

//Lwm2m SoftWare Update Status
export const LWM2M_STATUS_DOWNLOAD = 'download';
export const LWM2M_STATUS_EXECUTE = 'execute';

//state action mode
export const CREATE = 'create';
export const UPDATE = 'update';

export const deviceTypeTabsLabel = {
  abilities: 'Abilities',
  commands: 'Commands',
  configurations: 'Configurations',
  connections: 'Connections',
  details: 'Details',
  ingestors: 'Ingestors',
  metadata: 'Metadata',
  policies: 'Policies',
  pollableAttributes: 'Pollable Attributes',
  softwareUpdate: 'Software Update',
};

export const activityLabel: {
  [key: string]: string;
} = {
  alert: 'Alert',
  error: 'Error',
  update: 'Update',
};

export const activityColorThemeMap: {
  [key: string]: StatusTheme;
} = {
  alert: 'warning',
  error: 'error',
  heartbeat: 'info',
  update: 'info',
};

export const activityFilterValues: {
  [key: string]: string;
} = {
  errors: 'errors',
  events: 'events',
  heartbeats: 'heartbeats',
  recent_command: 'recent-command',
  recent_software_updates: 'recent-software-updates',
  recent_user_defined_command: 'recent-user-defined-command',
  reports: 'reports',
  workflows_executions: 'workflows-executions',
};

export const activityFilterOptions = [
  {
    label: 'Reports',
    value: 'reports',
  },
  {
    label: 'Heartbeats',
    value: 'heartbeats',
  },
  {
    label: 'Errors',
    value: 'errors',
  },
  {
    label: 'Events',
    value: 'events',
  },
  {
    label: 'Recent software updates',
    value: 'recent-software-updates',
  },
  {
    label: 'Recent gateway commands',
    value: 'recent-command',
  },
  {
    label: 'Recent User Defined commands',
    value: 'recent-user-defined-command',
  },
  {
    label: 'Workflows executions',
    value: 'workflows-executions',
  },
];

export const hearbeatValuesLabel: {
  [key: string]: string;
}[] = [
  {
    label: 'Disk Size',
    value: 'disk_size',
  },
  {
    label: 'Disk Space Free',
    value: 'disk_free',
  },
  {
    label: 'RAM Usage',
    value: 'ram_usage',
  },
  {
    label: 'Disk Space Usage',
    value: 'disk_usage',
  },
  {
    label: 'CPU Usage',
    value: 'cpu_usage',
  },
];

export const genericViewOptions = ['list', 'grid'];

export const profileTypesMap: {
  [key: string]: string;
} = {
  cloud_native: 'Cloud Native',
  gateway: 'Gateway',
  lwm2m: 'LWM2M',
  modem: 'Modem',
  sensor: 'Sensor',
};

export const profileRolesMap: {
  [key: string]: string;
} = {
  endpoint: 'Endpoint',
  gateway: 'Gateway',
};

export const dataManagementTabsLabels = {
  ingestors: 'Ingestors',
  integrations: 'Integrations',
  policies: 'Policies',
  pollable_attributes: 'Pollable Attributes',
  translators: 'Translators',
};

export const messagesTabsLabels = {
  alerts: 'Alerts',
  bulk_jobs: 'Bulk Jobs',
  commands_executions: 'Commands Executions',
  device_errors: 'Device Errors',
  gateway_commands: 'Gateway Commands',
  heartbeats: 'Heartbeats',
  integration_events: 'Integration Events',
  reports: 'Reports',
  settings_application_records: 'Settings Application Records',
};

export const translatorTypesMap: {
  [key: string]: string;
} = {
  altair: 'SSI Smart Label Translator',
  assetlink: 'Assetlink Device Translator',
  gs: 'Globalstar Device Translator',
  inmarsat: 'Inmarsat Device Translator',
  javascript: 'Javascript',
  orbcomm: 'Orbcomm Device Translator',
  template: 'Template',
  template_cmd: 'Command Translator',
};

export const deleteHighlight = (
  count: number,
  single: string,
  plural: string,
): string => `${count === 1 ? single : plural} deleted successfully`;

export const integrationTypesMap: {
  [key: string]: string;
} = {
  aws_device_integrations: 'IoT - AWS',
  azure_device_integrations: 'IoT - Microsoft Azure',
  gcp_cloud_native_device_integrations:
    'Google Cloud Platform (GCP) - Cloud Native',
  gs_cloud_native_device_integrations: 'GlobalStar (GS) - Cloud Native',
  inmarsat_cloud_native_device_integrations:
    'Isat Data Pro (Inmarsat) - Cloud Native',
  orbcomm_cloud_native_device_integrations:
    'Isat Data Pro (Orbcomm) - Cloud Native',
  postmark_rule_action_integrations: 'E-Mail - Postmark',
  smtp_rule_action_integrations: 'E-Mail - SMTP',
  twilio_rule_action_integrations: 'SMS - Twilio',
  generic_username_password_integrations:
    'Generic Username/Password Integration',
  generic_token_integrations: 'Generic Token Integration',
};

export const pollableAttributeTypesMap: {
  [key: string]: string;
} = {
  snmp_mib: 'SNMP MIB',
  snmp_mib_integer: 'SNMP MIB Integer',
  snmp_mib_octet_string: 'SNMP MIB Octet String',
};

interface MetricsColorProps {
  [key: string]: {
    [key: string]: string;
  };
}

export const metricsColor: MetricsColorProps = {
  as_expected: {
    backgroundColor: '#E6F8F3',
    progressMainColor: '#609884',
    progressSecondaryColor: '#CEE3DE',
  },
  inconsistent: {
    backgroundColor: '#FBF1D9',
    progressMainColor: '#EDB31E',
    progressSecondaryColor: '#FFE298',
  },
  never_reported: {
    backgroundColor: '#ECF1F6',
    progressMainColor: '#2B4259',
    progressSecondaryColor: '#C6CFD8',
  },
  not_responsive: {
    backgroundColor: '#FAECEB',
    progressMainColor: '#DE4F48',
    progressSecondaryColor: '#FFD4D2',
  },
};

export const CHART_COLORS = ['#2B4259', '#609884', '#EDB31E', '#DE4F48'];

export const connectionsLabel: {
  [key: string]: string;
} = {
  cellular: 'Cellular',
  ethernet_lan: 'Ethernet-LAN',
  ethernet_wan: 'Ethernet-WAN',
  ethernet_wan_lan: 'Ethernet-WAN/LAN',
  wifi: 'WiFi',
};
export const logsTypesMap: {
  [key: string]: string;
} = {
  critical: 'Critical',
  error: 'Error (default)',
  warn: 'Warning',
  info: 'Info',
  debug: 'Debug',
  trace: 'Trace',
};

export const snmpVersions: {
  [key: string]: string;
} = {
  '1': 'Version 1',
  '2c': 'Version 2c',
  '3': 'Version 3',
};

export const modbusRequestsTypes: {
  [key: string]: string;
} = {
  mask_write_register: 'Mask Write Register',
  read_coils: 'Read Coils',
  read_fifo_queue: 'Read FIFO Queue',
  read_holding_register: 'Read Holding Register',
  read_input_registers: 'Read Input Registers',
  read_inputs: 'Read Inputs',
  write_single_coil: 'Write Single Coil',
  write_single_register: 'Write Single Register',
};
export const opcuaRequestsTypes: {
  [key: string]: string;
} = {
  read_single_int32: 'Read Single Int32',
  write_single_int32: 'Write Single Int32',
};

export const bacnetObjectTypes: {
  [key: string]: string;
} = {
  analog_input: 'Analog Input',
  analog_output: 'Analog Output',
  analog_value: 'Analog Value',
  binary_input: 'Binary Input',
  binary_output: 'Binary Output',
  binary_value: 'Binary Value',
  characterstring_value: 'Characterstring Value',
  integer_value: 'Integer Value',
  large_analog_value: 'Large Analog Value',
  positive_integer_value: 'Positive Integer Value',
};

export const methodsTypes: {
  [key: string]: string;
} = {
  delete: 'DELETE',
  get: 'GET',
  patch: 'PATCH',
  post: 'POST',
  put: 'PUT',
};

export const dbusHandlerTypes: {
  [key: string]: string;
} = {
  int32: 'int32',
  int64: 'int64',
  string: 'String',
  string_to_value_32: 'Dictionary of key: string to value: int32',
  string_to_value_64: 'Dictionary of key: string to value: int64',
  string_to_value_string: 'Dictionary of key: string to value: string',
};

export const baudRates = [
  1200, 2400, 4800, 9600, 19200, 38400, 57600, 155200, 230400,
];

// Default global consts
export const defaultConnectionType = 'ethernet-wan';

export const AwsGreengrassDocsURL =
  'https://docs.aws.amazon.com/greengrass/v1/developerguide/what-is-gg.html#gg-core-download-tab';
export const AwsGreengrassV1EndOfMaintenanceURL =
  'https://docs.aws.amazon.com/greengrass/v1/developerguide/maintenance-policy.html#end-of-maintenance-schedule';
export const AwsGreengrassDocsURLV2 =
  'https://docs.aws.amazon.com/greengrass/v2/developerguide/manual-installation.html#download-greengrass-core-v2';

export const AwsGreengrassV2LatestInstallURL =
  'https://d2s8p88vqu9w66.cloudfront.net/releases/greengrass-nucleus-latest.zip';

export const SPEEDTEST_SERVERS_URL =
  'https://williamyaps.github.io/wlmjavascript/servercli.html';

export const activeDevicesFileName = 'Active_Devices';

export const discoveredDevicesFileName = 'Discovered_Devices';

// Device Types used values
export const GATEWAY_TYPE = 'gateway';
export const CLOUD_NATIVE_TYPE = 'cloud_native';
export const SENSOR_TYPE = 'sensor';
export const LWM2M_TYPE = 'lwm2m';
export const GATEWAY_ROLE = 'gateway';
export const ENDPOINT_ROLE = 'endpoint';

// Lwm2m actions and types
export const LWM2M_REQUEST = 'lwm2m_request';
export const LWM2M_FILE = 'lwm2m_file';
export const LWM2M_FILE_UPLOAD = 'fileupload';
export const LWM2M_FILE_DOWNLOAD = 'filedownload';

// aws iot thing group gateway commands
export const AWSIOT_ATTACH_THING_GROUPS = 'awsiot_attach_thing_groups';
export const AWSIOT_DETACH_THING_GROUPS = 'awsiot_detach_thing_groups';

// cancel password change gateway command
export const CANCEL_PASSWORD_CHANGE = 'cancel_password_change';

// file transfer gateway commands
export const FILE_TO_DEVICE = 'file_to_device';
export const FILE_FROM_DEVICE = 'file_from_device';

// Default tab details in almost all content pages
export const DETAILS_DEFAULT_TAB = 'details';

export const filtersKeysLabels: {
  [key: string]: string;
} = {
  [ACTIVATION_STATUS_KEY]: 'Activation Status',
  ancestor_company_id: 'Descendants accounts of',
  company_id: 'Account',
  device_type_id: 'Device Profile',
  health_status: 'Health Status',
  heartbeat_status: 'Heartbeat',
  tags: 'Tags',
  type: 'Type',
};

export const healthStatusLabel: {
  [key: string]: string;
} = {
  critical: 'Critical',
  needs_attention: 'Needs Attention',
  normal: 'Normal',
  null: 'No status',
};

export const activationStatusLabel: {
  [key: string]: string;
} = {
  activated_at: 'Not yet activated',
  activated_at_ne: 'Activated',
  deactivated_at_ne: 'Deactivated',
};
export const softwareUpdateType: {
  [key: string]: string;
} = {
  bash_script: 'Bash Script',
  command_delivered: 'Delivered via Command',
  lwm2m_object_5: 'LWM2M Firmware Update',
  lwm2m_object_9: 'LWM2M Software Update',
};
export const activeFilterLabel: {
  [key: string]: string;
} = {
  false: 'Inactive',
  true: 'Active',
};
export const ruleTypesFilterLabel: {
  [key: string]: string;
} = {
  false: 'Edge',
  true: 'Cloud',
};
export const deviceTransferStatus: {
  [key: string]: string;
} = {
  completed: 'Completed',
  error: 'Error',
  pending: 'Pending',
  processing: 'Processing',
};

export const lwm2mSoftwareStatus: {
  [key: string]: string;
} = {
  LWM2M_STATUS_DOWNLOAD: 'Download',
  LWM2M_STATUS_EXECUTE: 'Execute',
};

export const greengrassVersion: {
  [key: string]: string;
} = {
  v2: 'Greengrass v2',
  v1: 'Greengrass v1 (deprecated)',
};

export const configurationType: {
  [key: string]: string;
} = {
  boolean: 'Boolean',
  integer: 'Integer',
  predefined_list: 'Predefined',
  string: 'String',
};

export const configurationPredifinedLabel: {
  [key: string]: string;
} = {
  auto: 'Auto',
  static: 'Static',
};

export const commandExecutionColorThemeMap: {
  [key: string]: StatusTheme;
} = {
  error: 'error',
  info: 'warning',
  retry: 'warning',
  initiated: 'info',
  success: 'success',
};

export const workflowExecutionColorThemeMap: {
  [key: string]: StatusTheme;
} = {
  error: 'error',
  info: 'info',
  retry: 'warning',
  warn: 'warning',
  initiated: 'info',
  success: 'success',
};

export const devicesFiltersTabsLabels = {
  accounts: 'Accounts',
  activationStatus: 'Activation Status',
  deviceProfiles: 'Device Profile',
  healthStatus: 'Health Status',
  heartbeat: 'Heartbeat',
  metadata: 'Metadata',
  softwareVersion: 'Software Version',
  tags: 'Tags',
};
export const scheduledJobTypes: {
  [key: string]: string;
} = {
  'user-defined-command': 'Command',
  'gateway-command': 'Gateway command',
  // 'user-defined-workflow': 'Workflows',
};

export const settingApplicationRecordThemeMap: {
  [key: string]: StatusTheme;
} = {
  sent: 'info',
  reported: 'success',
};

export const retryStrategyOptions: {
  [key: string]: string;
} = {
  none: `Don't retry`,
  fixed: 'Fixed',
  exponential_backoff: 'Exponential backoff',
};

export type DeviceAuthMethod = 'password-auth' | 'certificate-auth';

export const deviceAuthMethodLabels: {
  [key in string]: string;
} = {
  'certificate-auth': 'X. 509 certificate',
  'password-auth': 'Password',
};

export const workflowStatusThemeMap: {
  [key: string]: StatusTheme;
} = {
  Unknown: 'info',
  Running: 'success',
};

export const workflowStatusLabelMap: {
  [key: string]: string;
} = {
  Unknown: 'Inactive',
  Running: 'Active',
};

export const BULK_RESPONSE_TYPES: {
  [key: string]: string;
} = {
  delete: 'Bulk Delete',
  create_devices: 'Create Devices',
  create_escrow_devices: 'Create Escrow Devices',
  csv_upload: 'CSV Devices Upload',
  device_transfer_request: 'Device Transfer Request',
  execute_workflow: 'Execute Workflow',
  gateway_command: 'Gateway command',
  bulk_promote_discovered_devices: 'Promote Discovered Devices',
  update_devices: 'Update Devices',
  command: 'User defined command',
};

export const BULK_RESPONSE_STATUS: {
  [key: string]: string;
} = {
  success: 'Completed successfully',
  fail: 'Failed',
  both: 'Completed with some failures',
};

export const METADATA_ARRAY_OPERATORS = ['in', 'nin'];

export const METADATA_OPERATORS_SYMBOLS: {
  [key: string]: string;
} = {
  eq: '=',
  ne: '≠',
  in: '∈',
  nin: '∉',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
};

export const METADATA_OPERATORS: {
  [key: string]: string;
} = {
  eq: `equal (${METADATA_OPERATORS_SYMBOLS.eq})`,
  ne: `not equal (${METADATA_OPERATORS_SYMBOLS.ne})`,
  in: `any of (${METADATA_OPERATORS_SYMBOLS.in})`,
  nin: `none of (${METADATA_OPERATORS_SYMBOLS.nin})`,
};

export const SOFTWARE_VERSION_OPERATORS: {
  [key: string]: string;
} = {
  eq: `equal (${METADATA_OPERATORS_SYMBOLS.eq})`,
  gt: `greater than (${METADATA_OPERATORS_SYMBOLS.gt})`,
  gte: `greater than or equal (${METADATA_OPERATORS_SYMBOLS.gte})`,
  lt: `less than (${METADATA_OPERATORS_SYMBOLS.lt})`,
  lte: `less than or equal (${METADATA_OPERATORS_SYMBOLS.lte})`,
  installed: 'installed',
};
