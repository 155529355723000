import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Command, Commands, PaginationFilter } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  setNewCommandInput,
  setStateCommands,
} from '../../redux/reducers/commands.reducer';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  genericViewOptions,
} from '../../app/constants';
import { GENERIC_SORTING } from '../../constants/sortings';
import { SortingOption } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import ListSelection from '../../components/ListSelection';
import SharedTable from '../../components/SharedTable';
import CardsGrid from '../../components/CardsGrid';
import Card from '../../components/Card';
import ActionDialog from '../../components/ActionDialog';
import Header from '../../containers/HeaderWithActionButton';
import CommandCard from './CommandCard';
import CommandsFilters from './CommandsFilters';
import { CommandsColumns } from './columns';

const CommandsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filters = useAppSelector((state: RootState) => state.filters);
  const commandsState = useAppSelector((state: RootState) => state.commands);

  const [selectedCommands, setSelectedCommands] = useState<string[]>([]);
  const [commands, setCommands] = useState<Command[]>(commandsState.commands);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.commands.sortBy, GENERIC_SORTING),
  );
  const [selectedView, setSelectedView] = useState(filters.commands.view);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const getCommands = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    Commands.list(
      parseFilters(filters.commands.filters ?? {}, filters.case_sensitive),
      pagination,
    )
      .then((result) => {
        const newCommands = addPage
          ? [...commands, ...result.commands]
          : result.commands;
        setCommands(newCommands);
        dispatch(setStateCommands(newCommands));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    whenComponetMounted(1);
  }, [filters.commands]);

  const whenComponetMounted = (newPage: number): void => {
    setLoading(true);
    setSelectedCommands([]);
    getCommands(newPage);
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getCommands(page + 1, true);
  };

  const checkCommandCallback =
    (deviceId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedCommands([...selectedCommands, deviceId]);
      } else {
        setSelectedCommands(
          selectedCommands.filter((item) => item !== deviceId),
        );
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'commands'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'commands'));
    setSelectedView(view);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleSelectAll = (): void => {
    if (selectedCommands.length !== commands.length) {
      setSelectedCommands(commands.map((command) => command._id));
    } else {
      setSelectedCommands([]);
    }
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    if (selectedCommands.length === 1) {
      Commands.delete(selectedCommands[0])
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: deleteHighlight(
                selectedCommands.length,
                'Command',
                'Commands',
              ),
              type: 'success',
            }),
          );
          whenComponetMounted(page);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    } else {
      Commands.deleteMultiple(selectedCommands)
        .then((_result) => {
          dispatch(
            setAlert({
              link: 'messages#bulk-jobs',
              linkText: 'bulk job',
              message:
                'A <link> has been created to delete the selected commands',
              type: 'success',
            }),
          );
          whenComponetMounted(1);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    }
  };

  const handleCopyCommand =
    (command: Command) =>
    (event: MouseEvent<HTMLButtonElement>): void => {
      event.stopPropagation();
      dispatch(
        setNewCommandInput({
          ...command,
          name: `${command.name} - copy`,
        }),
      );
      navigate('/new-command');
    };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        action="create"
        title="Commands"
        link="new-command"
        actionLabel="Create New Command"
        model={'command'}
      />
      <CommandsFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={GENERIC_SORTING}
        selectedLabel="command"
        viewsOptions={genericViewOptions}
        itemsSelected={selectedCommands.length !== 0}
        itemsSelectedCount={selectedCommands.length}
        allSelected={
          commands.length !== 0 && selectedCommands.length === commands.length
        }
        deleteAction={true}
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && (
            <CardsGrid
              cards={commands.map((command) => (
                <Card
                  checked={selectedCommands.includes(command._id)}
                  checkboxCallback={checkCommandCallback}
                  id={command._id}
                  baseLink="/command"
                  content={<CommandCard command={command} />}
                />
              ))}
            />
          )}
          {selectedView === 'list' && (
            <SharedTable
              columns={CommandsColumns(handleCopyCommand)}
              rows={commands}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={selectedCommands.length === commands.length}
              loading={loading}
              selectedItemsIds={selectedCommands}
              onRequestSort={handleTableSorting}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkCommandCallback}
            />
          )}
          {commands.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedCommands.length === 1 ? 'command' : 'commands'
            }:`}</span>
            <ul>
              {commands
                .filter((command) => selectedCommands.includes(command._id))
                .map((command) => (
                  <li key={command._id}>
                    {command.name} - Id: {command._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default CommandsPage;
