import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import {
  Command,
  Configuration,
  Configurations,
  Device,
  Devices,
  Setting,
  SettingApplicationRecords,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch } from '../../../redux/hooks';
import { dispatchError, getFetchError } from '../../../helpers/utils';
import CodeEditor from '../../../components/CodeEditor';
import useStyles from '../styles';

interface DeviceSettingTypeProps {
  configuration?: Configuration | null;
  device?: Device | null;
  type: 'sent' | 'reported';
}

const DeviceSettingType: React.FC<DeviceSettingTypeProps> = ({
  configuration,
  device,
  type,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errorDispatcher = dispatchError(dispatch);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [setting, setSetting] = useState<Setting>();
  const [settingValues, setSettingValues] = useState('');
  const [settingVersion, setSettingVersion] = useState<number>();
  const [fetchCommand, setFetchCommand] = useState<Command>();

  const getApplicationRecord = (): void => {
    setLoading(true);
    if (device && configuration) {
      SettingApplicationRecords.list(
        {
          configuration_id: { operator: 'eq', value: configuration?._id },
          device_id: { operator: 'eq', value: device?._id },
          type: { operator: 'eq', value: type },
        },
        {
          itemsPerPage: 1,
          order_by: '-created_at',
          page: 1,
        },
      )
        .then((result) => {
          const applicationSettings =
            result.settingsApplicationRecords[0]?.setting;
          const version = result.settingsApplicationRecords[0]?.version;
          if (applicationSettings) {
            setSetting(applicationSettings);
            try {
              setSettingValues(
                JSON.stringify(applicationSettings.values, null, 2),
              );
            } catch (error) {
              console.info(error);
            }
          } else {
            setSettingValues('');
          }
          if (version) {
            setSettingVersion(version);
          } else {
            setSettingVersion(undefined);
          }
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError(`last ${type} settings.`),
          );
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSetting(undefined);
    setSettingValues('');
    setSettingVersion(undefined);
    getApplicationRecord();
  }, [device, configuration, type]);

  useEffect(() => {
    setFetchCommand(undefined);
    if (configuration) {
      Configurations.getFetchCommand(configuration._id)
        .then((response) => {
          setFetchCommand(response);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('configuration fetch command.'),
          );
        });
    }
  }, [configuration]);

  const handleGoToSettings = (): void => {
    navigate(
      `/configuration/${configuration?._id}/${
        setting ? `settings/${setting._id}` : 'new-settings'
      }`,
      {
        state: {
          goBackLabel: `Device: ${device?.name}`,
        },
      },
    );
  };

  const handleReFetch = (): void => {
    if (device && configuration && fetchCommand) {
      setFetching(true);
      Devices.processGatewayCommand(device?._id, {
        command_id: fetchCommand._id,
        command_type: 'setting_fetch',
        configuration_id: configuration._id,
      })
        .then(() => {
          getApplicationRecord();
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            'Error fetching settings',
          );
        })
        .finally(() => {
          setFetching(false);
        });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} className="mb-4 d-flex flex-justify-between">
        <div>
          <Typography variant="h6" className="mr-4" component="span">
            {`Last ${type} settings`}
          </Typography>
          <Link
            variant="button"
            underline="none"
            className="mr-2"
            style={{ cursor: 'pointer' }}
            onClick={handleGoToSettings}
          >
            <strong>{setting?.name}</strong>
          </Link>
          {typeof settingVersion !== 'undefined' ? (
            <Typography variant="overline" className={classes.version}>
              <strong>{`V${settingVersion}`}</strong>
            </Typography>
          ) : (
            <></>
          )}
        </div>
        {type === 'reported' && fetchCommand && (
          <Button
            data-cy="fetch-last-reported-settings"
            variant="contained"
            size="small"
            disabled={fetching}
            onClick={handleReFetch}
          >
            {!fetching ? (
              <Typography variant="button">Fetch settings</Typography>
            ) : (
              <CircularProgress size={15} />
            )}
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Grid container className="loading-container">
            <CircularProgress size={75} thickness={5} />
          </Grid>
        ) : (
          <Box>
            {settingValues !== '' ? (
              <CodeEditor
                mode="json"
                prop="json_schema"
                height={400}
                disabled={true}
                value={settingValues}
              />
            ) : (
              <Typography variant="button">
                {`No settings ${type} for this device with this configuration.`}
              </Typography>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceSettingType;
