import { ReactElement } from 'react';
import {
  CellTower as CellTowerIcon,
  Lan as LanIcon,
  Language as LanguageIcon,
  SettingsEthernet as SettingsEthernetIcon,
  Wifi as WifiIcon,
} from '@mui/icons-material';
import {
  CellularAPNConfig,
  ConnectionType,
  DeviceConfig,
  DeviceConfigInput,
} from '@edgeiq/edgeiq-api-js';
import { set, get, cloneDeep } from 'lodash';

import { newNetworkConfiguration } from '../../../redux/reducers/networkConfigurations.reducer';

// Handle the changes of the main part of the form, not the connectiosn specifications.
export const handleNetworkFormChange = (
  deviceConfig: DeviceConfigInput | DeviceConfig,
  prop: string,
  value: string | number | string[] | CellularAPNConfig[],
): DeviceConfigInput | DeviceConfig => {
  const auxConfig = cloneDeep(deviceConfig);
  if (prop.indexOf('.') !== -1) {
    if (typeof value === 'string' && value.indexOf('checkbox') !== -1) {
      const priorState = get(deviceConfig, prop, value);
      set(auxConfig, prop, !priorState);
    } else {
      set(auxConfig, prop, value);
    }
    return auxConfig;
  }
  if (typeof value === 'string' && value.indexOf('restore_defaults') !== -1) {
    if (prop === 'latency_test' || prop === 'performance_test') {
      set(auxConfig, `${prop}.options`, newNetworkConfiguration[prop]?.options);
    }
    return auxConfig;
  }
  return {
    ...auxConfig,
    [prop]: value,
  };
};

export const renderTypeIcon = (type: ConnectionType): ReactElement => {
  switch (type) {
    case 'cellular':
      return <CellTowerIcon className="ml-2" fontSize="small" />;
    case 'ethernet_lan':
      return (
        <>
          <SettingsEthernetIcon className="ml-2" fontSize="small" />
          <LanIcon className="ml-1" fontSize="small" />
        </>
      );
    case 'ethernet_wan':
      return (
        <>
          <SettingsEthernetIcon className="ml-2" fontSize="small" />
          <LanguageIcon className="ml-1" fontSize="small" />
        </>
      );
    case 'ethernet_wan_lan':
      return (
        <>
          <SettingsEthernetIcon className="ml-2" fontSize="small" />
          <LanguageIcon className="ml-1" fontSize="small" />
          <LanIcon className="ml-1" fontSize="small" />
        </>
      );
    case 'wifi':
      return <WifiIcon className="ml-2" fontSize="small" />;
    default:
      return <></>;
  }
};

export const processRemoveIntercae = (
  deviceConfig: DeviceConfigInput | DeviceConfig,
  index: number,
): DeviceConfigInput | DeviceConfig => {
  const auxConfig = cloneDeep(deviceConfig);
  if (auxConfig.connections?.length) {
    auxConfig.connections.splice(index, 1);
    return auxConfig;
  }
  return auxConfig;
};
