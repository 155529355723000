import { DeviceTypeInput } from '@edgeiq/edgeiq-api-js';

export const emptyDeviceTypeInput: DeviceTypeInput = {
  capabilities: {
    actions: {
      activate_cloud_native_device: false,
      aws_iot: false,
      azure_iot: false,
      backup: false,
      bacnet: false,
      bluemix_iot: false,
      data_reset: false,
      data_restore: false,
      deactivate_cloud_native_device: false,
      deploy_deployment: true,
      disable_data_restriction: false,
      email: false,
      enable_data_restriction: false,
      file_transfer: false,
      greengrass_initialize: false,
      greengrass_redeploy: false,
      greengrass_restart: false,
      heartbeat: true,
      http_request: false,
      log: true,
      log_config: true,
      log_level: true,
      log_upload: true,
      lwm2m_request: false,
      mqtt: false,
      network_connectivity_monitoring: false,
      notification: false,
      opcua: false,
      provision_marmon_button: false,
      prtg: false,
      reboot: true,
      relay: true,
      remote_terminal: false,
      request_deployment_status: false,
      reset: false,
      restore_backup: false,
      send_config: true,
      sms: false,
      sms_at_command_status_request: false,
      software_update: true,
      software_version: true,
      status: false,
      tcp: false,
      tcp_modbus: false,
      update_edge: true,
      update_firmware: true,
    },
    firmware: {
      backup: false,
      upgrade: false,
    },
    iptables: false,
    peripherals: [],
  },
  company_id: '',
  manufacturer: '',
  model: '',
  name: '',
  role: 'gateway',
  type: 'gateway',
};
