import { ReactElement, useEffect, useState } from 'react';
import { ClassNameMap, Grid, Tab, Tabs, Typography } from '@mui/material';
import { NetworkInterfaceLatencyReport } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import timeHelpers from '../../../helpers/timeHelpers';

/**
 * Latency Report component, any data related to the latency should be added/proccessed here.
 */
const NetworkLatency: React.FC<{
  latencies: NetworkInterfaceLatencyReport[];
  classes: ClassNameMap;
  renderDataPair: (
    label: string,
    data: string,
    icon?: ReactElement,
  ) => ReactElement;
}> = ({ latencies, classes, renderDataPair }) => {
  const [isOnlyOne, setIsOnlyOne] = useState(true);
  const [chosenTab, setChosenTab] = useState(latencies[0]?.host ?? '');
  const [latency, setLatency] = useState<NetworkInterfaceLatencyReport>();

  useEffect(() => {
    if (latencies.length !== 0) {
      setChosenTab(latencies[0].host);
      setLatency(latencies[0]);
      setIsOnlyOne(latencies.length === 1);
    }
  }, [latencies]);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: string,
  ): void => {
    setChosenTab(newValue);
    setLatency(latencies.find((l) => newValue === l.host));
  };

  return (
    <Grid container spacing={2} className="mb-4">
      <Grid item xs={12}>
        {isOnlyOne ? (
          <Typography variant="h6" className="m-0">
            Latest Latency Test
            <Typography
              component="span"
              variant="overline"
              className={clsx('ml-3', classes.dataLabel)}
            >
              {' '}
              {latency ? timeHelpers.getDate(latency.created_at) : '--'}
            </Typography>
          </Typography>
        ) : (
          <div className="d-flex flex-items-center">
            <Typography component="span" variant="h6" className="m-0">
              Latency Tests{' '}
              <Typography
                component="span"
                variant="overline"
                className={clsx('ml-3', classes.dataLabel)}
              >
                Hosts
              </Typography>
            </Typography>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={chosenTab}
              onChange={handleChange}
            >
              {latencies.map(
                (lat, index): ReactElement => (
                  <Tab
                    key={`tab-${lat.host}-${index}`}
                    label={lat.host}
                    value={lat.host}
                  />
                ),
              )}
            </Tabs>
          </div>
        )}
      </Grid>
      {latency && (
        <>
          {!isOnlyOne && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                'Tested at',
                timeHelpers.getDate(latency.created_at),
              )}
            </Grid>
          )}
          {latency.host && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair('Host Name', latency.host)}
            </Grid>
          )}
          {latency.min_latency_ms && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                'Min Latency',
                `${String(latency.min_latency_ms.toFixed(2))} ms`,
              )}
            </Grid>
          )}
          {latency.max_latency_ms && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                'Max Latency',
                `${String(latency.max_latency_ms.toFixed(2))} ms`,
              )}
            </Grid>
          )}
          {latency.avg_latency_ms && (
            <Grid item xs={12} md={6} lg={3}>
              {renderDataPair(
                'Average Latency',
                `${String(latency.avg_latency_ms.toFixed(2))} ms`,
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default NetworkLatency;
