import { Grid, MenuItem, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import clsx from 'clsx';
import cloneDeep from 'lodash.clonedeep';
import { AwsThingGroup, DeviceTypes } from '@edgeiq/edgeiq-api-js';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  setNewDeviceTypeInput,
  cleanNewDeviceTypeInput,
  setOptionsDeviceTypes,
} from '../../redux/reducers/deviceTypes.reducer';
import {
  errorHighlight,
  profileTypesMap,
  profileRolesMap,
  optionsPaginationsFilter,
  LWM2M_TYPE,
} from '../../app/constants';
import { dispatchError, updateThingGroupOnChange } from '../../helpers/utils';
import NetworkConfigurationSelect from '../../containers/NetworkConfigurationSelect';
import Header from '../../containers/HeaderWithActionButton';
import Publish from '../../containers/Publish';
import TextInput from '../../components/TextInput';
import SelectInput from '../../components/SelectInput';
import SwitchButton from '../../components/SwitchButton';
import IntegrationConfig from '../../containers/IntegrationConfig';
import DeviceTypeObservablePaths from '../deviceProfileContent/deviceTypeDetails/deviceTypeObservablePaths';

const CreateDeviceProfile: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deviceTypeState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const { newDeviceTypeInput } = deviceTypeState;
  const deviceProfileTypes = Object.entries(profileTypesMap).map((entry) => ({
    key: entry[0],
    label: entry[1],
  }));
  const deviceProfileRoles = Object.entries(profileRolesMap).map((entry) => ({
    key: entry[0],
    label: entry[1],
  }));

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setEnableSubmit(
      newDeviceTypeInput.name !== '' &&
        newDeviceTypeInput.manufacturer !== '' &&
        newDeviceTypeInput.model !== '' &&
        newDeviceTypeInput.company_id !== '',
    );
  }, [newDeviceTypeInput]);

  const handleChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    let deviceToUpdate = cloneDeep(newDeviceTypeInput);

    switch (prop) {
      case 'firmware-upgrade':
        deviceToUpdate.capabilities.firmware.upgrade = value as boolean;
        break;

      case 'ip-tables':
        deviceToUpdate.capabilities.iptables = value as boolean;
        break;

      case 'observable_paths':
        deviceToUpdate.capabilities.observable_paths = value as string[];
        break;

      default:
        deviceToUpdate = { ...deviceToUpdate, [prop]: value };
        break;
    }

    dispatch(setNewDeviceTypeInput(deviceToUpdate));
  };

  const handleDefaultThingGroupChange = (value: AwsThingGroup): void => {
    let deviceToUpdate = cloneDeep(newDeviceTypeInput);

    deviceToUpdate = {
      ...deviceToUpdate,
      default_thing_groups: updateThingGroupOnChange(
        newDeviceTypeInput.default_thing_groups,
        value,
      ),
    };

    dispatch(setNewDeviceTypeInput(deviceToUpdate));
  };

  const handleAccountChange = (companyId: string): void => {
    handleChange('company_id', companyId);
  };

  const handleObservablePathsChange = (
    _prop: string,
    value: string[],
  ): void => {
    handleChange('observable_paths', value);
  };

  const submitHandler = (): void => {
    setIsSubmitting(true);
    DeviceTypes.create(newDeviceTypeInput)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: 'Device Profile created successfully',
            type: 'success',
          }),
        );
        dispatch(cleanNewDeviceTypeInput());
        getDeviceProfileOptions();
        navigate(`/device-profiles`);
      })
      .catch((error) => {
        setAlert({
          highlight: errorHighlight,
          message: error.messages || error.message,
          type: 'error',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const getDeviceProfileOptions = (): void => {
    DeviceTypes.list({}, optionsPaginationsFilter)
      .then((result) => {
        dispatch(setOptionsDeviceTypes(result.deviceTypes));
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="device-profiles"
          goBackLabel="Device Profiles"
          isCreatePage={true}
          model="device_type"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8 ">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-device-profile-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new Device Profile
                </Typography>
              </div>
              <Grid container direction="row" spacing={3}>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Name"
                    prop="name"
                    required={true}
                    value={newDeviceTypeInput.name}
                    onInputChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Description"
                    prop="long_description"
                    value={newDeviceTypeInput.long_description}
                    onInputChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Image URL"
                    prop="image"
                    onInputChange={handleChange}
                    value={newDeviceTypeInput.image}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Manufacturer"
                    prop="manufacturer"
                    required={true}
                    value={newDeviceTypeInput.manufacturer}
                    onInputChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Model"
                    prop="model"
                    required={true}
                    value={newDeviceTypeInput.model}
                    onInputChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Type"
                    prop="type"
                    required={true}
                    value={newDeviceTypeInput.type}
                    onSelectChange={handleChange}
                    options={deviceProfileTypes.map((type) => (
                      <MenuItem dense key={type.key} value={type.key}>
                        {type.label}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Role"
                    prop="role"
                    required={true}
                    value={newDeviceTypeInput.role}
                    onSelectChange={handleChange}
                    options={deviceProfileRoles.map((role) => (
                      <MenuItem dense key={role.key} value={role.key}>
                        {role.label}
                      </MenuItem>
                    ))}
                  />
                </Grid>
                <IntegrationConfig
                  deviceType={newDeviceTypeInput}
                  onIntegrationChange={handleChange}
                  onDefaultThingGroupChange={handleDefaultThingGroupChange}
                  isCreateDialog={true}
                />
                <NetworkConfigurationSelect
                  deviceType={newDeviceTypeInput}
                  onChange={handleChange}
                />
                <Grid item xs={12}>
                  <SwitchButton
                    label="Firmware Upgrades Supported"
                    value={newDeviceTypeInput.capabilities.firmware.upgrade}
                    prop="firmware-upgrade"
                    onSwitchChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SwitchButton
                    label="IP Tables Supported"
                    value={newDeviceTypeInput.capabilities.iptables}
                    prop="ip-tables"
                    onSwitchChange={handleChange}
                  />
                </Grid>
                {newDeviceTypeInput.type === LWM2M_TYPE && (
                  <Grid item xs={12}>
                    <DeviceTypeObservablePaths
                      deviceType={newDeviceTypeInput}
                      onInputChange={handleObservablePathsChange}
                    />
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="device profile"
          submitting={isSubmitting}
          companyId={newDeviceTypeInput.company_id}
          onChangeAccount={handleAccountChange}
          onSubmit={submitHandler}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateDeviceProfile;
