import React from 'react';
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { DeviceConfig, DeviceConfigInput } from '@edgeiq/edgeiq-api-js';

import { SPEEDTEST_SERVERS_URL } from '../../../app/constants';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import CheckboxInput from '../../../components/CheckboxInput';

interface PerformanceTestProps {
  deviceConfig: DeviceConfig | DeviceConfigInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
}

const PerformanceTest: React.FC<PerformanceTestProps> = ({
  deviceConfig,
  onInputChange,
}) => {
  const handleArrayChange =
    (prop: string) =>
    (_e: React.SyntheticEvent, value: string[] | null): void => {
      if (value) {
        onInputChange(prop, value);
      }
    };

  const handleRestoreDefaultOptions =
    (object: 'latencyTest' | 'performance_test') => (): void => {
      onInputChange(object, 'restore_defaults');
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CheckboxInput
          label="Enable Performance Tests"
          prop="performance_test.enabled"
          value="performance_test.enabled_checkbox"
          checked={deviceConfig.performance_test?.enabled}
          onCheckboxClick={onInputChange}
        />
      </Grid>
      {deviceConfig.performance_test?.enabled && (
        <>
          {/* <Grid item xs={12} lg={6}>
            <TextInput
              label="Frequency (s)"
              prop="performance_test.frequency_seconds"
              type="number"
              value={deviceConfig.performance_test.frequency_seconds}
              onInputChange={onInputChange}
            />
          </Grid> */}
          {deviceConfig.connections && (
            <Grid item xs={12} lg={6}>
              <SelectInput
                multiple
                label="Interfaces"
                prop="performance_test.interfaces"
                values={deviceConfig.performance_test.interfaces ?? []}
                onMultiSelectChange={onInputChange}
                options={deviceConfig.connections?.map((network) => (
                  <MenuItem
                    dense
                    key={`performance_test_interface_${network.name}`}
                    value={network.name}
                  >
                    {network.name}
                  </MenuItem>
                ))}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" className="custom-label">
              Servers (optional)
              <Button
                variant="text"
                size="small"
                color="primary"
                target="_blank"
                href={SPEEDTEST_SERVERS_URL}
              >
                List of available servers
              </Button>
            </Typography>
            <Autocomplete
              multiple
              id="performance_test.options.servers"
              freeSolo
              value={(
                (deviceConfig.performance_test?.options.servers as string[]) ??
                [] ??
                []
              ).map((option) => option)}
              options={[] as string[]}
              onChange={handleArrayChange('performance_test.options.servers')}
              renderTags={(tagValue: readonly string[], getTagProps) =>
                tagValue.map((option: string, subIndex: number) => {
                  const { key, ...tagProps } = getTagProps({
                    index: subIndex,
                  });
                  return (
                    <Chip
                      variant="outlined"
                      label={option}
                      key={key}
                      {...tagProps}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Add server. Optional. If no server is added, the server closest to the device will be used."
                  helperText="Press enter to add server"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="custom-label m-0">
              Options
              <Button
                variant="text"
                size="small"
                onClick={handleRestoreDefaultOptions('latencyTest')}
              >
                Restore to defaults
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <CheckboxInput
              label="Download"
              prop="performance_test.options.download"
              value="performance_test.options.download_checkbox"
              checked={deviceConfig.performance_test?.options.download}
              onCheckboxClick={onInputChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CheckboxInput
              label="Upload"
              prop="performance_test.options.upload"
              value="performance_test.options.upload_checkbox"
              checked={deviceConfig.performance_test?.options.upload}
              onCheckboxClick={onInputChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              label="Timeout (s)"
              type="number"
              prop="performance_test.options.timeout_seconds"
              value={deviceConfig.performance_test.options.timeout_seconds}
              onInputChange={onInputChange}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PerformanceTest;
