import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { DeviceType, DeviceTypes } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getDeviceTypeSelector,
  setActualDeviceType,
  setOptionsDeviceTypes,
} from '../../redux/reducers/deviceTypes.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import { dispatchError, getPageHash } from '../../helpers/utils';
import { deviceTypeTabsLabel, DETAILS_DEFAULT_TAB } from '../../app/constants';
import { LocationState } from '../../models/common';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import FooterBar from '../../components/FooterBar';
import DeviceTypeDetails from './deviceTypeDetails';
import DeviceTypeAbilites from './deviceTypeAbilities';
import DeviceTypePolicies from './deviceTypePolicies';
import DeviceTypePollableAttributes from './deviceTypePollableAttributes';
import DeviceTypeSoftwareUpdates from './deviceTypeSoftwareUpdate';
import DeviceTypeConfiguration from './deviceTypeConfigurations';
import DeviceTypeIngestors from './deviceTypeIngestors';
import DeviceTypeCommands from './deviceTypeCommands';
import DeviceProfileMetadata from './deviceProfileMetadata';

const DeviceProfileContent: React.FC = () => {
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const location = useLocation();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const deviceTypeData = useAppSelector((state: RootState) =>
    getDeviceTypeSelector(state.deviceTypes, id),
  );
  const newDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const { optionsDeviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [loading, setLoading] = useState(false);
  const [deviceCompany] = useFetchCompany(
    deviceTypeData?.company_id,
    errorDispatcher,
  );
  const [activeTab, setActiveTab] = useState(DETAILS_DEFAULT_TAB);

  useEffect(() => {
    if (deviceTypeData && deviceTypeData._id === id) {
      dispatch(setActualDeviceType(deviceTypeData));
    } else if (id) {
      DeviceTypes.getOneById(id)
        .then((response) => {
          dispatch(setActualDeviceType(response));
        })
        .catch((error) => {
          errorDispatcher(error.message);
        });
    }
  }, [id]);

  useEffect(() => {
    setActiveTab(
      getPageHash(location.hash, DETAILS_DEFAULT_TAB, deviceTypeTabsLabel),
    );
  }, [location.hash]);

  const handleDeleteDeviceType = (): void => {
    if (!deviceTypeData) {
      return;
    }
    setLoading(true);
    DeviceTypes.delete(deviceTypeData._id)
      .then(() => {
        dispatch(
          setOptionsDeviceTypes(
            optionsDeviceTypes.filter(
              (optionDeviceType) => optionDeviceType._id !== deviceTypeData._id,
            ),
          ),
        );
        dispatch(
          setAlert({
            highlight: 'Delete device type',
            message: 'Device type successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/device-profiles');
      })
      .catch((error) => {
        errorDispatcher(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateProfileState = (
    updatedContent: DeviceType | undefined,
  ): void => {
    if (optionsDeviceTypes && updatedContent) {
      const foundIndex = optionsDeviceTypes.findIndex(
        (e) => e._id === updatedContent._id,
      );
      optionsDeviceTypes[foundIndex] = updatedContent;
      if (foundIndex >= 0) {
        dispatch(setOptionsDeviceTypes([...optionsDeviceTypes]));
      }
    }
  };

  const handleSaveChanges = (): void => {
    if (newDeviceType !== deviceTypeData) {
      setLoading(true);
      DeviceTypes.update(newDeviceType as DeviceType)
        .then((response) => {
          dispatch(setActualDeviceType(response));
          handleUpdateProfileState(newDeviceType);
          dispatch(
            setAlert({
              highlight: 'Update device type',
              message: 'Device type successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((error) => {
          errorDispatcher(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const goToItem = (type: string, itemId?: string) => (): void => {
    let url = '';
    let tab = '';
    switch (type) {
      case 'ingestor':
        url = `data-management/${
          itemId ? `${type}/${itemId}` : 'new-ingestor'
        }`;
        tab = 'ingestors';
        break;

      case 'policy':
        url = `${itemId ? `${type}/${itemId}` : 'new-policy'}`;
        tab = 'policies';
        break;

      case 'command':
        url = `${itemId ? `${type}/${itemId}` : 'new-command'}`;
        tab = 'commands';
        break;

      case 'configuration':
        url = `${itemId ? `${type}/${itemId}` : 'new-configuration'}`;
        tab = 'configurations';
        break;

      case 'pollable-attribute':
        url = `data-management/${
          itemId ? `${type}/${itemId}` : 'new-pollable-attribute'
        }`;
        tab = 'pollable-attributes';
        break;

      case 'software-update':
        url = `${itemId ? `${type}/${itemId}` : 'new-software-update'}`;
        tab = 'software-updates';
        break;

      default:
        break;
    }
    navigate(`/${url}`, {
      state: {
        goBackLabel: `Device Profile: ${deviceTypeData?.name}`,
        goBackUrl: `device-profile/${deviceTypeData?._id}#${tab}`,
      },
    });
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newDeviceType, deviceTypeData);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item xs={12}>
        <Header
          title="Device profile content"
          goBack={goBackUrl ? goBackUrl : 'device-profiles'}
          goBackLabel={goBackLabel || `Device Profiles`}
          model="device-type"
        />
      </Grid>
      {deviceTypeData && deviceTypeData && (
        <Box className="content-page-container">
          <ContentHeader
            title={deviceTypeData.name}
            contentType="device-type"
            profileName={deviceTypeData.name}
            profileType={deviceTypeData.type}
            profileRole={deviceTypeData.role}
            subtitle={deviceTypeData._id}
            extraImage={deviceCompany?.branding?.logo_url}
            extraTitle={deviceCompany?.name}
            extraSubtitle={deviceCompany?._id}
            hideOverline={false}
            copySubtitleToClipboard
          />
          <VerticalTabs
            /* eslint sort-keys: 0 */
            tabs={{
              details: <DeviceTypeDetails />,
              abilities: <DeviceTypeAbilites />,
              ingestors: <DeviceTypeIngestors goToItem={goToItem} />,
              policies: <DeviceTypePolicies goToItem={goToItem} />,
              commands: <DeviceTypeCommands goToItem={goToItem} />,
              configurations: <DeviceTypeConfiguration goToItem={goToItem} />,
              pollableAttributes: (
                <DeviceTypePollableAttributes goToItem={goToItem} />
              ),
              softwareUpdate: <DeviceTypeSoftwareUpdates goToItem={goToItem} />,
              metadata: <DeviceProfileMetadata />,
            }}
            defaultTab={activeTab}
            tabsLabel={deviceTypeTabsLabel}
            changeUrl={true}
          />
        </Box>
      )}
      <FooterBar
        deleteModalContent="You are about to delete this device profile"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteDeviceType}
      />
    </Grid>
  );
};

export default DeviceProfileContent;
