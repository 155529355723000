import { DateTime } from 'luxon';
import { DateRange } from 'materialui-daterange-picker';

export interface StringDateRange {
  endDate: string;
  startDate: string;
}

const getTimesFromSecs = (
  secsInput: number,
): {
  days: number;
  hours: number;
  minutes: number;
} => {
  const days = Math.floor(secsInput / 86400); //  get days from seconds
  const hours = Math.floor((secsInput - days * 86400) / 3600); // get hours from seconds
  const minutes = Math.floor((secsInput - days * 86400 - hours * 3600) / 60); // get minutes from seconds
  return {
    days,
    hours,
    minutes,
  };
};

// Returns date with format: Today - 12:20 PM  /  April 25 - 12:20 PM
const getDate = (
  date: string,
  isMillis = false,
  addMillis = false,
  showSameYear = false,
): string => {
  const hourFormat = `hh:mm:ss${addMillis ? '.SSS' : ''} a`;
  if (!date) {
    return '--';
  }
  const now = DateTime.now();
  const paramDate = isMillis
    ? DateTime.fromMillis(parseInt(`${date}000`))
    : DateTime.fromISO(date);
  if (now.hasSame(paramDate, 'day')) {
    return `Today - ${paramDate.toFormat(hourFormat)}`;
  } else if (now.hasSame(paramDate, 'year')) {
    return paramDate.toFormat(
      `${showSameYear ? 'yyyy ' : ''}LLLL d - ${hourFormat}`,
    );
  }

  return paramDate.toFormat(`yyyy LLLL d - ${hourFormat}`);
};

const getPlainDate = (date: string, isMillis = false): string => {
  if (!date) {
    return '--';
  }
  const paramDate = isMillis
    ? DateTime.fromMillis(parseInt(`${date}000`))
    : DateTime.fromISO(date);
  return paramDate.toFormat('MM/dd/yyyy');
};

/**
 * get the difference between two dates in a human readable string
 * @param from string date
 * @param to string date optional
 * @returns humand readable string in days hours and minutes if are there
 */
const getDifferenceBetweenDates = (from: string, to?: string): string => {
  const fromDate = DateTime.fromISO(from);
  const toDate = to ? DateTime.fromISO(to) : DateTime.now();
  const diff = toDate.diff(fromDate, ['days', 'hours', 'minutes']).toObject();
  return `
    ${
      diff.days
        ? `${Math.trunc(diff.days)} ${diff.days > 1 ? 'days ' : 'day '}`
        : ''
    }
    ${
      diff.hours
        ? `${diff.days !== 0 && !diff.minutes ? 'and ' : ''}${Math.trunc(
            diff.hours,
          )} ${diff.hours > 1 ? 'hours ' : 'hour '}`
        : ''
    }
    ${
      diff.minutes
        ? `${diff.hours !== 0 ? 'and ' : ''}${Math.trunc(diff.minutes)} ${
            diff.minutes > 1 ? 'minutes ' : 'minute '
          }`
        : ''
    }
  `;
};

/**
 * get the date string to be able to do queries such as "last x days"
 * @param lastDays number to days
 * @returns string tdate to use in queries
 */
const getLastDaysDate = (lastDays: number): string => {
  return DateTime.now().minus({ days: lastDays }).toISO();
};

/**
 * get the date string to be able to do queries such as "last x days"
 * @returns string tdate to use in queries
 */
const getWeekDates = (type: string): StringDateRange | DateRange => {
  const now = DateTime.now();
  const stratDateTime = now.startOf('week').minus({ day: 1 });
  const endDateTime = now.endOf('week').minus({ day: 1 });
  if (type === 'js') {
    return {
      endDate: endDateTime.toJSDate(),
      startDate: stratDateTime.toJSDate(),
    };
  }
  return {
    endDate: endDateTime.toLocaleString(),
    startDate: stratDateTime.toLocaleString(),
  };
};

const timeHelpers = {
  getDate,
  getDifferenceBetweenDates,
  getLastDaysDate,
  getPlainDate,
  getTimesFromSecs,
  getWeekDates,
};

export default timeHelpers;
