import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  DeviceType,
  DeviceTypeInput,
  DeviceConfig,
  DeviceConfigs,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setStateNetworkConfigurations } from '../../redux/reducers/networkConfigurations.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { RootState } from '../../redux/store';
import { optionsPaginationsFilter, errorHighlight } from '../../app/constants';
import SelectInput from '../../components/SelectInput';

// logic: if `device` is set, then this is the device dialog (either create or detail), otherwise it is the device profile dialog
interface NetworkConfigurationSelectProps {
  deviceType: DeviceType | DeviceTypeInput;
  onChange: (prop: string, configId: string) => void;
  isCreate?: boolean;
}

const NetworkConfigurationSelect: React.FC<NetworkConfigurationSelectProps> = ({
  deviceType,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const networkConfigurationsState = useAppSelector(
    (state: RootState) => state.configs,
  );
  const [networkConfigurations, setNetworkConfigurations] = useState<
    DeviceConfig[]
  >([]);
  const [chosenConfiguration, setChosenConfiguration] = useState<
    DeviceConfig | undefined
  >();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (networkConfigurationsState.configs.length === 0) {
      DeviceConfigs.list({}, optionsPaginationsFilter)
        .then((result) => {
          setNetworkConfigurations(result.deviceConfigs);
          dispatch(setStateNetworkConfigurations(result.deviceConfigs));
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setNetworkConfigurations(networkConfigurationsState.configs);
    if (deviceType.device_network_config_id) {
      setChosenConfiguration(
        networkConfigurationsState.configs.find(
          (c) => c._id === deviceType.device_network_config_id,
        ),
      );
    }
  }, [networkConfigurationsState.configs]);

  const handleSelectInputChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    setChosenConfiguration(networkConfigurations.find((c) => c._id === value));
    onChange(prop, value as string);
  };

  return (
    <>
      {!loading && networkConfigurations.length === 0 ? (
        <Grid item xs={12}>
          <Typography component="div" variant="button">
            {`No network configurations have been created to select from.`}
            <Button
              variant="text"
              size="small"
              className="ml-2 p-0"
              target="_blank"
              href={`${window.location.origin}/new-network-configuration`}
            >
              Create a new one
            </Button>
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={11}>
            <SelectInput
              label="Select a network configuration"
              prop="device_network_config_id"
              value={chosenConfiguration?._id ?? ''}
              onSelectChange={handleSelectInputChange}
              options={[
                <MenuItem dense value="" key="no-value-key">
                  Select a network configuration
                </MenuItem>,
                ...networkConfigurations.map((networkConfig) => (
                  <MenuItem
                    dense
                    key={networkConfig._id}
                    value={networkConfig._id}
                  >
                    {networkConfig.name}
                  </MenuItem>
                )),
              ]}
            />
          </Grid>
          {chosenConfiguration && (
            <Grid item xs={1} className="d-flex flex-items-center">
              <Tooltip
                placement="top"
                title={`Go to configuration: ${chosenConfiguration.name}`}
              >
                <IconButton
                  aria-label="go-to-configuration"
                  size="medium"
                  target="_blank"
                  href={`${window.location.origin}/network-configuration/${chosenConfiguration._id}`}
                >
                  <OpenInNewIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default NetworkConfigurationSelect;
