import { useEffect, useState, ChangeEvent } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import {
  SoftwareUpdates,
  PaginationFilter,
  SoftwareUpdate,
  DeviceTypes,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';

import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { setOptionsDeviceTypes } from '../../redux/reducers/deviceTypes.reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setSorting } from '../../redux/reducers/filters.reducer';
import { setStateSoftwareUpdate } from '../../redux/reducers/softwareUpdates.reducer';

import Header from '../../containers/HeaderWithActionButton';
import CardsGrid from '../../components/CardsGrid';
import Card from '../../components/Card';
import ListSelection from '../../components/ListSelection';
import ActionDialog from '../../components/ActionDialog';

import { SortingOption } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import {
  errorHighlight,
  deleteHighlight,
  optionsPaginationsFilter,
} from '../../app/constants';

import SoftwarePackageCard from './SoftwarePackageCard';
import LogUpdates from '../../containers/LogUpdates';
import SoftwarePackageFilters from './SoftwarePackageFilters';
import { SORTING_OPTIONS_OLDEST_NEWEST_NAME } from '../../constants/sortings';

const SoftwarePackagesPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state: RootState) => state.filters);
  const softwareUpdatesState = useAppSelector(
    (state: RootState) => state.softwareUpdates,
  );
  const [softwareUpdates, setSoftwareUpdates] = useState<SoftwareUpdate[]>(
    softwareUpdatesState.softwareUpdates,
  );
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedSoftwarePackages, setSelectedSoftwarePackages] = useState<
    string[]
  >([]);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(
      filters.secrets.sortBy,
      SORTING_OPTIONS_OLDEST_NEWEST_NAME,
    ),
  );
  const [loadingDelete, setLoadingDelete] = useState(false);
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [devicesTypes, setDevicesTypes] = useState<DeviceType[]>(
    deviceTypesState.optionsDeviceTypes,
  );
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const getSoftwareUpdates = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: 10,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    SoftwareUpdates.list(
      parseFilters(
        filters.softwareUpdates.filters ?? {},
        filters.case_sensitive,
      ),
      pagination,
    )
      .then((result) => {
        const newSoftwareUpdates = addPage
          ? [...softwareUpdates, ...result.softwareUpdates]
          : result.softwareUpdates;
        setSoftwareUpdates(newSoftwareUpdates);
        dispatch(setStateSoftwareUpdate(newSoftwareUpdates));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const whenComponetMounted = (newPage: number): void => {
    setLoading(true);
    setSelectedSoftwarePackages([]);
    getSoftwareUpdates(newPage);
  };

  useEffect(() => {
    whenComponetMounted(1);
  }, [filters.softwareUpdates]);

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getSoftwareUpdates(page + 1, true);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    if (selectedSoftwarePackages.length === 1) {
      SoftwareUpdates.delete(selectedSoftwarePackages[0])
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: deleteHighlight(
                selectedSoftwarePackages.length,
                'Secret',
                'Secrets',
              ),
              type: 'success',
            }),
          );
          whenComponetMounted(page);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    } else {
      SoftwareUpdates.deleteMultiple(selectedSoftwarePackages)
        .then((_result) => {
          dispatch(
            setAlert({
              highlight: deleteHighlight(
                selectedSoftwarePackages.length,
                'Secret',
                'Secrets',
              ),
              type: 'success',
            }),
          );
          whenComponetMounted(1);
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => {
          setLoadingDelete(false);
          closeDeleteModal();
        });
    }
  };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'softwareUpdates'));
    setSelectedSorting(option);
  };

  const handleSelectAll = (): void => {
    if (selectedSoftwarePackages.length !== softwareUpdates.length) {
      setSelectedSoftwarePackages(
        softwareUpdates.map((softwareUpdate) => softwareUpdate._id),
      );
    } else {
      setSelectedSoftwarePackages([]);
    }
  };

  useEffect(() => {
    if (devicesTypes.length === 0) {
      setLoading(true);
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          setDevicesTypes(result.deviceTypes);
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => noLoading());
    }
  }, []);

  const checkDeviceCallback =
    (deviceId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setSelectedSoftwarePackages([...selectedSoftwarePackages, deviceId]);
      } else {
        setSelectedSoftwarePackages(
          selectedSoftwarePackages.filter((item) => item !== deviceId),
        );
      }
    };

  const generateCards = (): JSX.Element[] => {
    return softwareUpdates.map((softwareUpdate) => (
      <Card
        checked={selectedSoftwarePackages.includes(softwareUpdate._id)}
        checkboxCallback={checkDeviceCallback}
        id={softwareUpdate._id}
        baseLink="/software-update"
        extraClasses="background-grey"
        content={
          <SoftwarePackageCard
            softwareUpdate={softwareUpdate}
            deviceType={devicesTypes.find(
              (deviceType) => deviceType._id === softwareUpdate.device_type_id,
            )}
          />
        }
      />
    ));
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };
  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        action="create"
        title="Software Packages"
        link="new-software-update"
        actionLabel="Create new packages"
        model="software_update"
      />
      <SoftwarePackageFilters total={total} />
      <Grid item xs={12} className="mw-100">
        <ListSelection
          selectedSorting={selectedSorting}
          selectedView={'grid'}
          sortingOptions={SORTING_OPTIONS_OLDEST_NEWEST_NAME}
          selectedLabel="software package"
          viewsOptions={[]}
          itemsSelected={selectedSoftwarePackages.length !== 0}
          itemsSelectedCount={selectedSoftwarePackages.length}
          allSelected={
            softwareUpdates.length !== 0 &&
            selectedSoftwarePackages.length === softwareUpdates.length
          }
          deleteAction={true}
          sortingCallback={handleSorting}
          selectAllCallback={handleSelectAll}
          deleteCallback={openDeleteModal}
        />
        <Grid container columnSpacing={3} direction="row" className="px-9 pb-6">
          <Grid item xs={12} md={8} className="overflow-y scrollbar">
            <Paper className={clsx('shadow py-8')}>
              {loading ? (
                <Grid container className="loading-container">
                  <CircularProgress size={75} thickness={5} />
                </Grid>
              ) : (
                <>
                  <CardsGrid
                    xsSize={12}
                    twoColumns={true}
                    cards={generateCards()}
                  />
                  {softwareUpdates.length !== total && (
                    <Grid item xs={12} className="loading-container">
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={handleLoadMore}
                      >
                        {!loadingMore ? (
                          <Typography variant="button">Load more</Typography>
                        ) : (
                          <CircularProgress size={25} />
                        )}
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <LogUpdates />
          </Grid>
        </Grid>
      </Grid>
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedSoftwarePackages.length === 1 ? 'secret' : 'secrets'
            }:`}</span>
            <ul>
              {softwareUpdates
                .filter((softwareUpdate) =>
                  selectedSoftwarePackages.includes(softwareUpdate._id),
                )
                .map((softwareUpdate) => (
                  <li key={softwareUpdate._id}>
                    {softwareUpdate.name} - Id: {softwareUpdate._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default SoftwarePackagesPage;
