import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorButton: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
    },
    fontBold: {
      fontWeight: 700,
    },
    rowBox: {
      display: 'flex',
    },
    tag: {
      height: '18px',
    },
  }),
);

export default useStyles;
